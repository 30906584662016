import {
  RESET_APP_STATE,
  TRANSACTIONS_STATS_DOWNLOAD,
  TRANSACTIONS_STATS_DOWNLOAD_COMPLETED,
  TRANSACTIONS_STATS_DOWNLOAD_ERROR,
  TRANSACTION_LIST,
  TRANSACTION_LIST_COMPLETED,
  TRANSACTION_LIST_EMPTY,
  TRANSACTION_LIST_ERROR,
  TRANSACTION_REVERT,
  TRANSACTION_REVERT_COMPLETED,
  TRANSACTION_REVERT_ERROR,
  USER_TRANSACTION_LIST_EMPTY,
  USER_TRANSACTION_LIST_GET,
  USER_TRANSACTION_LIST_GET_COMPLETED,
  USER_TRANSACTION_LIST_GET_ERROR,
} from "../actions/actions.constants";
import { Transaction } from "../../models/entities/Transaction";

export interface TransactionState {
  entities: Record<number, Transaction>;
  transactionIds: number[];
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  hasMoreData: boolean;
  currentPage: number;
  currentId: number;
  metaTotalResult: number | null;
  prevPageFirstElementId: number;
  userTransactionEntities: {
    entities: Record<number, Transaction>;
    entityIds: number[];
    loading?: boolean;
    loaded?: boolean;
    error?: string;
    hasMoreData: boolean;
    currentPage: number;
    currentId: number;
    metaTotalResult: number | null;
    prevPageFirstElementId: number;
  };
  download_loading?: boolean;
}

const initialState: TransactionState = {
  entities: {},
  transactionIds: [],
  loading: false,
  loaded: true,
  error: "",
  hasMoreData: true,
  currentPage: 1,
  currentId: -1,
  metaTotalResult: null,
  prevPageFirstElementId: -1,
  userTransactionEntities: {
    entities: {},
    entityIds: [],
    hasMoreData: false,
    currentPage: 0,
    currentId: 0,
    metaTotalResult: null,
    prevPageFirstElementId: 0,
  },
};
export const transactionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case TRANSACTION_LIST_EMPTY: {
      return {
        ...initialState,
      };
    }
    case TRANSACTION_REVERT:
    case TRANSACTION_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case TRANSACTION_LIST_COMPLETED: {
      const transactionIds =
        action.data.wallet_address &&
        state.entities[state.transactionIds[0]]?.user?.wallet_address !==
          action.data.wallet_address
          ? new Set([])
          : new Set(state.transactionIds);
      const hasMoreData =
        action.data.meta.current_page < action.data.meta.last_page;
      const currentPage = action.data.meta.current_page;
      const metaTotalResult = action.data?.meta?.total;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.data.transactions.length
        ? action.data.transactions[0].id
        : state.prevPageFirstElementId;
      const transactions: { [key: number]: Transaction } = {
        ...state.entities,
      };
      action.data.transactions.forEach((e: Transaction) => {
        transactionIds.add(e.id);
        transactions[e.id] = e;
      });
      return {
        ...state,
        entities: transactions,
        transactionIds: Array.from(transactionIds),
        hasMoreData,
        currentPage,
        metaTotalResult,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
        loading: false,
      };
    }
    case USER_TRANSACTION_LIST_GET: {
      return {
        ...state,
        userTransactionEntities: {
          ...state.userTransactionEntities,
          loading: true,
        },
      };
    }
    case USER_TRANSACTION_LIST_GET_COMPLETED: {
      const transactionIds = new Set(state.userTransactionEntities.entityIds);
      const hasMoreData =
        action.data.meta.current_page < action.data.meta.last_page;
      const currentPage = action.data.meta.current_page;
      const metaTotalResult = action.data?.meta?.total;
      const firstElementOfPrevPageId =
        state.userTransactionEntities.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.data.transactions.length
        ? action.data.transactions[0].id
        : state.userTransactionEntities.prevPageFirstElementId;
      const transactions: { [key: number]: Transaction } = {
        ...state.userTransactionEntities.entities,
      };
      action.data.transactions.forEach((e: Transaction) => {
        transactionIds.add(e.id);
        transactions[e.id] = e;
      });
      return {
        ...state,
        userTransactionEntities: {
          ...state.userTransactionEntities,
          entities: transactions,
          entityIds: Array.from(transactionIds),
          hasMoreData,
          currentPage,
          metaTotalResult,
          currentId: firstElementOfPrevPageId,
          prevPageFirstElementId: newPrevPageFirstElementId,
          loading: false,
        },
      };
    }
    case USER_TRANSACTION_LIST_GET_ERROR: {
      return {
        ...state,
        userTransactionEntities: {
          ...state.userTransactionEntities,
          loading: false,
          error: action.payload.message,
        },
      };
    }
    case TRANSACTION_REVERT_COMPLETED: {
      const newTransaction = action.payload?.res?.new_transaction;
      const updatedTransaction = action.payload?.res?.updated_transaction;
      const entityIds = state.transactionIds.filter(
        (id) => id !== updatedTransaction.id
      );
      return {
        ...state,
        entities: {
          ...state.entities,
          [newTransaction?.id]: { ...newTransaction },
          [updatedTransaction.id]: {
            ...state.entities[updatedTransaction.id],
            ...updatedTransaction,
          },
        },
        transactionIds: [
          newTransaction?.id,
          updatedTransaction.id,
          ...entityIds,
        ],
        loading: false,
        metaTotalResult: (state?.metaTotalResult ?? 0) + 1,
      };
    }
    case USER_TRANSACTION_LIST_EMPTY: {
      return {
        ...state,
        userTransactionEntities: initialState.userTransactionEntities,
      };
    }
    case TRANSACTIONS_STATS_DOWNLOAD: {
      return {
        ...state,
        download_loading: true,
      };
    }
    case TRANSACTIONS_STATS_DOWNLOAD_COMPLETED: {
      return {
        ...state,
        download_loading: false,
      };
    }
    case TRANSACTIONS_STATS_DOWNLOAD_ERROR: {
      return {
        ...state,
        download_loading: false,
        error: action.payload.message,
      };
    }
    case TRANSACTION_REVERT_ERROR:
    case TRANSACTION_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    }

    default:
      return state;
  }
};
