import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import React from "react";
import { useSelector } from "react-redux";
import { WagmiConfig, configureChains, createConfig, mainnet } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import {
  ENV_FE_BASE_URL,
  ENV_WALLETCONNECT_PROJECT_ID,
} from "../../../constants/secret";
import { BrandState } from "../../../redux/reducers/brand.reducer";
import {
  brandConfigSelector,
  brandSelector,
} from "../../../redux/selectors/app.selectors";
import { BrandBackgroundColor, BrandConfigKeys } from "../../../utils/enums";
import WalletAccountHookContainer from "./WalletAccountHookContainer";

export interface Props {
  children: React.ReactNode;
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [publicProvider()]
);

const wagmiConfig = createConfig(
  getDefaultConfig({
    // Required API Keys
    walletConnectProjectId: ENV_WALLETCONNECT_PROJECT_ID!,

    // Required
    chains,
    publicClient,
    webSocketPublicClient,
    appName: "Gen City Labs, Inc. - Tonic",

    // Optional (TO CHANGE)
    autoConnect: false,
    appDescription: ENV_FE_BASE_URL,
    appUrl: ENV_FE_BASE_URL, // your app's url
    appIcon: `${ENV_FE_BASE_URL}/favicon.ico`, // your app's icon, no bigger than 1024x1024px (max. 1MB)
  })
);

const ConnectWalletProvider: React.FC<Props> = ({ children }) => {
  const { configEntities: brandConfig }: BrandState =
    useSelector(brandSelector);

  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectKitProvider
        mode={
          brandConfigSelector(brandConfig, BrandConfigKeys.STYLES)?.value
            .background_color === BrandBackgroundColor.BLACK
            ? "dark"
            : "light"
        }
        options={{
          walletConnectName: "WalletConnect QR",
        }}
        customTheme={{
          "--ck-connectbutton-font-size": "13px",
          "--ck-font-family": "var(--font-secondary)",
          "--ck-body-background": "rgba(var(--color-modal-primary))",
          "--ck-overlay-background": "rgba(0, 0, 0, 0.8)",
          // Cross, Question Mark, Back
          "--ck-body-background-secondary":
            "rgba(var(--color-background), 0.5)",

          "--ck-primary-button-font-weight": 500,
          "--ck-primary-button-background":
            "rgba(var(--color-background), 0.5)",
          "--ck-primary-button-border-radius": "0.625rem",
          "--ck-primary-button-hover-border-radius": "0.625rem",
          "--ck-primary-button-active-border-radius": "0.625rem",
          "--ck-primary-button-hover-background":
            "rgba(var(--color-background))",
          "--ck-primary-button-hover-box-shadow":
            "0 0 0.625rem rgba(var(--color-button-primary), 0.5)",

          "--ck-secondary-button-font-weight": 500,
          "--ck-secondary-button-background":
            "rgba(var(--color-background), 0.5)",
          "--ck-secondary-button-border-radius": "0.625rem",
          "--ck-secondary-button-hover-border-radius": "0.625rem",
          "--ck-secondary-button-active-border-radius": "0.625rem",
          "--ck-secondary-button-hover-background":
            "rgba(var(--color-background))",
          "--ck-secondary-button-hover-box-shadow":
            "0 0 0.625rem rgba(var(--color-button-primary), 0.5)",
        }}
      >
        <WalletAccountHookContainer>{children}</WalletAccountHookContainer>
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default React.memo(ConnectWalletProvider);
