import { all, call, put, takeLatest } from "redux-saga/effects";
import { webToastService } from "../../services/WebToastService";
import { baseApiService } from "../../services/api-services/BaseApiService";
import { exclusiveContentServices } from "../../services/api-services/ExclusiveContentServices";
import {
  EXCLUSIVE_CONTENTS_LIST_GET,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE,
  EXCLUSIVE_CONTENT_CREATE,
  EXCLUSIVE_CONTENT_DELETE,
  EXCLUSIVE_CONTENT_GET,
  EXCLUSIVE_CONTENT_UPDATE,
} from "../actions/actions.constants";
import {
  exclusiveContentCoverImageEditCompletedAction,
  exclusiveContentCoverImageEditErrorAction,
  exclusiveContentCreateCompletedAction,
  exclusiveContentCreateErrorAction,
  exclusiveContentDeleteActionCompleted,
  exclusiveContentDeleteActionError,
  exclusiveContentEditCompletedAction,
  exclusiveContentEditErrorAction,
  exclusiveContentGetCompletedAction,
  exclusiveContentGetErrorAction,
  exclusiveContentListGetCompletedAction,
  exclusiveContentListGetErrorAction,
} from "../actions/exclusive.content.action";
import { SAGA_PAYLOAD_TYPE } from "./../../models/types/SagaPayloadType";

function* getExclusiveContentSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(exclusiveContentServices.get, data.payload);
    yield put(
      exclusiveContentGetCompletedAction({
        res: {
          exclusive_content: response.exclusive_content,
        },
      })
    );
  } catch (e: any) {
    yield put(
      exclusiveContentGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* createExclusiveContentSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(exclusiveContentServices.create, payload.req);
    if (payload?.thumbnail_object_url) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.thumbnail_object_url,
        response.signed_urls?.thumbnail_signed_url
      );
    }
    if (payload?.image_object_url) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.image_object_url,
        response.signed_urls?.image_signed_url
      );
    }
    if (payload?.video_object_url) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.video_object_url,
        response.signed_urls?.video_signed_url
      );
    }
    webToastService.showSuccess("Successfully Created");
    yield put(
      exclusiveContentCreateCompletedAction({
        res: { exclusive_content: response.exclusive_content },
      })
    );
  } catch (e: any) {
    yield put(
      exclusiveContentCreateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* updateExclusiveContentCoverImageSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(exclusiveContentServices.editImage, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.thumbnail_object_url,
      response.signed_url
    );
    webToastService.showSuccess("Successfully Uploaded New Image");
    yield put(
      exclusiveContentCoverImageEditCompletedAction({
        res: { exclusive_content: response.exclusive_content },
      })
    );
  } catch (e: any) {
    yield put(
      exclusiveContentCoverImageEditErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* editExclusiveContentSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(
      exclusiveContentServices.edit,
      payload.id,
      payload.req
    );
    if (payload?.image_object_url) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.image_object_url,
        response.signed_urls?.image_signed_url
      );
    }
    if (payload?.video_object_url) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.video_object_url,
        response.signed_urls?.video_signed_url
      );
    }
    webToastService.showSuccess("Successfully Edited");
    yield put(
      exclusiveContentEditCompletedAction({
        res: { exclusive_content: response.exclusive_content },
      })
    );
  } catch (e: any) {
    yield put(
      exclusiveContentEditErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getExclusiveContentListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(exclusiveContentServices.list, payload.req);
    yield put(
      exclusiveContentListGetCompletedAction({
        res: { exclusive_contents: response.exclusive_contents },
      })
    );
  } catch (e: any) {
    yield put(
      exclusiveContentListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* removeExlusiveContent(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(exclusiveContentServices.delete, payload?.id);
    if (response?.success) {
      yield put(exclusiveContentDeleteActionCompleted({ id: payload.id }));
    }

    webToastService.showSuccess("Successfully deleted");
  } catch (e: any) {
    yield put(
      exclusiveContentDeleteActionError({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* exclusiveContentSaga() {
  yield all([
    takeLatest(EXCLUSIVE_CONTENT_GET, getExclusiveContentSaga),
    takeLatest(EXCLUSIVE_CONTENT_CREATE, createExclusiveContentSaga),
    takeLatest(
      EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE,
      updateExclusiveContentCoverImageSaga
    ),
    takeLatest(EXCLUSIVE_CONTENT_UPDATE, editExclusiveContentSaga),
    takeLatest(EXCLUSIVE_CONTENTS_LIST_GET, getExclusiveContentListSaga),

    takeLatest(EXCLUSIVE_CONTENT_DELETE, removeExlusiveContent),
  ]);
}

export default exclusiveContentSaga;
