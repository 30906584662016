import React, { Dispatch, SetStateAction } from "react";

export const BrandContext = React.createContext<{
  background_color: any;
  primary_color: any;
  secondary_color: any;
  currency: any;
  primary_font: any;
  secondary_font: any;
  domain: string;
  discord_client_id: any;
  twitter_client_id: any;
  instagram_client_id: any;
  tiktok_client_id: any;
  youtube_client_id: any;
  brand_logo_url: any;
  brand_banner_url: any;
  is_tier_enable: boolean;
  button_font_color: any;
  sign_up_credit_points: any;
  isBrandLoading: any;
  brandButtonColorStyle: any;
  favicon_image_url: any;
  shareable_image_url: any;
  spotify_client_id: any;
  linkedin_client_id: any;
  brand_button_color: any;
  is404Page?: boolean;
  setIs404Page?: Dispatch<SetStateAction<boolean>>;

  openCookiePolicy?: boolean;
  setOpenCookiePolicy?: Dispatch<SetStateAction<boolean>>;
}>({
  background_color: "",
  primary_color: "",
  secondary_color: "",
  currency: "",
  primary_font: "",
  secondary_font: "",
  domain: "",
  discord_client_id: "",
  twitter_client_id: "",
  instagram_client_id: "",
  tiktok_client_id: "",
  youtube_client_id: "",
  brand_logo_url: "",
  brand_banner_url: "",
  is_tier_enable: false,
  button_font_color: "",
  sign_up_credit_points: "",
  isBrandLoading: null,
  brandButtonColorStyle: "",
  favicon_image_url: "",
  shareable_image_url: "",
  linkedin_client_id: "",
  spotify_client_id: "",
  brand_button_color: "",
});
