import Quest from "../../models/entities/Quest";
import UserQuest from "../../models/entities/user-quest/UserQuest";
import { SortBy, UserQuestStatus } from "../../utils/enums";
import {
  APPROVE_OR_REJECT_ALL_USER_QUEST,
  APPROVE_OR_REJECT_ALL_USER_QUEST_COMPLETED,
  APPROVE_OR_REJECT_ALL_USER_QUEST_ERROR,
  CLAIMED_USER_QUEST_AMOUNT_RESET,
  CLAIM_USER_QUEST,
  CLAIM_USER_QUEST_COMPLETED,
  CLAIM_USER_QUEST_ERROR,
  IS_QUEST_APPROVAL_PENDING_FOR_SELECTED_QUEST,
  LIST_ALL_USER_QUEST,
  LIST_ALL_USER_QUEST_COMPLETED,
  LIST_ALL_USER_QUEST_ERROR,
  LIST_ALL_USER_QUEST_RESET,
  MY_QUESTS_ACTIVITY_LIST_GET,
  MY_QUESTS_ACTIVITY_LIST_GET_COMPLETED,
  MY_QUESTS_ACTIVITY_LIST_GET_ERROR,
  MY_QUESTS_ACTIVITY_LIST_RESET,
  QUESTS_LIST_GET,
  QUESTS_LIST_GET_COMPLETED,
  QUESTS_LIST_GET_ERROR,
  QUEST_COVER_IMAGE_UPDATE,
  QUEST_COVER_IMAGE_UPDATE_COMPLETED,
  QUEST_COVER_IMAGE_UPDATE_ERROR,
  QUEST_CREATE,
  QUEST_CREATE_COMPLETED,
  QUEST_CREATE_ERROR,
  QUEST_GET,
  QUEST_GET_COMPLETED,
  QUEST_GET_ERROR,
  QUEST_LEAR2EARN_VIDEO_URL_SECTION,
  QUEST_MORE_DETAILS_FORM_SUBMISSION,
  QUEST_NEW_FORM_SUBMISSION,
  QUEST_POLL_QUESTION_SECTION,
  QUEST_PROOF_SECTION,
  QUEST_QUIZ_QUESTIONS_SECTION,
  QUEST_RESET_CURRENT_QUEST_ITEM,
  QUEST_SURVEY_QUESTIONS_SECTION,
  QUEST_UPDATE,
  QUEST_UPDATE_COMPLETED,
  QUEST_UPDATE_ERROR,
  RESET_APP_STATE,
  SET_CURRENT_QUEST_ITEM,
  UPDATE_USER_QUEST_STATUS,
  UPDATE_USER_QUEST_STATUS_COMPLETED,
  UPDATE_USER_QUEST_STATUS_ERROR,
  USER_QUESTS_LIST_GET,
  USER_QUESTS_LIST_GET_COMPLETED,
  USER_QUESTS_LIST_GET_ERROR,
  USER_QUEST_CLAIM_ERROR_RESET,
  USER_QUIZ_QUEST_CLAIM_SAFETY_CHECK_LINK_ERROR_RESET,
  USER_QUIZ_QUEST_TRY_AGAIN_RESET,
} from "../actions/actions.constants";

export interface QuestState {
  loading?: boolean;
  loaded?: boolean;
  error?: any;
  entities: Record<number, Quest>;
  entityIds: number[];
  hasMoreData: boolean;
  currentPage: number;
  currentId: number;
  metaTotalResult: number | null;
  prevPageFirstElementId: number;
  currentQuestItem: {
    [category: string]: {
      initial_form_data: any;
      sections: { [index: number]: any; is_duplicated?: boolean };
      more_details: any;
    };
  };
  filters?: { sortBy?: SortBy; filterBy?: string[] };
  userQuestsEntities: {
    entities: Record<number, UserQuest>;
    entityIds: number[];
    hasMoreData: boolean;
    currentPage: number;
    currentId: number;
    prevPageFirstElementId: number;
    metaTotalResult: number | null;
    filters?: { sortBy?: SortBy; filterBy?: string[] };
    loading?: boolean;
    loaded?: boolean;
    error?: string | null;
  };
  currentQueryQuest: {
    quest: Quest | undefined | null;
    loading?: boolean;
    loaded?: boolean;
    error?: string;
  };
  isClaimedSuccessfully?: null | boolean;
  isTryAgainQuest?: boolean | null;
  isSafetyCheckLinkError?: boolean;
  amount?: number | null;

  userQuestsAprrovalEntities: {
    entities: Record<number, UserQuest>;
    entityIds: number[];
    loading?: boolean;
    loaded?: boolean;
    hasMoreData: boolean;
    currentPage: number;
    currentId: number;
    metaTotalResult: number | null;
    prevPageFirstElementId: number;
    error?: string | null;
    isApprovalPendingForSelectedQuest: boolean;
  };
  safetyCheckLinkErrors?: Record<number, string>;
}

const initialState: QuestState = {
  loading: false,
  loaded: true,
  error: null,
  entities: {},
  entityIds: [],
  hasMoreData: true,
  currentPage: 1,
  currentId: -1,
  metaTotalResult: null,
  prevPageFirstElementId: -1,
  currentQuestItem: {},
  userQuestsEntities: {
    entities: {},
    entityIds: [],
    hasMoreData: true,
    currentPage: 1,
    currentId: -1,
    prevPageFirstElementId: -1,
    metaTotalResult: null,
    loading: false,
    loaded: true,
    error: null,
  },
  amount: null,
  isClaimedSuccessfully: null,
  isTryAgainQuest: null,
  currentQueryQuest: { quest: null },
  userQuestsAprrovalEntities: {
    entities: {},
    entityIds: [],
    loading: false,
    loaded: true,
    error: null,
    hasMoreData: true,
    currentPage: 1,
    currentId: -1,
    metaTotalResult: null,
    prevPageFirstElementId: -1,
    isApprovalPendingForSelectedQuest: false,
  },
};

export const questReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case CLAIM_USER_QUEST:
    case QUEST_UPDATE:
    case QUEST_COVER_IMAGE_UPDATE:
    case QUEST_CREATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };

    case QUEST_CREATE_COMPLETED: {
      const quest = action.payload?.res?.quest;
      return {
        ...state,

        entities: { [quest?.id]: { ...quest }, ...state.entities },
        entityIds: [quest?.id, ...state.entityIds],
        loading: false,
        loaded: true,
        error: "",
        metaTotalResult: state.metaTotalResult
          ? state.metaTotalResult + 1
          : state.metaTotalResult,
      };
    }
    case CLAIM_USER_QUEST_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action?.payload?.isSafetyCheckLinkError
          ? action.payload?.message?.split(": ")[0] +
            " : SAFETY_CHECK_LINK_FAILED"
          : action.payload?.message,
        isTryAgainQuest: action.payload?.isTryAgainQuest,
        isSafetyCheckLinkError: action?.payload?.isSafetyCheckLinkError,
        safetyCheckLinkErrors: action?.payload?.isSafetyCheckLinkError
          ? JSON.parse(action.payload?.message?.split(": ")[1] || "{}")
          : undefined,
      };
    }
    case QUEST_COVER_IMAGE_UPDATE_ERROR:
    case QUEST_UPDATE_ERROR:
    case QUEST_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case QUEST_COVER_IMAGE_UPDATE_COMPLETED:
    case QUEST_UPDATE_COMPLETED: {
      const quest = action.payload?.res?.quest;
      return {
        ...state,
        entities: { ...state.entities, [quest?.id]: { ...quest } },
        loading: false,
        loaded: true,
        error: "",
      };
    }

    case USER_QUESTS_LIST_GET:
    case QUESTS_LIST_GET: {
      return {
        ...state,
        filters: action.payload.req?.query ?? {},
        loading: true,
        loaded: false,
        error: "",
      };
    }
    case USER_QUESTS_LIST_GET_COMPLETED: {
      const hasMoreData =
        action.payload.res.meta?.current_page <
        action.payload.res.meta?.last_page;
      const currentPage = action.payload.res.meta?.current_page;

      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.payload.res.quests?.length
        ? action.payload.res.quests[0].id
        : state.prevPageFirstElementId;

      const quests: Quest[] = action.payload?.res?.quests;
      const questEntities = {
        entities: quests.reduce<{ [id: number]: Quest }>(
          (accumulator: any, quest) => {
            return { ...accumulator, [quest.id]: { ...quest } };
          },
          {}
        ),
        entityIds: quests.reduce<number[]>((accumulator: any, quest) => {
          return [...accumulator, quest.id];
        }, []),
      };

      return {
        ...state,
        entities: { ...state.entities, ...questEntities.entities },
        entityIds: [...questEntities.entityIds],
        hasMoreData,
        currentPage,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
        loading: false,
        loaded: true,
        error: undefined,
      };
    }

    case QUESTS_LIST_GET_COMPLETED: {
      const hasMoreData =
        action.payload.res.meta?.current_page <
        action.payload.res.meta?.last_page;
      const currentPage = action.payload.res.meta?.current_page;
      const metaTotalResult = action.payload.res.meta?.total;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.payload.res.quests?.length
        ? action.payload.res.quests[0].id
        : state.prevPageFirstElementId;

      const quests: Quest[] = action.payload?.res?.quests;
      const questEntities = {
        entities: quests.reduce<{ [id: number]: Quest }>(
          (accumulator: any, quest) => {
            return { ...accumulator, [quest.id]: { ...quest } };
          },
          {}
        ),
        entityIds: quests.reduce<number[]>((accumulator: any, quest) => {
          return [...accumulator, quest.id];
        }, []),
      };

      return {
        ...state,
        entities: { ...state.entities, ...questEntities.entities },
        entityIds:
          state.currentPage < currentPage
            ? [...state.entityIds, ...questEntities.entityIds]
            : [...questEntities.entityIds],
        hasMoreData,
        currentPage,
        metaTotalResult,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
        loading: false,
        loaded: true,
        error: undefined,
      };
    }

    case QUEST_GET: {
      return {
        ...state,
        currentQueryQuest: {
          ...state.currentQueryQuest,
          loading: true,
          loaded: false,
        },
      };
    }

    case QUEST_GET_COMPLETED: {
      const quest = action.payload.res.quest;
      return {
        ...state,
        currentQueryQuest: {
          quest,
          loading: false,
          loaded: true,
          error: undefined,
        },
      };
    }

    case QUEST_GET_ERROR: {
      return {
        ...state,
        currentQueryQuest: {
          loading: false,
          loaded: true,
          error: action.payload?.message,
        },
      };
    }
    case USER_QUESTS_LIST_GET_ERROR:
    case QUESTS_LIST_GET_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case CLAIM_USER_QUEST_COMPLETED: {
      const userQuest: UserQuest = action.payload?.res?.quest;
      const quest = state.entities[userQuest.quest_id];
      let updatedQuest;
      if (userQuest.status === UserQuestStatus.CLAIMED) {
        updatedQuest = {
          ...quest,
          claimedQuestCountByUser: quest.claimedQuestCountByUser + 1,
          claimed_quantity: (quest?.claimed_quantity || 0) + quest?.per_wallet,
          used: quest?.used + quest?.per_wallet,
        };
      } else if (userQuest.status === UserQuestStatus.PENDING) {
        updatedQuest = {
          ...quest,
          pendingQuestCountByUser: quest.pendingQuestCountByUser + 1,
          used: quest?.used + quest?.per_wallet,
          claimed_quantity: (quest?.claimed_quantity || 0) + quest?.per_wallet,
        };
      }
      return {
        ...state,
        entities: { ...state.entities, [userQuest.quest_id]: updatedQuest },
        amount: userQuest?.amount,
        isClaimedSuccessfully: !!userQuest?.meta?.transaction_id,
        loading: false,
        loaded: true,
      };
    }
    case CLAIMED_USER_QUEST_AMOUNT_RESET: {
      return {
        ...state,
        amount: null,
        isClaimedSuccessfully: null,
      };
    }
    case MY_QUESTS_ACTIVITY_LIST_GET: {
      const filterBy = action.payload?.req?.filters;
      const sortBy = action.payload?.req?.sort_by;
      return {
        ...state,
        userQuestsEntities: {
          ...state.userQuestsEntities,
          filters: {
            filterBy,
            sortBy,
          },
          error: undefined,
          loading: true,
          loaded: false,
        },
      };
    }
    case MY_QUESTS_ACTIVITY_LIST_GET_ERROR: {
      return {
        ...state,
        userQuestsEntities: {
          ...state.userQuestsEntities,
          loading: false,
          loaded: true,
          error: action.payload?.message,
        },
      };
    }
    case MY_QUESTS_ACTIVITY_LIST_RESET: {
      return {
        ...state,
        userQuestsEntities: {
          ...state.userQuestsEntities,
          entityIds: [],
        },
      };
    }
    case MY_QUESTS_ACTIVITY_LIST_GET_COMPLETED: {
      const hasMoreData =
        action.payload.res.meta?.current_page <
        action.payload.res.meta?.last_page;
      const currentPage = action.payload.res.meta?.current_page;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const metaTotalResult = action.payload.res.meta?.total;
      const newPrevPageFirstElementId = action.payload.res.user_quests?.length
        ? action.payload.res.user_quests[0].id
        : state.prevPageFirstElementId;

      const userQuests: UserQuest[] = action.payload?.res?.user_quests;
      const entityIds = new Set(state.userQuestsEntities.entityIds);
      userQuests.forEach((userQuest: UserQuest) => {
        entityIds.add(userQuest.id);
      });
      const entities = userQuests.reduce((accumulator: any, quest: any) => {
        accumulator[quest.id] = { ...quest };
        return accumulator;
      }, {});
      return {
        ...state,
        userQuestsEntities: {
          ...state.userQuestsEntities,
          entities: {
            ...state.userQuestsEntities.entities,
            ...entities,
          },
          entityIds: Array.from(entityIds),
          hasMoreData,
          currentPage,
          metaTotalResult,
          currentId: firstElementOfPrevPageId,
          prevPageFirstElementId: newPrevPageFirstElementId,
          loading: false,
          loaded: true,
          error: undefined,
        },
      };
    }

    case QUEST_NEW_FORM_SUBMISSION: {
      const formData = action.payload.req;
      const cover_image = action.payload.cover_image;
      const category = action.payload.category;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            initial_form_data: { ...formData, cover_image },
          },
        },
      };
    }
    case QUEST_LEAR2EARN_VIDEO_URL_SECTION: {
      const sectionIndex = action.payload.index;
      const subType = action.payload.subType;
      const category = action.payload.category;
      const quest_content = action.payload.req.quest_content;
      const is_duplicated = action.payload.isQuestDuplicated;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            sections: {
              ...state.currentQuestItem[category]?.sections,
              [sectionIndex]: { subType, quest_content },
              is_duplicated,
            },
          },
        },
      };
    }
    case QUEST_QUIZ_QUESTIONS_SECTION: {
      const sectionIndex = action.payload.index;
      const category = action.payload.category;
      const is_duplicated = action.payload.isQuestDuplicated;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            sections: {
              ...state.currentQuestItem[category]?.sections,
              [sectionIndex]: { ...action.payload.req },
              is_duplicated,
            },
          },
        },
      };
    }
    case QUEST_POLL_QUESTION_SECTION: {
      const sectionIndex = action.payload.index;
      const poll = action.payload.req.poll;
      const category = action.payload.category;
      const is_duplicated = action.payload.isQuestDuplicated;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            sections: {
              ...state.currentQuestItem[category]?.sections,
              [sectionIndex]: poll,
              is_duplicated,
            },
          },
        },
      };
    }
    case QUEST_SURVEY_QUESTIONS_SECTION: {
      const sectionIndex = action.payload.index;
      const survey = action.payload.req.survey;
      const category = action.payload.category;
      const is_duplicated = action.payload.isQuestDuplicated;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            sections: {
              ...state.currentQuestItem[category]?.sections,
              [sectionIndex]: survey,
              is_duplicated,
            },
          },
        },
      };
    }

    case QUEST_MORE_DETAILS_FORM_SUBMISSION: {
      const category = action.payload.category;
      const details = action.payload.req;
      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            more_details: { ...details },
          },
        },
      };
    }
    case QUEST_PROOF_SECTION: {
      const sectionIndex = action.payload.index;
      const proofMeta = action.payload.req;
      const category = action.payload.category;
      const is_duplicated = action.payload.isQuestDuplicated;

      return {
        ...state,
        currentQuestItem: {
          [category]: {
            ...state.currentQuestItem[category],
            sections: {
              ...state.currentQuestItem[category]?.sections,
              [sectionIndex]: { ...proofMeta },
              is_duplicated,
            },
          },
        },
      };
    }
    case SET_CURRENT_QUEST_ITEM: {
      return {
        ...state,
        currentQuestItem: { ...action.payload },
      };
    }
    case QUEST_RESET_CURRENT_QUEST_ITEM: {
      return { ...state, currentQuestItem: {} };
    }
    default:
      return state;

    case LIST_ALL_USER_QUEST: {
      return {
        ...state,
        filters: action.payload.req?.query ?? {},
        loading: true,
        loaded: false,
        error: "",
      };
    }

    case LIST_ALL_USER_QUEST_COMPLETED: {
      const userQuests = action.payload.res.quests;
      const userQuestsMeta = action.payload.res.meta;
      const hasMoreData =
        userQuestsMeta?.current_page < userQuestsMeta?.last_page;
      const currentPage = userQuestsMeta?.current_page;
      const metaTotalResult = userQuestsMeta.total;
      const firstElementOfPrevPageId =
        state.userQuestsAprrovalEntities.prevPageFirstElementId;
      const newPrevPageFirstElementId = userQuests?.length
        ? userQuests[0].id
        : state.userQuestsAprrovalEntities.prevPageFirstElementId;

      const entityIds = new Set(state.userQuestsAprrovalEntities.entityIds);
      userQuests.forEach((userQuest: UserQuest) => {
        entityIds.add(userQuest.id);
      });
      const entities = userQuests.reduce((accumulator: any, quest: any) => {
        accumulator[quest.id] = { ...quest };
        return accumulator;
      }, {});

      return {
        ...state,
        userQuestsAprrovalEntities: {
          ...state.userQuestsAprrovalEntities,
          entities: {
            ...state.userQuestsAprrovalEntities.entities,
            ...entities,
          },
          entityIds: Array.from(entityIds),
          hasMoreData,

          currentPage,
          currentId: firstElementOfPrevPageId,
          metaTotalResult,
          prevPageFirstElementId: newPrevPageFirstElementId,
          loading: false,
          loaded: true,
          error: undefined,
        },
        loading: false,
        loaded: true,
      };
    }
    case LIST_ALL_USER_QUEST_RESET: {
      return {
        ...state,
        userQuestsAprrovalEntities: initialState.userQuestsAprrovalEntities,
      };
    }

    case LIST_ALL_USER_QUEST_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case APPROVE_OR_REJECT_ALL_USER_QUEST:
    case UPDATE_USER_QUEST_STATUS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };

    case UPDATE_USER_QUEST_STATUS_COMPLETED:
      const updatedQuest: UserQuest = action.payload.res.updatedQuest;
      return {
        ...state,
        userQuestsAprrovalEntities: {
          ...state.userQuestsAprrovalEntities,
          entities: {
            ...state.userQuestsAprrovalEntities.entities,
            [updatedQuest.id]: {
              ...state.userQuestsAprrovalEntities.entities[updatedQuest.id],
              ...updatedQuest,
            },
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };

    case APPROVE_OR_REJECT_ALL_USER_QUEST_ERROR:
    case UPDATE_USER_QUEST_STATUS_ERROR:
      const errorMessage = action.payload.message;
      return {
        ...state,
        loading: false,
        loaded: true,
        error: errorMessage,
      };

    case APPROVE_OR_REJECT_ALL_USER_QUEST_COMPLETED: {
      const updatedQuests: UserQuest[] = action.payload.res.user_quests;
      const sanitizeUserQuest = updatedQuests.reduce(
        (accumulator: any, userQuest) => {
          return {
            ...accumulator,
            [userQuest.id]: {
              ...userQuest,
            },
          };
        },
        {}
      );
      const entityIds = new Set([
        ...state.userQuestsAprrovalEntities.entityIds,
        ...updatedQuests.map((quest) => quest.id),
      ]);
      return {
        ...state,
        userQuestsAprrovalEntities: {
          ...state.userQuestsAprrovalEntities,
          entities: {
            ...state.userQuestsAprrovalEntities.entities,
            ...sanitizeUserQuest,
          },
          entityIds: Array.from(entityIds),
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case IS_QUEST_APPROVAL_PENDING_FOR_SELECTED_QUEST: {
      return {
        ...state,
        userQuestsAprrovalEntities: {
          ...state.userQuestsAprrovalEntities,
          isApprovalPendingForSelectedQuest: action.payload?.res?.is_pending,
        },
      };
    }
    case USER_QUIZ_QUEST_TRY_AGAIN_RESET: {
      return {
        ...state,
        isTryAgainQuest: undefined,
      };
    }
    case USER_QUEST_CLAIM_ERROR_RESET: {
      const resetLinkSafetyError = action.payload?.resetLinkSafetyError;
      const safetyCheckLinkErrors = resetLinkSafetyError
        ? undefined
        : {
            ...state.safetyCheckLinkErrors,
          };
      return {
        ...state,
        error: undefined,
        isSafetyCheckLinkError: undefined,
        safetyCheckLinkErrors,
      };
    }
    case USER_QUIZ_QUEST_CLAIM_SAFETY_CHECK_LINK_ERROR_RESET: {
      const id = action.payload?.req?.id;
      const safetyCheckLinkErrors = {
        ...state.safetyCheckLinkErrors,
      };
      delete safetyCheckLinkErrors[id];
      return {
        ...state,
        safetyCheckLinkErrors,
      };
    }
  }
};
