import { Admin } from "../../models/entities/Admin";
import { Brand } from "../../models/entities/Brand";
import { User } from "../../models/entities/User";
import {
  AdminMembersFiltersKey,
  TransactionStatus,
  TransferType,
} from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  // Override being used on server side
  // override_authorization should be of type: `bearer eYxxxxxxxxxxxxxxxxxxx`
  async me(override_authorization?: string): Promise<{
    user?: User;
    admin?: Admin;
    brand: Brand;
  }> {
    return baseApiService.get(
      "/me",
      override_authorization
        ? {
            headers: {
              Authorization: override_authorization,
            },
          }
        : undefined
    );
  }

  async transferLore(data: {
    amount: number;
    transfer_type: TransferType;
  }): Promise<{
    transaction_id: number;
    signature_data: {
      nonce: string;
      v: string;
      r: string;
      s: string;
    };
  }> {
    return await baseApiService.post("/transfer-lore", data);
  }

  async updateProfileImage(): Promise<{ user: User; signed_url: string }> {
    const response = await baseApiService.put(`users/profile_pic`);
    return { user: response.user, signed_url: response.signed_url };
  }

  async updateProfile(payload: { username: string }): Promise<{ user: User }> {
    const response = await baseApiService.put(`/users`, {
      username: payload.username,
    });
    return { user: response.user };
  }

  async updateTransferLore(
    data: {
      status: TransactionStatus;
      transfer_type: TransferType;
      transaction_hash?: string;
    },
    transactionId: number
  ): Promise<void> {
    return await baseApiService.put("/transfer-lore/" + transactionId, data);
  }

  async login(payload: { code: any; provider: string; redirect_uri: string }) {
    const response = await baseApiService.post(
      "/socials/token",
      {},
      {
        params: {
          code: payload.code,
          provider: payload.provider,
          redirect_uri: payload.redirect_uri,
        },
      }
    );
    window.localStorage.setItem("socialLogin", "true");
    window.dispatchEvent(new Event("storage"));
    return { success: response.success };
  }

  async deauthorize(payload: {
    id: number;
    provider: string;
  }): Promise<{ user: User }> {
    const response = await baseApiService.delete("/socials/deauthorize", {
      params: {
        id: payload.id.toString(),
        provider: payload.provider,
      },
    });
    return { user: response.data.user };
  }

  async list(payload: {
    search: string;
    page: string;
    sort_by?: string;
    filters?: {
      [AdminMembersFiltersKey.TIERS]: Array<string>;
      [AdminMembersFiltersKey.PROVIDERS]: Array<string>;
    };
  }): Promise<{
    users: User[];
    meta: any;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response: any = await baseApiService.get("/admin/users", {
      params: {
        search: payload.search || "",
        page: payload.page || "",
        sort_by: payload.sort_by || "",
        filters: JSON.stringify(payload.filters) || [],
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { users: response.data.users, meta: response.data.meta };
  }

  async get(id: number): Promise<{ user: User }> {
    const authToken = await webStorageService.getAuthToken();
    const response: any = await baseApiService.get(`/admin/users/${id}`, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      user: response.data.user,
    };
  }

  async leaderboard(): Promise<{
    rank: number;
    users: User;
  }> {
    const response: any = await baseApiService.get(`/users/leader-board`);
    return {
      rank: response.data.rank,
      users: response.data.users,
    };
  }

  async addEmail(data: { email?: string }): Promise<{ code: string }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.get("/users/alternate-login-email", {
      params: data,
      extras: { useAuth: authToken ? true : false },
    });
  }

  async addEmailCheckVerification(data: {
    code: string;
  }): Promise<{ user?: User }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.get("/users/alternate-login-check-verification", {
      params: data,
      extras: { useAuth: authToken ? true : false },
    });
  }

  async addEmailVerify(data: { code: string }): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.get("/users/alternate-login-verify-email", {
      params: data,
      extras: { useAuth: false },
    });
  }

  async addWalletAddress(data: {
    address?: string;
  }): Promise<{ nonce: string }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.get("/users/alternate-login-wallet-address", {
      params: data,
      extras: { useAuth: authToken ? true : false },
    });
  }

  async verifyWalletAddressSignature(data: {
    address?: string;
    signature: string;
  }): Promise<{ user?: User }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.post(
      "/users/alternate-login-verify-signature",
      data,
      {
        extras: { useAuth: authToken ? true : false },
      }
    );
  }

  async rejectWalletAddressSignature(): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    return baseApiService.post("/users/alternate-login-reject-signature", {
      extras: { useAuth: authToken ? true : false },
    });
  }

  async award(payload: {
    id: any;
    points: number;
    comments?: string;
  }): Promise<{ user: User }> {
    const response = await baseApiService.put(`/admin/gift/${payload.id}`, {
      amount: payload.points,
      comments: payload.comments,
    });
    return { user: response.data.user };
  }

  async revoke(payload: {
    id: any;
    points: number;
    comments?: string;
  }): Promise<{ user: User }> {
    const response = await baseApiService.put(`/admin/revoke/${payload.id}`, {
      amount: payload.points,
      comments: payload.comments,
    });
    return { user: response.data.user };
  }
  async updateTwitter(): Promise<{ user: User }> {
    const response = await baseApiService.put(`/users/twitter-profile-data`);
    return { user: response.data.user };
  }

  async suggestionSubmission(data: {
    subject?: string;
    suggestion: string;
    email: string;
    namespace: string;
    type: string;
  }): Promise<{ success: boolean }> {
    const response = await baseApiService.post(`/users/suggestions`, data, {
      params: { type: data.type },
    });
    return { success: response.data.success };
  }

  async getSuggestionImageSignedUrl(): Promise<{
    namespace: string;
    signed_url: string;
  }> {
    const response = await baseApiService.get(
      `/users/suggestions-image/signed-url`
    );
    return {
      namespace: response.data.namespace,
      signed_url: response.data.signed_url,
    };
  }

  async getUserReferralCode(): Promise<{ referral_code: string }> {
    const response = await baseApiService.get(`/users/referral-code`);
    return response.data;
  }

  async resendAlternateEmail(data: {
    code: string;
    email: string;
  }): Promise<{ success: boolean }> {
    return baseApiService.get("/users/resend-alternate-email", {
      params: data,
    });
  }

  async deleteMyAccount(): Promise<{ success: boolean }> {
    return baseApiService.put("/users/delete-my-account");
  }

  async downloadMembersStats(payload: {
    search: string;
    filters?: {
      [AdminMembersFiltersKey.TIERS]: Array<string>;
      [AdminMembersFiltersKey.PROVIDERS]: Array<string>;
    };
  }): Promise<{ url: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("admin/users/download", {
      params: {
        search: payload.search || "",
        filters: JSON.stringify(payload.filters) || [],
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      url: response.data,
    };
  }
}

export const userService = UserService.getInstance();
