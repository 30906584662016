import React from "react";

interface MilestoneBadgeIconProps {
  className?: string;
}

const MilestoneBadgeIcon: React.FC<MilestoneBadgeIconProps> = (props) => {
  return (
    <span className={`block ${props.className} `}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.4 16.1002V6.86025C22.4 6.02025 21.56 5.32025 20.72 5.46025C20.16 5.60025 19.6 5.60025 19.04 5.60025C17.5 5.60025 15.96 5.18025 14.84 4.34025C14.42 4.06025 13.86 4.06025 13.44 4.34025C12.04 5.18025 10.78 5.60025 9.23998 5.60025C8.53998 5.60025 7.83998 5.60025 7.27998 5.46025C6.43998 5.18025 5.59998 5.88025 5.59998 6.86025V16.2402"
          stroke="currentColor"
          strokeWidth="1.8125"
          strokeMiterlimit="10"
        />
        <path
          d="M5.59998 10.0803V18.2003C5.59998 19.3203 6.15998 20.1603 7.13998 20.7203L12.74 23.6603C13.58 24.0803 14.42 24.0803 15.26 23.6603L20.86 20.8603C21.84 20.4403 22.4 19.4603 22.4 18.3403V10.0803"
          stroke="currentColor"
          strokeWidth="1.8125"
          strokeMiterlimit="10"
        />
        <path
          d="M14.2805 10.0798L15.2605 12.0398C15.4005 12.3198 15.6805 12.4598 15.9605 12.4598L18.0605 12.7398C18.3405 12.7398 18.3405 13.0198 18.2005 13.1598L16.6605 14.6998C16.5205 14.8398 16.3805 15.1198 16.3805 15.3998L16.8005 17.4998C16.8005 17.7798 16.6605 17.9198 16.3805 17.7798L14.4205 16.7998C14.1405 16.6598 13.8605 16.6598 13.5805 16.7998L11.6205 17.7798C11.4805 17.9198 11.2005 17.7798 11.2005 17.4998L11.6205 15.3998C11.6205 15.1198 11.6205 14.8398 11.3405 14.6998L9.94047 13.2998C9.66047 13.1598 9.80047 12.8798 10.0805 12.8798L12.1805 12.5998C12.4605 12.5998 12.7405 12.3198 12.8805 12.1798L13.8605 10.2198C13.8605 9.93975 14.1405 9.93975 14.2805 10.0798Z"
          stroke="currentColor"
          strokeWidth="1.8125"
          strokeMiterlimit="10"
        />
      </svg>
    </span>
  );
};

export default React.memo(MilestoneBadgeIcon);
