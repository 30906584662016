import { useEffect, useState } from "react";
import { throttle } from "lodash";

export const getDeviceConfig = (width: number) => {
  if (width < 640) {
    return "xs";
  } else if (width >= 640 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 1024) {
    return "md";
  } else if (width >= 1024 && width < 1280) {
    return "lg";
  } else if (width >= 1280 && width < 1536) {
    return "xl";
  } else if (width >= 1536) {
    return "2xl";
  }
};

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<string | undefined>();
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    setWidth(window?.innerWidth);
    setBreakPoint(getDeviceConfig(window?.innerWidth));

    const calcInnerWidth = throttle(() => {
      setWidth(window?.innerWidth);
      setBreakPoint(getDeviceConfig(window?.innerWidth));
    }, 200);

    window?.addEventListener?.("resize", calcInnerWidth);

    return () => window?.removeEventListener?.("resize", calcInnerWidth);
  }, []);

  return {
    width,
    breakpoint,
    isMobile: ["xs", "sm"].includes(breakpoint as any),
    isMedium: ["md"].includes(breakpoint as any),
    isLargeScreen: ["xl", "2xl"].includes(breakpoint as any),
    isFooterLarge: ["2xl"].includes(breakpoint as any),
  };
};

export default useBreakpoint;
