import { useField } from "formik";
import React, { useEffect } from "react";
import { InputProps } from "../InputProps";
import Input from "./Input";

interface FormikInputProps extends Omit<InputProps, "onChange" | "value"> {
  name: string;
  outsideError?: string;
  setSubmitting?: (isSubmitting: boolean) => void;
}

const FormikInput: React.FC<FormikInputProps> = (props) => {
  const { outsideError, setSubmitting, ...inputProps } = props;
  const [formikInputProps, meta, helpers] = useField(inputProps.name);

  const { value, error, touched } = meta;
  const { setValue, setError } = helpers;
  useEffect(() => {
    setError(outsideError);
    if (outsideError && setSubmitting) setSubmitting(false);
  }, [outsideError]);

  return (
    <Input
      value={value}
      onChange={(e): void => setValue(e.target.value)}
      error={touched ? error : undefined}
      onBlur={formikInputProps.onBlur}
      {...inputProps}
    />
  );
};

export default React.memo(FormikInput);
