import { Item } from "../../models/entities/Item";
import { UserItem } from "../../models/entities/UserItem";
import { SortBy } from "../../utils/enums";
import {
  ITEM_CREATE,
  ITEM_CREATE_COMPLETED,
  ITEM_CREATE_ERROR,
  ITEM_EDIT,
  ITEM_EDIT_COMPLETED,
  ITEM_EDIT_ERROR,
  ITEM_GET,
  ITEM_GET_COMPLETED,
  ITEM_GET_ERROR,
  ITEM_IMAGE_EDIT,
  ITEM_IMAGE_EDIT_COMPLETED,
  ITEM_IMAGE_EDIT_ERROR,
  ITEM_LIST_GET,
  ITEM_LIST_GET_COMPLETED,
  ITEM_LIST_GET_ERROR,
  ITEM_LIST_OWNED_GET,
  ITEM_LIST_OWNED_GET_COMPLETED,
  ITEM_LIST_OWNED_GET_ERROR,
  ITEM_LIST_OWNED_RESET,
  ITEM_LIST_RESET,
  ITEM_REDEEM,
  ITEM_REDEEM_COMPLETED,
  ITEM_REDEEM_ERROR,
  ITEM_REDEEM_RESET,
  ITEM_REPORT_DOWNLOAD,
  ITEM_REPORT_DOWNLOAD_COMPLETED,
  ITEM_SET_CURRENT_ELEMENT,
  ITEM_SET_OWNED_CURRENT_ELEMENT,
  ITEM_STATS_DOWNLOAD,
  ITEM_STATS_DOWNLOAD_COMPLETED,
  ITEM_STATS_LIST,
  ITEM_STATS_LIST_COMPLETED,
  ITEM_STATS_LIST_ERROR,
} from "./actions.constants";

export const itemGetAction = (payload: { id: number }) => ({
  type: ITEM_GET,
  payload,
});

export const itemGetCompletedAction = (payload: { res: { item: Item } }) => ({
  type: ITEM_GET_COMPLETED,
  payload,
});

export const itemGetErrorAction = (payload: { message: string }) => ({
  type: ITEM_GET_ERROR,
  payload,
});

export const itemStatsListAction = (payload: {
  id: number;
  page: number;
  query: { search?: string; filter?: any[]; sort_by?: string };
}) => ({
  type: ITEM_STATS_LIST,
  payload,
});

export const itemStatsListCompletedAction = (payload: {
  res: { item_stats: UserItem[]; id: number; meta: any };
}) => ({
  type: ITEM_STATS_LIST_COMPLETED,
  payload,
});

export const itemStatsListErrorAction = (payload: { message: string }) => ({
  type: ITEM_STATS_LIST_ERROR,
  payload,
});

export const itemStatsDownloadAction = (payload: {
  id: number;
  query: { search?: string };
}) => ({
  type: ITEM_STATS_DOWNLOAD,
  payload,
});

export const itemStatsDownloadCompletedAction = () => ({
  type: ITEM_STATS_DOWNLOAD_COMPLETED,
});

export const itemCreateAction = (payload: {
  req: Omit<Omit<Item, "image_url">, "quantity_used">;
  filters?: string[];
  fileObjectUrl: string;
}) => ({
  type: ITEM_CREATE,
  payload,
});

export const itemCreateCompletedAction = (payload: {
  filters?: string[];
  res: { item: Item };
}) => ({
  type: ITEM_CREATE_COMPLETED,
  payload,
});

export const itemCreateErrorAction = (payload: { message: string }) => ({
  type: ITEM_CREATE_ERROR,
  payload,
});

export const itemImageEditAction = (payload: {
  id: number;
  fileObjectUrl: string;
}) => ({
  type: ITEM_IMAGE_EDIT,
  payload,
});

export const itemImageEditCompletedAction = (payload: {
  res: { item: Item };
}) => ({
  type: ITEM_IMAGE_EDIT_COMPLETED,
  payload,
});

export const itemImageEditErrorAction = (payload: { message: string }) => ({
  type: ITEM_IMAGE_EDIT_ERROR,
  payload,
});

export const itemEditAction = (payload: {
  id: number;
  req: any;
  filters?: string[];
}) => ({
  type: ITEM_EDIT,
  payload,
});

export const itemEditCompletedAction = (payload: {
  res: { item: Item; filters?: string[] };
}) => ({
  type: ITEM_EDIT_COMPLETED,
  payload,
});

export const itemEditErrorAction = (payload: { message: string }) => ({
  type: ITEM_EDIT_ERROR,
  payload,
});

export const itemListGetAction = (payload: {
  req: {
    end_point?: string;
    page: number;
    query: {
      search?: string;
      sortBy?: SortBy;
      filters?: string[];
      category?: string[];
    };
  };
}): any => ({ type: ITEM_LIST_GET, payload });

export const itemListGetCompletedAction = (payload: {
  res: {
    items: Item[];
    meta: any;
  };
}) => ({ type: ITEM_LIST_GET_COMPLETED, payload });

export const itemListGetErrorAction = (payload: { message: string }) => ({
  type: ITEM_LIST_GET_ERROR,
  payload,
});

export const itemSetCurrentElement = (payload: { currElementId: number }) => ({
  type: ITEM_SET_CURRENT_ELEMENT,
  payload,
});

export const itemListOwnedGetAction = (payload: {
  wallet_address?: string;
  req: {
    page: number;
    query: { search?: string; sortBy?: SortBy; categories?: string[] };
  };
}) => ({ type: ITEM_LIST_OWNED_GET, payload });

export const itemListOwnedGetCompletedAction = (payload: {
  wallet_address?: string;
  res: {
    userItems: UserItem[];
    meta: any;
  };
}) => ({ type: ITEM_LIST_OWNED_GET_COMPLETED, payload });

export const itemListOwnedGetErrorAction = (payload: { message: string }) => ({
  type: ITEM_LIST_OWNED_GET_ERROR,
  payload,
});

export const itemSetOwnedCurrentElement = (payload: {
  currElementId: number;
}) => ({
  type: ITEM_SET_OWNED_CURRENT_ELEMENT,
  payload,
});

export const itemRedeemAction = (payload: {
  id: number;
  req: {
    email?: string;
    discord?: string;
    quantity: number;
  };
}) => ({
  type: ITEM_REDEEM,
  payload,
});

export const itemRedeemCompletedAction = (payload: {
  res: {
    userItem: UserItem;
    quantity: number;
  };
}) => ({
  type: ITEM_REDEEM_COMPLETED,
  payload,
});

export const itemRedeemErrorAction = (payload: { message: string }) => ({
  type: ITEM_REDEEM_ERROR,
  payload,
});

export const itemListResetAction = () => ({
  type: ITEM_LIST_RESET,
});

export const itemListOwnedResetAction = () => ({
  type: ITEM_LIST_OWNED_RESET,
});

export const itemReportDownloadAction = () => ({
  type: ITEM_REPORT_DOWNLOAD,
});

export const itemReportDownloadCompletedAction = () => ({
  type: ITEM_REPORT_DOWNLOAD_COMPLETED,
});

export const itemRedeemReset = () => ({
  type: ITEM_REDEEM_RESET,
});
