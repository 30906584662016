import classNames from "classnames";
import { Info } from "phosphor-react";
import React, { useEffect, useState } from "react";
import LockCircleIcon from "../../../../public/icons/lock-circle-icon";
import MilestoneBadgeIcon from "../../../../public/icons/milestone-badge-icon";
import { BrandContext } from "../../../contexts/BrandContext";
import Utils from "../../../utils/Utils";
import BrandTooltip from "../BrandTooltip";
import ProgressBar from "./ProgressBar";

// This array should be sorted increasingly based on targetPoints
export type MilestoneProgressType = {
  tag: string;
  targetPoints: number;
}[];

interface MilestoneProgressProps {
  id: string;
  showLifetimePoints?: boolean;
  lifetimePoints: number;
  milestoneProgressData: MilestoneProgressType;
}

const MilestoneProgress: React.FC<MilestoneProgressProps> = ({
  id,
  lifetimePoints,
  milestoneProgressData,
  showLifetimePoints,
}) => {
  const [target, setTarget] = useState(1000);

  const nextTarget = milestoneProgressData.filter(
    (milestone) => milestone.targetPoints > lifetimePoints
  )[0];
  useEffect(() => {
    if (lifetimePoints >= target) {
      setTarget(target + 1000);
    }
  }, [target, lifetimePoints]);

  const brandValue = React.useContext(BrandContext);

  return (
    <>
      <div className="flex items-center justify-between w-full space-x-2">
        {milestoneProgressData.map((milestone) => (
          <div
            key={milestone.tag}
            className="flex min-w-[2rem] items-center space-x-1"
          >
            {lifetimePoints >= milestone.targetPoints ? (
              <MilestoneBadgeIcon className="flex-shrink-0 w-5 h-5" />
            ) : (
              <LockCircleIcon className="flex-shrink-0 w-4 h-4 opacity-50" />
            )}

            <BrandTooltip
              id={`${milestone.tag}-tooltip-${id}`}
              tooltip={`${milestone.tag} - ${milestone.targetPoints} ${
                brandValue.currency ?? `Points`
              }`}
              place="top"
              className="truncate"
            >
              <div className="inline-flex items-center">
                <h2
                  className={classNames(
                    "text-xs truncate font-semibold leading-none",
                    {
                      "opacity-50": lifetimePoints < milestone.targetPoints,
                    }
                  )}
                >
                  {Utils.getEllipsisedText(milestone.tag, 6)}
                </h2>
                <Info className="px-0.5 w-4 h-4 flex-shrink-0" />
              </div>
            </BrandTooltip>
          </div>
        ))}
      </div>
      <div id="scale" className="flex flex-col items-stretch w-full space-y-1">
        <div className={classNames("")}>
          <div className="flex justify-between w-full pb-[2px] text-[0.6rem] font-light">
            <span className="relative flex flex-col items-end">
              <span className="absolute ">0</span>
              <span className="translate-y-[65%] font-bold opacity-50">|</span>
            </span>
            {new Array(4).fill(0).map((_, index: number) => {
              return (
                <div key={index} className="relative grid col-4">
                  <div className="relative flex flex-col items-end">
                    <span className="absolute translate-x-[40%]">
                      {Utils.milestoneScale(
                        nextTarget?.targetPoints ?? target,
                        index
                      )}
                    </span>
                    <span className="translate-y-[65%] font-bold  opacity-50">
                      |
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Progress Bar */}
          <ProgressBar
            percentage={
              !!nextTarget?.targetPoints
                ? (lifetimePoints / nextTarget?.targetPoints) * 100
                : (lifetimePoints / target) * 100
            }
          />
        </div>
        <h1 className="text-xs font-semibold text-center opacity-70">
          {!!nextTarget?.targetPoints
            ? `${nextTarget.targetPoints - lifetimePoints} ${
                brandValue.currency ?? `Points`
              } To ${nextTarget?.tag}`
            : `${target - lifetimePoints} ${
                brandValue.currency ?? `Points`
              } to next target`}
        </h1>
        {showLifetimePoints && (
          <span className="flex items-center justify-center space-x-1 font-medium text-center text-md ">
            <span className="opacity-80">
              Lifetime {brandValue?.currency ?? "Points"} Earned:
            </span>
            <span className="font-bold">{lifetimePoints}</span>
          </span>
        )}
      </div>
    </>
  );
};

export default React.memo(MilestoneProgress);
