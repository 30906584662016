import {
  ITEM_STATS_DOWNLOAD,
  ITEM_STATS_DOWNLOAD_COMPLETED,
  ITEM_STATS_LIST,
  ITEM_STATS_LIST_COMPLETED,
  ITEM_STATS_LIST_ERROR,
  RESET_APP_STATE,
} from "../actions/actions.constants";
import { UserItem } from "../../models/entities/UserItem";

export interface ItemStatsState {
  loading?: boolean;
  loaded?: boolean;
  error?: string | null;
  entities: Record<number, UserItem[]>;
  entityIds: number[];
  hasMoreData: boolean;
  currentPage: number;
  currentId: number;
  prevPageFirstElementId: number;
}

const initialState: ItemStatsState = {
  loading: false,
  loaded: true,
  error: null,
  entities: {},
  entityIds: [],
  hasMoreData: true,
  currentPage: 1,
  currentId: -1,
  prevPageFirstElementId: -1,
};

export const itemStatsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case ITEM_STATS_DOWNLOAD:
    case ITEM_STATS_LIST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case ITEM_STATS_LIST_COMPLETED: {
      const itemId = action.payload.res.id;
      const hasMoreData =
        action.payload.res.meta.current_page <
        action.payload.res.meta.last_page;
      const currentPage = action.payload.res.meta.current_page;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.payload.res.item_stats.length
        ? action.payload.res.item_stats[0].id
        : state.prevPageFirstElementId;
      const entityIds = new Set(state.entityIds);
      action.payload.res.item_stats.forEach((userItem: UserItem) => {
        entityIds.add(userItem.id);
      });
      let entities = {
        ...state?.entities,
        [itemId]: [
          // ...(state.entities?.[itemId] ?? []),
          ...action.payload.res.item_stats,
        ],
      };

      return {
        ...state,
        entities,
        entityIds: Array.from(entityIds),
        hasMoreData,
        currentPage,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
        loading: false,
        loaded: true,
        error: undefined,
      };
    }

    case ITEM_STATS_DOWNLOAD_COMPLETED: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ITEM_STATS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    default:
      return state;
  }
};
