import { delay } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers";
import { UserState } from "../../../../redux/reducers/user.reducer";
import { ModalType } from "../../../../utils/enums";
import BrandHeading from "../../custom-components/heading/BrandHeading";
import CustomModal from "../CustomModal";
import SupportModalFormSection from "./SupportModalFormSection";
import { userSuggestionOrSupportSubmissionResetAction } from "../../../../redux/actions/user.actions";

interface SupportModalProps {
  isShareModalOpen: boolean;
  setIsShareModalOpen: (value: boolean) => void;
  modalType: string;
  setModalType: Function;
  asAQuest?: boolean;
}

const SupportModal: React.FC<SupportModalProps> = ({
  setIsShareModalOpen,
  isShareModalOpen,
  modalType,
  setModalType,
  asAQuest,
}) => {
  const dispatch = useDispatch()
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const { is_suggestion_sent, is_suggestion_sending }: UserState = useSelector(
    (state: AppState) => state.user
  );
  const isModalTypeSuggestion = modalType === ModalType.SUGGESTIONS;

  useEffect(() => {
    if (!!is_suggestion_sent) {
      setIsShareModalOpen(false)
      delay(() => {
        setIsThankYouModalOpen(true);
        setModalType(null);
      }, 390)
    }
  }, [is_suggestion_sent, setIsShareModalOpen, setModalType]);

  return (
    <>
      <CustomModal
        parentClassNames="!item-center"
        modalClassName="w-[95%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[30%]"
        theme="secondary"
        onClose={() => {
          setIsThankYouModalOpen(false);
          dispatch(userSuggestionOrSupportSubmissionResetAction())
        }}
        showCross
        open={isThankYouModalOpen}
      >
        <div className="relative py-28">
          <BrandHeading className="text-center">Thank You!</BrandHeading>
          <div className="text-xs text-center opacity-50 sm:text-sm">
            {"We'll get back to you shortly."}
          </div>
        </div>
      </CustomModal>

      <CustomModal
        theme="secondary"
        modalClassName="w-[95%] sm:w-[85%] md:w-[75%] lg:w-[60%] xl:w-[50%]"
        open={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          delay(() => {
            setModalType(null);
          }, 200);
        }}
        showCross
      >
        <SupportModalFormSection
          setIsShareModalOpen={setIsShareModalOpen}
          setModalType={setModalType}
          isModalTypeSuggestion={isModalTypeSuggestion}
          modalType={modalType}
          asAQuest={asAQuest}
          isShareModalOpen={isShareModalOpen}
          loading={is_suggestion_sending}
        />
      </CustomModal>
    </>
  );
};

export default React.memo(SupportModal);
