import { all, fork } from "redux-saga/effects";
import authSaga from "./auth.saga";
import itemSaga from "./item.saga";
import userSaga from "./user.saga";
import transactionSaga from "./transaction.saga";
import userEngagementSaga from "./user.engagement.saga";
import brandSaga from "./brand.saga";
import engagementSaga from "./engagement.saga";
import analyticsSaga from "./analytics.saga";
import questSaga from "./quest.saga";
import inviteSaga from "./invite.saga";
import exclusiveContentSaga from "./exclusive.content.saga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(itemSaga),
    fork(transactionSaga),
    fork(engagementSaga),
    fork(userEngagementSaga),
    fork(brandSaga),
    fork(analyticsSaga),
    fork(questSaga),
    fork(exclusiveContentSaga),
    fork(inviteSaga),
  ]);
}
