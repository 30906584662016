import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import NextRouteConfig from "../../../../next-routes/NextRouteConfig";
import { BrandContext } from "../../../contexts/BrandContext";
import { AppState } from "../../../redux/reducers";
import { BrandState } from "../../../redux/reducers/brand.reducer";
import { UserState } from "../../../redux/reducers/user.reducer";
import {
  brandSelector,
  userSelector,
} from "../../../redux/selectors/app.selectors";
import Utils from "../../../utils/Utils";
import { BrandButtonColorStyle } from "../../../utils/enums";
import ImageWrapper from "../custom-components/image-wrapper/ImageWrapper";

interface NFTHeaderProps {}

const BrandPageHeader: React.FC<NFTHeaderProps> = (props) => {
  const userState: UserState = useSelector((state: AppState) =>
    userSelector(state)
  );
  const { brand }: BrandState = useSelector((state: AppState) =>
    brandSelector(state)
  );
  const brandValue = React.useContext(BrandContext);
  const isUser = !!userState?.userData;
  const isAdmin = !!userState.adminData;
  const isPublic = !isUser && !isAdmin;

  return (
    <div
      id={"header"}
      style={
        !!brandValue?.brand_banner_url
          ? {
              backgroundImage: `url(${brandValue?.brand_banner_url})`,
            }
          : undefined
      }
      className={`flex bg-no-repeat items-center justify-between nft-header lg:justify-between w-full bg-cover bg-center h-[6.25rem] rounded-xl overflow-hidden ${
        brandValue.brandButtonColorStyle === BrandButtonColorStyle.GRADIENT
          ? "gradient-button"
          : "bg-primaryButton"
      }`}
    >
      <div className="flex items-center w-full h-full px-2 sm:px-4 lg:px-8 bg-gradient-to-t from-black/60 to-transparent md:bg-none">
        <div
          className={classNames(
            "relative flex items-center justify-end w-full md:justify-end",
            {
              "justify-between": isUser,
            }
          )}
        >
          {isUser ? (
            <div className="block border-2 border-white rounded-full shrink-0 md:hidden">
              <ImageWrapper
                height={50}
                width={50}
                className={`transition-all !aspect-square object-cover rounded-full duration-500`}
                url={
                  userState.userData?.profile_pic_url ??
                  "/images/profile.placeholder.png"
                }
              />
            </div>
          ) : null}

          <div className={"flex items-center px-4 text-white md:hidden"}>
            <span className={`text-center`}>
              <h1
                className={classNames(
                  "text-xl font-bold sm:font-semibold sm:text-4xl w-full",
                  {
                    "absolute flex w-full items-center justify-center h-full top-0 z-50 left-0":
                      isPublic,
                  }
                )}
              >
                {isUser
                  ? Utils.numberWithCommas(userState?.userData?.points ?? 0)
                  : isPublic
                  ? brand?.title
                  : null}
              </h1>
              {isUser ? (
                <h1 className="text-xs sm:whitespace-nowrap">
                  Current {brand?.currency ?? "Points"} Balance
                </h1>
              ) : null}
            </span>
          </div>

          <Link
            href={
              userState.adminData
                ? NextRouteConfig.ADMIN.CAMPAIGNS._root
                : NextRouteConfig.USER.HOME._root
            }
            passHref
          >
            <div className="flex items-center py-1 text-4xl cursor-pointer shrink-0 w-max xl:text-4xl">
              <Image
                height={90}
                width={90}
                className="object-scale-down !aspect-square rounded-md"
                src={brandValue?.brand_logo_url}
                alt="Brand Logo"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BrandPageHeader;
