import { isUndefined } from "lodash";
import { toast, ToastContent, ToastOptions } from "react-toastify";

export class WebToastService {
  private static _instance: WebToastService;

  private constructor() {}

  static getInstance(): WebToastService {
    if (!this._instance) {
      this._instance = new WebToastService();
    }

    return this._instance;
  }

  showError(content: ToastContent, config?: ToastOptions) {
    // toast.dismiss();
    toast(content, {
      ...config,
      type: "error",
      position: config?.position || "top-center",
      autoClose: isUndefined(config?.autoClose) ? 3000 : config?.autoClose,
    });
  }

  showInfo(content: ToastContent, config?: ToastOptions) {
    // toast.dismiss();
    toast(content, {
      ...config,
      type: "info",
      position: config?.position || "top-center",
      autoClose: isUndefined(config?.autoClose) ? 3000 : config?.autoClose,
    });
  }

  showSuccess(content: ToastContent, config?: ToastOptions) {
    // toast.dismiss();
    toast(content, {
      ...config,
      type: "success",
      position: config?.position || "top-center",
      autoClose: isUndefined(config?.autoClose) ? 3000 : config?.autoClose,
    });
  }

  showWarning(content: ToastContent, config?: ToastOptions) {
    // toast.dismiss();
    toast(content, {
      ...config,
      type: "warning",
      position: config?.position || "top-center",
      autoClose: isUndefined(config?.autoClose) ? 3000 : config?.autoClose,
    });
  }

  dismiss(toastRef: any) {
    toast.dismiss(toastRef);
  }
}

export const webToastService = WebToastService.getInstance();
