import React from "react";
import { PlacesType, Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { BrandContext } from "../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../utils/enums";

interface TooltipProps {
  id: string;
  place?: PlacesType;
  tooltip: React.ReactNode;
  tooltipClassnames?: string;
}
const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const { place, tooltip, id } = props;
  const brandValue = React.useContext(BrandContext);
  return (
    <Tooltip
      content={tooltip as any}
      place={place ?? "right"}
      className={
        `absolute z-50 max-w-xs whitespace-pre-line shadow-primaryCardShadow text-textButton ${
          brandValue.brandButtonColorStyle === BrandButtonColorStyle.GRADIENT
            ? "gradient-button"
            : "bg-primaryButton"
        } text-xs font-normal tracking-wider ` + props.tooltipClassnames
      }
      anchorId={id}
    />
  );
};

export default React.memo(CustomTooltip);
