import { Invite } from "../../models/entities/Invite";
import {
  GET_INVITE_DETAILS,
  GET_INVITE_DETAILS_COMPLETED,
  GET_INVITE_DETAILS_ERROR,
  RESET_APP_STATE,
} from "../actions/actions.constants";

export interface InviteState {
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  invite: Invite | null;
}

const initialState: InviteState = {
  loading: false,
  loaded: true,
  error: "",
  invite: null,
};

export const inviteStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case GET_INVITE_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    }

    case GET_INVITE_DETAILS_COMPLETED: {
      const invite = action.payload.response;
      return {
        ...state,
        loading: false,
        loaded: true,
        invite: { ...invite },
      };
    }
    case GET_INVITE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    default:
      return state;
  }
};
