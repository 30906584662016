import Quest from "../../models/entities/Quest";
import UserQuest from "../../models/entities/user-quest/UserQuest";
import { SortBy, UserQuestStatus } from "../../utils/enums";
import {
  APPROVE_OR_REJECT_ALL_USER_QUEST,
  APPROVE_OR_REJECT_ALL_USER_QUEST_COMPLETED,
  APPROVE_OR_REJECT_ALL_USER_QUEST_ERROR,
  CLAIMED_USER_QUEST_AMOUNT_RESET,
  CLAIM_USER_QUEST,
  CLAIM_USER_QUEST_COMPLETED,
  CLAIM_USER_QUEST_ERROR,
  IS_QUEST_APPROVAL_PENDING_FOR_SELECTED_QUEST,
  LIST_ALL_USER_QUEST,
  LIST_ALL_USER_QUEST_COMPLETED,
  LIST_ALL_USER_QUEST_ERROR,
  LIST_ALL_USER_QUEST_RESET,
  MY_QUESTS_ACTIVITY_LIST_GET,
  MY_QUESTS_ACTIVITY_LIST_GET_COMPLETED,
  MY_QUESTS_ACTIVITY_LIST_GET_ERROR,
  MY_QUESTS_ACTIVITY_LIST_RESET,
  UPDATE_USER_QUEST_STATUS,
  UPDATE_USER_QUEST_STATUS_COMPLETED,
  UPDATE_USER_QUEST_STATUS_ERROR,
  USER_QUESTS_LIST_GET,
  USER_QUESTS_LIST_GET_COMPLETED,
  USER_QUESTS_LIST_GET_ERROR,
  USER_QUEST_CLAIM_ERROR_RESET,
  USER_QUIZ_QUEST_CLAIM_SAFETY_CHECK_LINK_ERROR_RESET,
  USER_QUIZ_QUEST_TRY_AGAIN_RESET,
} from "./actions.constants";

export const userQuestListGetAction = (payload?: {
  req: {
    // page: number;
    query: {
      filter?: string[];
      sort_by?: string;
      search?: string | null;
      category?: string[];
    };
  };
}) => ({
  type: USER_QUESTS_LIST_GET,
  payload,
});

export const userQuestsListGetCompletedAction = (payload?: {
  res: {
    quests: Quest[];
    meta: any;
  };
}) => ({
  type: USER_QUESTS_LIST_GET_COMPLETED,
  payload,
});
export const userQuestsListGetErrorAction = (payload: { message: string }) => ({
  type: USER_QUESTS_LIST_GET_ERROR,
  payload,
});

export const listAllUserQuestsGetAction = (payload?: {
  req: {
    page: number;
    quest_id?: number;
    query: {
      filter?: string[];
      search?: string | null;
      category?: string[];
    };
  };
}) => ({
  type: LIST_ALL_USER_QUEST,
  payload,
});

export const listAllUserQuestsCompletedAction = (payload?: {
  res: {
    quests: Quest[];
    meta: any;
  };
}) => ({
  type: LIST_ALL_USER_QUEST_COMPLETED,
  payload,
});
export const listAllUserQuestsGetErrorAction = (payload: {
  message: string;
}) => ({
  type: LIST_ALL_USER_QUEST_ERROR,
  payload,
});

export const listAllUserQuestsGetResetAction = () => ({
  type: LIST_ALL_USER_QUEST_RESET,
});

export const updateUserQuestStatusAction = (payload: {
  req: {
    userQuestId: number;
    status: UserQuestStatus;
    questId?: number;
    comment?: string;
    query?: {
      page?: number;
      filter?: string[];
      search?: string | null;
      category?: string[];
    };
  };
}) => ({
  type: UPDATE_USER_QUEST_STATUS,
  payload,
});
export const updateUserQuestStatusCompletedAction = (payload: {
  res: { updatedQuest: UserQuest }; // The updated user quest data
}) => ({
  type: UPDATE_USER_QUEST_STATUS_COMPLETED,
  payload,
});

export const updateUserQuestStatusErrorAction = (payload: {
  message: string;
}) => ({
  type: UPDATE_USER_QUEST_STATUS_ERROR,
  payload,
});

export const myQuestsActivityListGetAction = (payload: {
  page: number;
  req: {
    filters?: string[];
    sort_by?: SortBy;
  };
}) => ({
  type: MY_QUESTS_ACTIVITY_LIST_GET,
  payload,
});

export const myQuestsActivityListGetCompletedAction = (payload: {
  res: {
    user_quests: UserQuest[];
    meta: any;
  };
}) => ({
  type: MY_QUESTS_ACTIVITY_LIST_GET_COMPLETED,
  payload,
});
export const myQuestsActivityListGetErrorAction = (payload: {
  message: string;
}) => ({
  type: MY_QUESTS_ACTIVITY_LIST_GET_ERROR,
  payload,
});

export const myQuestsActivityListResetAction = () => ({
  type: MY_QUESTS_ACTIVITY_LIST_RESET,
});

export const claimUserQuestAction = <T>(payload: {
  quest_id: number;
  type?: string;
  req: T;
  mediaObjectUrls?: any;
}) => ({
  type: CLAIM_USER_QUEST,
  payload,
});
export const claimUserQuestCompletedAction = (payload: {
  res: { quest: UserQuest };
}) => ({
  type: CLAIM_USER_QUEST_COMPLETED,
  payload,
});
export const claimUserQuestErrorAction = (payload: {
  message: string;
  isTryAgainQuest?: boolean;
  isSafetyCheckLinkError?: boolean;
}) => ({
  type: CLAIM_USER_QUEST_ERROR,
  payload,
});
export const claimUserQuestAmountResetAction = () => ({
  type: CLAIMED_USER_QUEST_AMOUNT_RESET,
});

export const userQuizQuestTryAgainResetAction = () => ({
  type: USER_QUIZ_QUEST_TRY_AGAIN_RESET,
});

export const userQuestClaimErrorResetAction = (payload?: {
  resetLinkSafetyError?: boolean;
}) => ({
  type: USER_QUEST_CLAIM_ERROR_RESET,
});

export const userQuestClaimSafetyCheckLinkErrorResetAction = (payload: {
  req: {
    id: any;
  };
}) => ({
  type: USER_QUIZ_QUEST_CLAIM_SAFETY_CHECK_LINK_ERROR_RESET,
  payload,
});

export const approveOrRejectAllUserQuestAction = (payload: {
  req: {
    quest_id?: number;
    status: string;
    comment?: string;
    query: {
      search?: string | null;
      category?: string[];
    };
  };
}) => ({
  type: APPROVE_OR_REJECT_ALL_USER_QUEST,
  payload,
});

export const approveOrRejectAllUserQuestCompletedAction = (payload: {
  res: {
    user_quests: UserQuest[];
  };
}) => ({
  type: APPROVE_OR_REJECT_ALL_USER_QUEST_COMPLETED,
  payload,
});
export const approveOrRejectAllUserQuestErrorAction = (payload: {
  message: string;
}) => ({
  type: APPROVE_OR_REJECT_ALL_USER_QUEST_ERROR,
  payload,
});

export const isQuestApprovalForSelectedQuestPendingAction = (payload: {
  res: {
    is_pending: boolean;
  };
}) => ({
  type: IS_QUEST_APPROVAL_PENDING_FOR_SELECTED_QUEST,
  payload,
});
