import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { Check, X } from "phosphor-react";
import React from "react";
import { BrandContext } from "../../../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../../../utils/enums";

interface ToggleProps {
  enabled: boolean;
  setEnabled: (v: boolean) => void;
  disabled?: boolean;
  label?: React.ReactNode;
  labelClassName?: string;
  name?: string;
  switchClassName?: string;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  const { enabled, setEnabled, disabled, labelClassName, name } = props;

  const brandValue = React.useContext(BrandContext);

  return (
    <>
      <Switch.Group
        as="div"
        className={"flex items-center space-x-4 " + props.switchClassName}
      >
        {props.label && (
          <Switch.Label
            passive
            className={props.labelClassName + " text-sm sm:text-base"}
          >
            {props.label}
          </Switch.Label>
        )}
        <Switch
          name={name}
          style={{
            border: enabled && brandValue?.primary_color,
          }}
          checked={enabled}
          disabled={disabled}
          onChange={setEnabled}
          className={classNames(
            disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
            "relative inline-flex items-center border-[2px] flex-shrink-0 h-6 w-10 rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primaryText focus-visible:ring-opacity-75",
            enabled
              ? brandValue.brandButtonColorStyle ===
                BrandButtonColorStyle.GRADIENT
                ? "gradient-button"
                : "bg-primaryButton"
              : "bg-primaryModal border-brandGray"
          )}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled ? "translate-x-4" : "translate-x-0 bg-brandGray"
            } pointer-events-none font-bold text-sm flex ml-0.5 justify-center items-center h-4 w-4 rounded-full bg-primaryText !text-primaryBackground shadow-lg transform ring-0 transition delay-75 ease-in-out duration-200`}
          >
            {enabled ? (
              <Check className="w-3 h-3 text-primaryBackground" weight="bold" />
            ) : (
              <X className="w-3 h-3 text-primaryText" weight="bold" />
            )}
          </span>
        </Switch>
      </Switch.Group>
    </>
  );
};

export default Toggle;
