import { Invite } from "../../models/entities/Invite";
import {
  GET_INVITE_DETAILS,
  GET_INVITE_DETAILS_COMPLETED,
  GET_INVITE_DETAILS_ERROR,
} from "./actions.constants";

export const getInviteAction = (payload: { invite_id: string }) => ({
  type: GET_INVITE_DETAILS,
  payload,
});

export const getInviteCompletedAction = (payload: { response: Invite }) => ({
  type: GET_INVITE_DETAILS_COMPLETED,
  payload,
});

export const getInviteErrorAction = (payload: { message: string }) => ({
  type: GET_INVITE_DETAILS_ERROR,
  payload,
});
