import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { Info } from "phosphor-react";
import React from "react";
import BrandButton from "../custom-components/BrandButton";
import CustomModal from "./CustomModal";

interface ModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  confirmButtonText: string;
  confirmButtonOnClick: Function;
  confirmSubmitting?: boolean;
  modalHeaderText: string | React.ReactNode;
  modalDescriptionText?: React.ReactNode;
  cancelButtonOnClick?: Function;
  disableConfirm?: boolean;
  extra?: React.ReactNode;
  customConfirmation?: boolean;
  cancelButtonText?: string;
  cancelButton?: boolean;
  cancelSubmitting?: boolean;
  className?: string;
  overlayClassName?: string;
  modalClassName?: string
  showInfoIcon?: boolean;
}

const ConfirmDialog: React.FC<ModalProps> = ({
  cancelButtonText,
  showModal,
  setShowModal,
  confirmButtonText,
  confirmButtonOnClick,
  modalHeaderText,
  modalDescriptionText,
  extra,
  disableConfirm,
  cancelButtonOnClick,
  customConfirmation,
  cancelButton = true,
  className,
  overlayClassName,
  confirmSubmitting,
  cancelSubmitting,
  modalClassName,
  showInfoIcon = true
}) => {
  return (
    <CustomModal
      onClose={() => {
        cancelButtonOnClick ?? setShowModal(false);
      }}
      modalClassName={modalClassName}
      open={showModal}
      className={className}
      overlayClassNames={overlayClassName}
    >
      <div className="flex flex-col items-start w-full py-4 space-y-3 sm:space-y-5 sm:py-8">
        <div className="w-full gap-4 sm:flex sm:items-start">
          {showInfoIcon && <div className="flex items-center justify-center flex-shrink-0">
            <Info className="w-6 h-6" />
          </div>}
          <div className="w-full text-center sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-semibold leading-6">
              {modalHeaderText}
            </Dialog.Title>
            <div className="mt-2">
              {modalDescriptionText && (
                <p className="text-sm">{modalDescriptionText}</p>
              )}
              {extra && extra}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "flex flex-col space-y-2 md:flex-row md:items-center w-full md:justify-end md:space-x-4 md:space-y-0 px-3 md:px-1",
            {
              "md:justify-center": !cancelButton,
            }
          )}
        >
          <BrandButton
            disabled={disableConfirm}
            type="button"
            onClick={() => {
              if (!disableConfirm) {
                if (!customConfirmation) {
                  confirmButtonOnClick();
                  setShowModal(false);
                } else {
                  confirmButtonOnClick();
                }
              }
            }}
            isSubmitting={confirmSubmitting}
            className="text-base"
          >
            {confirmButtonText}
          </BrandButton>
          {cancelButton ? (
            <BrandButton
              type="button"
              theme="secondary"
              onClick={() => {
                cancelButtonOnClick
                  ? cancelButtonOnClick()
                  : setShowModal(false);
              }}
              isSubmitting={cancelSubmitting}
              className="text-base"
            >
              {cancelButtonText ?? "Cancel"}
            </BrandButton>
          ) : null}
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDialog;
