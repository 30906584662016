import classNames from "classnames";
import React from "react";

interface BrandHeadingProps {
  className?: string;
  children?: React.ReactNode;
}

const BrandHeading: React.FC<BrandHeadingProps> = ({ className, children }) => {
  return (
    <h1
      className={classNames(
        "text-2xl md:text-3xl font-primary font-weight_primary tracking-tracking_primary md:pb-4 pb-2",
        className
      )}
    >
      {children}
    </h1>
  );
};

export default React.memo(BrandHeading);
