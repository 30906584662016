import classNames from "classnames";
import React, { FunctionComponent } from "react";

export interface Props {
  htmlFor?: string;
  // Pass a div with Tooltip in label, instead of a separate prop
  label: React.ReactNode;
  className?: string;
}

const InputLabel: FunctionComponent<Props> = ({
  label,
  htmlFor,
  className,
}) => (
  <label
    htmlFor={htmlFor}
    className={classNames("block text-sm leading-none", className)}
  >
    {label}
  </label>
);

InputLabel.defaultProps = {};

export default React.memo(InputLabel);
