import React, { useContext } from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../../utils/enums";

interface ProgressBarProps {
  percentage: number;
  count?: number | string;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const brandValue = useContext(BrandContext);
  return (
    <div
      className={`relative w-full h-4 overflow-hidden rounded-full bg-primaryText/10 backdrop-blur border border-primaryText/10`}
    >
      <div
        className={`relative h-full transition-all duration-1000 rounded-full drop-shadow-socialConnect ${
          brandValue.brandButtonColorStyle === BrandButtonColorStyle.GRADIENT
            ? `gradient-button`
            : `bg-primaryButton`
        }`}
        style={{
          width: `${props.percentage}%`,
        }}
      >
        {props.count ? (
          <span className="absolute right-[0.8rem] m-auto py-1 !text-textButton inset-y-0 flex items-center font-extrabold text-xs">
            {props.count}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
