import { Engagement } from "../../models/entities/Engagement";
import { UserEngagement } from "../../models/entities/UserEngagement";
import { SortBy } from "../../utils/enums";
import {
  BRAND_LATEST_TWEET_LIST_GET,
  BRAND_LATEST_TWEET_LIST_GET_COMPLETED,
  BRAND_LATEST_TWEET_LIST_GET_ERROR,
  ENGAGEMENT_CREATE,
  ENGAGEMENT_CREATE_COMPLETED,
  ENGAGEMENT_CREATE_ERROR,
  ENGAGEMENT_LIST_GET,
  ENGAGEMENT_LIST_GET_COMPLETED,
  ENGAGEMENT_LIST_GET_ERROR,
  ENGAGEMENT_TOGGLE_ARCHIVE,
  ENGAGEMENT_TOGGLE_ARCHIVE_COMPLETED,
  ENGAGEMENT_TOGGLE_ARCHIVE_ERROR,
  ENGAGEMENT_UPDATE,
  ENGAGEMENT_UPDATE_COMPLETED,
  MY_ACTIVITY_LIST_GET,
  MY_ACTIVITY_LIST_GET_COMPLETED,
  MY_ACTIVITY_LIST_GET_ERROR,
  MY_ACTIVITY_LIST_RESET,
  RESET_APP_STATE,
  USER_ENGAGEMENTS_AMOUNT_RESET,
  USER_ENGAGEMENTS_CLAIM,
  USER_ENGAGEMENTS_CLAIM_COMPLETED,
  USER_ENGAGEMENTS_CLAIM_ERROR,
  USER_ENGAGEMENT_LIST_GET,
  USER_ENGAGEMENT_LIST_GET_COMPLETED,
  USER_ENGAGEMENT_LIST_GET_ERROR,
} from "../actions/actions.constants";

export interface userEngagementStatsState {
  entities: {
    [provider: string]: {
      entities: Record<number, Engagement>;
      verifying: boolean;
      amount: any;
      entityIds: number[];
      try_again: boolean;
    };
  };
  userEngagementEntities: {
    [provider: string]: {
      entities: Record<number, UserEngagement>;
      entityIds: number[];
      filters?: { sortBy?: SortBy; filterBy?: string[] };
    };
  };
  loading: boolean;
  loaded: boolean;
  error: string;
}

const initialState: userEngagementStatsState = {
  entities: {
    quest: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    twitter: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    instagram: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    discord: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    youtube: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    gmail: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
    tiktok: {
      entities: {},
      verifying: false,
      amount: null,
      try_again: false,
      entityIds: [],
    },
  },
  userEngagementEntities: {
    discord: { entities: {}, entityIds: [], filters: {} },
    gmail: { entities: {}, entityIds: [], filters: {} },
    instagram: { entities: {}, entityIds: [], filters: {} },
    quest: { entities: {}, entityIds: [], filters: {} },
    tiktok: { entities: {}, entityIds: [], filters: {} },
    twitter: { entities: {}, entityIds: [], filters: {} },
    youtube: { entities: {}, entityIds: [], filters: {} },
  },
  loading: false,
  loaded: true,
  error: "",
};

export const userEngagementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case ENGAGEMENT_LIST_GET:
    case MY_ACTIVITY_LIST_GET: {
      const filters = action.payload?.req?.filters;
      const sortBy = action.payload?.req?.sort_by;
      const provider = action.payload?.req?.provider;
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
        userEngagementEntities: {
          ...state.userEngagementEntities,
          [provider]: {
            ...state.userEngagementEntities[
              provider as keyof typeof state.userEngagementEntities
            ],
            filters: {
              sortBy,
              filterBy: filters ?? [],
            },
          },
        },
      };
    }
    case USER_ENGAGEMENT_LIST_GET:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case MY_ACTIVITY_LIST_GET_ERROR:
    case ENGAGEMENT_LIST_GET_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case MY_ACTIVITY_LIST_RESET: {
      const provider = action.payload?.req?.provider;
      return {
        ...state,
        userEngagementEntities: {
          ...state.userEngagementEntities,
          [provider]: {
            ...state.userEngagementEntities[
              provider as keyof typeof state.userEngagementEntities
            ],
            entityIds: [],
          },
        },
      };
    }
    case MY_ACTIVITY_LIST_GET_COMPLETED: {
      const userEngagements: UserEngagement[] =
        action.payload.res.userEngagements;
      const provider = action.payload.provider;

      const userEngagementsEntityIds = new Set(
        state.userEngagementEntities[
          provider as keyof typeof state.userEngagementEntities
        ].entityIds
      );
      userEngagements.forEach((userEng) => {
        userEngagementsEntityIds.add(userEng?.id);
      });

      const sanitizeUserEngagements = userEngagements.reduce(
        (commutator: any, value: UserEngagement) => {
          return {
            ...commutator,
            [value.id]: { ...value },
          };
        },
        {}
      );
      return {
        ...state,
        userEngagementEntities: {
          ...state.userEngagementEntities,
          [provider]: {
            ...state.userEngagementEntities[
              provider as keyof typeof state.userEngagementEntities
            ],
            entities: { ...sanitizeUserEngagements },
            entityIds: Array.from(userEngagementsEntityIds),
          },
        },
        loading: false,
        loaded: true,
      };
    }

    case ENGAGEMENT_LIST_GET_COMPLETED: {
      const engagementList: {
        [provider: string]: Engagement[];
      } = action?.payload?.res?.engagement;
      const sanitizeEntities = (
        (Object.keys(state.entities) as string[]) ?? []
      ).reduce((cummulator: any, value: any) => {
        return {
          ...cummulator,
          [value]: {
            entities: (
              engagementList[value as keyof typeof engagementList] ?? []
            ).reduce((cummulator: any, value: Engagement) => {
              return {
                ...cummulator,
                [value.id]: { ...value },
              };
            }, {}),
            entityIds: (
              engagementList[value as keyof typeof engagementList] ?? []
            ).reduce((cummulator: any, value: Engagement) => {
              return [...cummulator, value.id];
            }, []),
            verifying: false,
            amount: null,
            try_again: false,
          },
        };
      }, {});

      return {
        ...state,
        entities: {
          ...sanitizeEntities,
        },
        loading: false,
        loaded: true,
      };
    }

    case USER_ENGAGEMENT_LIST_GET_COMPLETED: {
      const engagementList: {
        [provider: string]: UserEngagement[];
      } = action?.payload?.res?.engagement;

      const sanitizeEntities = (
        (Object.keys(state.userEngagementEntities) as string[]) ?? []
      ).reduce((cummulator: any, value: any) => {
        return {
          ...cummulator,
          [value]: {
            entities: (
              engagementList[value as keyof typeof engagementList] ?? []
            ).reduce((cummulator: any, value: UserEngagement) => {
              return {
                ...cummulator,
                [value.id]: { ...value },
              };
            }, {}),
            entityIds: (
              engagementList[value as keyof typeof engagementList] ?? []
            ).reduce((cummulator: any, value: UserEngagement) => {
              return [...cummulator, value.id];
            }, []),
            verifying: false,
            amount: null,
            try_again: false,
          },
        };
      }, {});

      return {
        ...state,
        entities: {
          ...sanitizeEntities,
        },
        loading: false,
        loaded: true,
      };
    }

    case USER_ENGAGEMENT_LIST_GET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case BRAND_LATEST_TWEET_LIST_GET: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    }
    case BRAND_LATEST_TWEET_LIST_GET_COMPLETED: {
      const tweetEngagements: Engagement[] = action.payload.res.tweets;
      const sanitizeTweetEngagements = tweetEngagements.reduce(
        (cummulator: any, tweetEng: Engagement) => {
          return {
            ...cummulator,
            [tweetEng.id]: { ...tweetEng },
          };
        },
        {}
      );
      const twitterEngagementsEntityIds = tweetEngagements.map((eng) => eng.id);
      return {
        ...state,
        entities: {
          ...state.entities,
          twitter: {
            ...state.entities.twitter,
            entities: {
              ...state.entities.twitter.entities,
              ...sanitizeTweetEngagements,
            },
            entityIds: [
              ...state.entities.twitter.entityIds,
              ...twitterEngagementsEntityIds,
            ],
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_LATEST_TWEET_LIST_GET_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case USER_ENGAGEMENTS_CLAIM:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.req.provider]: {
            ...state.entities[
              action.payload?.req?.provider as keyof typeof state.entities
            ],
            verifying: true,
          },
        },
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_ENGAGEMENTS_CLAIM_COMPLETED: {
      const amount = action.payload.res?.amount;
      const provider = action.payload.res?.provider;
      let entities = {};
      let entityIds: number[] = [];
      (action.payload.res.engagements as Engagement[]).forEach((eng) => {
        entities = { ...entities, [eng.id]: { ...eng } };
        entityIds = [...entityIds, eng.id];
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          [provider]: {
            ...state.entities[provider as keyof typeof state.entities],
            entities,
            entityIds,
            verifying: false,
            amount: +amount,
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case USER_ENGAGEMENTS_AMOUNT_RESET:
      const providers = Object.keys(state.entities);
      const newEntites = providers.reduce((cummulator: any, value: string) => {
        const entity = state.entities[value as keyof typeof state.entities];
        return {
          ...cummulator,
          [value]: {
            ...entity,
            amount: null,
            try_again: false,
          },
        };
      }, {});
      return {
        ...state,
        entities: { ...newEntites },
        loading: false,
        loaded: true,
      };
    case USER_ENGAGEMENTS_CLAIM_ERROR:
      const error: string = action.payload.message;
      const provider = action.payload.provider;
      return {
        ...state,
        entities: {
          ...state.entities,
          [provider]: {
            ...state.entities[provider as keyof typeof state.entities],
            verifying: false,
            try_again: error.includes(`429`) && true,
          },
        },
        loading: false,
        loaded: true,
        error: error,
      };
    case ENGAGEMENT_CREATE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    }
    case ENGAGEMENT_CREATE_COMPLETED: {
      const provider = action.payload.res.engagement[0]?.provider;
      const sanitizeEntities = (
        action.payload.res.engagement as Engagement[]
      ).reduce((cummulator: any, eng: Engagement) => {
        return {
          ...cummulator,
          [eng.id]: { ...eng },
        };
      }, {});
      const sanitizeEntityIds = (
        action.payload.res.engagement as Engagement[]
      ).map((eng) => eng.id);

      return {
        ...state,
        entities: {
          ...state.entities,
          [provider]: {
            ...state.entities[provider as keyof typeof state.entities],
            entities: {
              ...state.entities[provider as keyof typeof state.entities]
                ?.entities,
              ...sanitizeEntities,
            },
            entityIds: [
              ...sanitizeEntityIds,
              ...state.entities[provider as keyof typeof state.entities]
                ?.entityIds,
            ],
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case ENGAGEMENT_CREATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };

    case ENGAGEMENT_TOGGLE_ARCHIVE:
      const engagementId = action.payload?.req?.id;
      const engagementProvider = action.payload.req?.provider;
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };

    case ENGAGEMENT_TOGGLE_ARCHIVE_COMPLETED: {
      const eng: Engagement[] = action.payload.res.engagements;
      const provider = eng[0].provider;
      const updatedEngagements = eng.reduce((accumulator: any, engagement) => {
        return {
          ...accumulator,
          [engagement.id]: {
            ...(
              state.entities[provider as keyof typeof state.entities] as Record<
                number,
                Engagement
              >
            )[engagement.id],
            ...engagement,
          },
        };
      }, []);
      return {
        ...state,
        entities: {
          ...state.entities,
          [provider]: {
            ...state.entities[provider as keyof typeof state.entities],
            entities: {
              ...state.entities[provider as keyof typeof state.entities]
                .entities,
              ...updatedEngagements,
            },
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case ENGAGEMENT_TOGGLE_ARCHIVE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    case ENGAGEMENT_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case ENGAGEMENT_UPDATE_COMPLETED:
      const updatedEngagement: Engagement = action.payload.res.engagement;
      const updatedEntityIds = (
        state.entities[
          updatedEngagement.provider as keyof typeof state.entities
        ]?.entityIds as number[]
      ).filter((id) => id !== updatedEngagement?.id);
      return {
        ...state,
        entities: {
          ...state.entities,
          [updatedEngagement.provider]: {
            ...state.entities[
              updatedEngagement.provider as keyof typeof state.entities
            ],
            entityIds: [updatedEngagement?.id, ...updatedEntityIds],
            entities: {
              ...state.entities[
                updatedEngagement.provider as keyof typeof state.entities
              ].entities,
              [updatedEngagement.id]: {
                ...(
                  state.entities[
                    updatedEngagement.provider as keyof typeof state.entities
                  ] as Record<number, Engagement>
                )[updatedEngagement.id],
                ...updatedEngagement,
              },
            },
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    default:
      return state;
  }
};
