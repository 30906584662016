import {
  ADMIN_ANALYTICS_STATS_GET,
  ADMIN_ANALYTICS_STATS_GET_COMPLETED,
  ADMIN_ANALYTICS_STATS_GET_ERROR,
  RESET_APP_STATE,
  SAFETY_CHECK_LINK,
  SAFETY_CHECK_LINK_COMPETED as SAFETY_CHECK_LINK_COMPLETED,
  SAFETY_CHECK_LINK_ERROR,
} from "../actions/actions.constants";

export interface AnalyticsState {
  stats: any;
  loading: boolean;
  loaded: boolean;
  error: string;
  safety_check: {
    [link: string]: {
      status: string;
    };
  };
}

const initialState: AnalyticsState = {
  stats: {},
  loading: false,
  loaded: false,
  error: "",
  safety_check: {},
};
export const analyticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case ADMIN_ANALYTICS_STATS_GET:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case ADMIN_ANALYTICS_STATS_GET_COMPLETED:
      const stats: any = action.payload.res.stats;
      return {
        ...state,
        stats: { ...stats },
        loading: false,
        loaded: true,
        error: "",
      };
    case ADMIN_ANALYTICS_STATS_GET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case SAFETY_CHECK_LINK: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAFETY_CHECK_LINK_COMPLETED: {
      const link = action.payload?.res.link;
      const status = action.payload?.res.status;
      return {
        ...state,
        safety_check: {
          ...state.safety_check,
          [link]: {
            ...state.safety_check[link],
            status,
          },
        },
        loading: false,
        loaded: true,
      };
    }
    case SAFETY_CHECK_LINK_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};
