import { Engagement } from "../../models/entities/Engagement";
import { UserEngagement } from "../../models/entities/UserEngagement";
import { SortBy } from "../../utils/enums";
import {
  ENGAGEMENT_CREATE,
  ENGAGEMENT_CREATE_COMPLETED,
  ENGAGEMENT_CREATE_ERROR,
  ENGAGEMENT_LIST_GET,
  ENGAGEMENT_LIST_GET_COMPLETED,
  ENGAGEMENT_LIST_GET_ERROR,
  ENGAGEMENT_UPDATE,
  ENGAGEMENT_UPDATE_COMPLETED,
  ENGAGEMENT_UPDATE_ERROR,
  USER_ENGAGEMENT_LIST_GET,
  USER_ENGAGEMENT_LIST_GET_COMPLETED,
  USER_ENGAGEMENT_LIST_GET_ERROR,
  USER_ENGAGEMENTS_AMOUNT_RESET,
  USER_ENGAGEMENTS_CLAIM,
  USER_ENGAGEMENTS_CLAIM_COMPLETED,
  USER_ENGAGEMENTS_CLAIM_ERROR,
  ENGAGEMENT_TOGGLE_ARCHIVE,
  ENGAGEMENT_TOGGLE_ARCHIVE_COMPLETED,
  ENGAGEMENT_TOGGLE_ARCHIVE_ERROR,
  MY_ACTIVITY_LIST_GET,
  MY_ACTIVITY_LIST_GET_COMPLETED,
  MY_ACTIVITY_LIST_GET_ERROR,
  MY_ACTIVITY_LIST_RESET,
  USER_FAKE_TWITTER_CLAIM,
  USER_FAKE_TWITTER_CLAIM_COMPLETED,
  USER_FAKE_TWITTER_CLAIM_ERROR,
} from "./actions.constants";

export const userEngagementListGetAction = () => ({
  type: USER_ENGAGEMENT_LIST_GET,
});

export const userEngagementListGetCompletedAction = (payload: {
  res: {
    engagement: {
      twitter: Engagement[];
      discord: Engagement[];
      instagram: Engagement[];
      other: Engagement[];
    };
    user_engagements: Engagement[];
  };
}) => ({
  type: USER_ENGAGEMENT_LIST_GET_COMPLETED,
  payload,
});

export const userEngagementListGetError = (payload: { message: string }) => ({
  type: USER_ENGAGEMENT_LIST_GET_ERROR,
  payload,
});

export const userEngagementsClaimAction = (payload: {
  req: { provider: string; provider_id?: string; isWithoutApi?: boolean };
}) => ({
  type: USER_ENGAGEMENTS_CLAIM,
  payload,
});

export const userEngagementsClaimCompletedAction = (payload: {
  res: {
    amount: string;
    provider: string;
    engagements: Engagement[];
  };
}) => ({
  type: USER_ENGAGEMENTS_CLAIM_COMPLETED,
  payload,
});

export const userEngagementClaimErroAction = (payload: {
  message: any;
  provider: string;
}) => ({
  type: USER_ENGAGEMENTS_CLAIM_ERROR,
  payload,
});

export const userEngagementsAmountResetAction = () => ({
  type: USER_ENGAGEMENTS_AMOUNT_RESET,
});

export const engagementListGetAction = (payload?: {
  req: {
    filter?: string[];
    sort_by?: string;
    search?: string | null;
  };
}) => ({
  type: ENGAGEMENT_LIST_GET,
  payload,
});

export const engagementListGetCompletedAction = (payload: {
  res: {
    engagement: {
      twitter: Engagement[];
      discord: Engagement[];
      instagram: Engagement[];
      youtube: Engagement[];
      tiktok: Engagement[];
      gmail: Engagement[];
      other: Engagement[];
    };
  };
}) => ({
  type: ENGAGEMENT_LIST_GET_COMPLETED,
  payload,
});

export const engagementListGetError = (payload: { message: string }) => ({
  type: ENGAGEMENT_LIST_GET_ERROR,
  payload,
});

export const engagementCreateAction = (payload: {
  req: {
    isWithoutApi?: boolean;
    provider: string;
    type: any;
    username?: string;
    total: number | null;
    provider_id?: string;
    invite_url?: string;
    video_url?: string;
    post_url?: string;
    per_wallet: {
      follow?: number;
      like?: number;
      retweet?: number;
      reply?: number;
      subscribe?: number;
      watch?: number;
      comment?: number;
      join?: number;
      share?: number;
      post?: number;
    };
  };
}) => ({
  type: ENGAGEMENT_CREATE,
  payload,
});

export const engagementCreateCompleteAction = (payload: {
  res: { engagement: Engagement };
}) => ({
  type: ENGAGEMENT_CREATE_COMPLETED,
  payload,
});

export const engagementCreateErrorAction = (payload: { message: string }) => ({
  type: ENGAGEMENT_CREATE_ERROR,
  payload,
});

export const engagementUpdateAction = (payload: {
  req: {
    id: number;
    per_wallet: number;
    total: number | null;
    invite_url?: string;
  };
}) => ({
  type: ENGAGEMENT_UPDATE,
  payload,
});

export const engagementUpdateCompletedAction = (payload: {
  res: { engagement: Engagement };
}) => ({
  type: ENGAGEMENT_UPDATE_COMPLETED,
  payload,
});

export const engagementUpdateErrorAction = (payload: { message: string }) => ({
  type: ENGAGEMENT_UPDATE_ERROR,
  payload,
});

export const engagementToggleAction = (payload: { req: { id: number } }) => ({
  type: ENGAGEMENT_TOGGLE_ARCHIVE,
  payload,
});
export const engagementToggleCompletedAction = (payload: {
  res: { engagements: Engagement[] };
}) => ({
  type: ENGAGEMENT_TOGGLE_ARCHIVE_COMPLETED,
  payload,
});

export const engagementToggleErrorAction = (payload: { message: string }) => ({
  type: ENGAGEMENT_TOGGLE_ARCHIVE_ERROR,
  payload,
});

export const myActivityListGetAction = (payload: {
  req: {
    provider: string;
    filters?: string[];
    sort_by?: SortBy;
  };
}) => ({
  type: MY_ACTIVITY_LIST_GET,
  payload,
});
export const myActivityListGetCompletedAction = (payload: {
  provider: string;
  res: {
    userEngagements: UserEngagement[];
  };
}) => ({
  type: MY_ACTIVITY_LIST_GET_COMPLETED,
  payload,
});
export const myActivityListGetErrorAction = (payload: { message: string }) => ({
  type: MY_ACTIVITY_LIST_GET_ERROR,
  payload,
});

export const myActivityListResetAction = (payload: {
  req: {
    provider: string;
  };
}) => ({
  type: MY_ACTIVITY_LIST_RESET,
  payload,
});
