import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { analyticsServices } from "../../services/api-services/AnalyticsServices";
import {
  ADMIN_ANALYTICS_STATS_GET,
  SAFETY_CHECK_LINK,
} from "../actions/actions.constants";
import {
  adminGetAnalyticsStatsCompletedAction,
  adminGetAnalyticsStatsErrorAction,
  safetyCheckLinkCompleteAction,
  safetyCheckLinkErrorAction,
} from "../actions/analytics.action";
import { LinkSafetyStatus } from "../../utils/enums";

function* analyticsStatsSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(analyticsServices.get);
    yield put(
      adminGetAnalyticsStatsCompletedAction({ res: { stats: response.stats } })
    );
  } catch (e: any) {
    yield put(
      adminGetAnalyticsStatsErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* safetyCheckLinkSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(
      analyticsServices.safetyCheckLink,
      payload?.req
    );
    yield put(
      safetyCheckLinkCompleteAction({
        res: {
          link: payload.req.link,
          status:
            response.status && response.status?.length > 0
              ? response.status.includes[LinkSafetyStatus.MALWARE]
                ? LinkSafetyStatus.MALWARE
                : response.status.includes[
                    (LinkSafetyStatus.SOCIAL_ENGINEERING,
                    LinkSafetyStatus.SOCIAL_ENGINEERING_EXTENDED_COVERAGE)
                  ]
                ? LinkSafetyStatus.SOCIAL_ENGINEERING
                : LinkSafetyStatus.UNWANTED_SOFTWARE
              : LinkSafetyStatus.SAFE,
        },
      })
    );
  } catch (e: any) {
    yield put(
      safetyCheckLinkErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* analyticsSaga() {
  yield all([
    takeLatest(ADMIN_ANALYTICS_STATS_GET, analyticsStatsSaga),
    takeEvery(SAFETY_CHECK_LINK, safetyCheckLinkSaga),
  ]);
}

export default analyticsSaga;
