import { BrandConfig } from "../../models/entities/BrandConfig";
import { BrandConfigKeys } from "../../utils/enums";
import { AppState } from "../reducers/index";

export const userSelector = (state: AppState) => state.user;
export const authSelector = (state: AppState) => state.auth;
// @ts-ignore
export const transactionSelector = (state: AppState) => state.transaction;
export const itemStatsSelector = (state: AppState) => state.itemStats;
export const userEngagementSelector = (state: AppState) => state.engagement;
export const brandSelector = (state: AppState) => state.brand;
export const questSelector = (state: AppState) => state.quest;
export const brandConfigSelector = (
  configs: Record<number, BrandConfig>,
  key: BrandConfigKeys
) => {
  if (configs) {
    return Object.values(configs).find(
      (config: BrandConfig) => config.key === key
    );
  }
};
