import React from "react";

interface Props {
    className?: string;
}

const TwitterXLogo: React.FC<Props> = (props) => {
    return (
        <span className={`block ${props.className} `}>
            <svg width="100%" height="100%" viewBox="0 0 31 29" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.073959 0L11.7858 15.6533L0 28.3801H2.65252L12.971 17.2376L21.308 28.3801H30.3347L17.9638 11.8463L28.934 0H26.2815L16.7787 10.262L9.10058 0H0.073959ZM3.97468 1.95303H8.12154L26.4333 26.4268H22.2865L3.97468 1.95303Z" fill="currentColor" />
            </svg>
        </span>
    );
};

export default React.memo(TwitterXLogo);
