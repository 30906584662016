import { Transaction } from "../../models/entities/Transaction";
import {
  AdminTransactionsFiltersKey,
  TransactionStatus,
} from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class TransactionService {
  static getInstance(): TransactionService {
    return new TransactionService();
  }

  // Admin Transactions List
  async list(payload: {
    search: string;
    page: string;
    sort_by?: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
  }): Promise<{ transactions: Transaction[]; meta: any }> {
    const response = await baseApiService.get("/admin/transactions", {
      params: {
        search: payload.search || "",
        page: payload.page || "",
        sort_by: payload.sort_by || "",
        filters: JSON.stringify(payload.filters) || [],
      },
    });
    return {
      transactions: response.transactions,
      meta: response.meta,
    };
  }

  async userTransactionList(payload: {
    search: string;
    page: string;
    sort_by?: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
  }): Promise<{ transactions: Transaction[]; meta: any }> {
    const response = await baseApiService.get("/transactions", {
      params: {
        search: payload.search || "",
        page: payload.page || "",
        sort_by: payload.sort_by || "",
        filters: JSON.stringify(payload.filters) || [],
      },
    });
    return {
      transactions: response.transactions,
      meta: response.meta,
    };
  }

  async updateTransactionHash(
    data: { status?: TransactionStatus; transaction_hash?: string },
    transactionId: number
  ): Promise<void> {
    return await baseApiService.put(
      "/transactions/" + transactionId + "/hash",
      data
    );
  }

  async revert(payload: { id: number; comments: string }): Promise<{
    new_transaction: Transaction;
    updated_transaction: Transaction;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `admin/revert-transaction/${payload.id}`,
      {
        comments: payload.comments,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      new_transaction: response?.new_transaction,
      updated_transaction: response?.updated_transaction,
    };
  }

  async downloadTransactionStats(payload: {
    search: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
  }): Promise<{ url: string }> {
    const response = await baseApiService.get("admin/transactions/download", {
      params: {
        search: payload.search || "",
        filters: JSON.stringify(payload.filters) || [],
      },
    });
    return {
      url: response.data,
    };
  }
}

export const transactionService = TransactionService.getInstance();
