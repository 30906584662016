import React from "react";

interface LockCircleIconProps {
  className?: string;
}

const LockCircleIcon: React.FC<LockCircleIconProps> = (props) => {
  return (
    <span className={`block ${props.className} `}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0775 8.40705H12.6105V7.47295C12.6105 6.18389 11.5643 5.1377 10.2752 5.1377C8.98616 5.1377 7.93997 6.18389 7.93997 7.47295V8.40705H7.47292C6.95916 8.40705 6.53882 8.8274 6.53882 9.34115V14.0117C6.53882 14.5254 6.95916 14.9458 7.47292 14.9458H13.0775C13.5913 14.9458 14.0116 14.5254 14.0116 14.0117V9.34115C14.0116 8.8274 13.5913 8.40705 13.0775 8.40705ZM10.2752 12.6105C9.76147 12.6105 9.34112 12.1902 9.34112 11.6764C9.34112 11.1626 9.76147 10.7423 10.2752 10.7423C10.789 10.7423 11.2093 11.1626 11.2093 11.6764C11.2093 12.1902 10.789 12.6105 10.2752 12.6105ZM8.87407 8.40705V7.47295C8.87407 6.69764 9.49992 6.0718 10.2752 6.0718C11.0505 6.0718 11.6764 6.69764 11.6764 7.47295V8.40705H8.87407Z"
          fill="currentColor"
        />
        <rect
          x="0.467051"
          y="0.467051"
          width="19.6161"
          height="19.6161"
          rx="9.80806"
          stroke="currentColor"
          strokeWidth="0.934101"
        />
      </svg>
    </span>
  );
};

export default React.memo(LockCircleIcon);
