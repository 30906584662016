import { delay } from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  cookiePolicyPartOne,
  cookiePolicyPartTwo,
} from "../../../../public/mocks/cookiePolicyMock";
import {
  COOKIE_CONSENT,
  COOKIE_CONSENT_KEYS,
  COOKIE_CONSENT_VALUES,
  webStorageService,
} from "../../../services/WebStorageService";
import ExternallyControlledAccordion from "../accordion/ExternallyControlledAccordion";
import BrandButton from "../custom-components/BrandButton";
import Toggle from "../custom-components/toggle-switch/ToggleSwitch";
import CustomModal from "./CustomModal";
import Utils from "../../../utils/Utils";

interface Props {
  showCookieConsentModal: boolean;
  setShowCookieConsentModal: Dispatch<SetStateAction<boolean>>;
}

const CookieConsentModal: React.FC<Props> = ({
  setShowCookieConsentModal,
  showCookieConsentModal,
}) => {
  const cookieConsent = webStorageService.getLocalStorageValue(COOKIE_CONSENT);
  const cookieConsentParsed = cookieConsent && JSON.parse(cookieConsent);

  const [analyticsCookie, setAnalyticsCookie] = useState(
    cookieConsentParsed?.[COOKIE_CONSENT_KEYS.ANALYTICS]
      ? cookieConsentParsed?.[COOKIE_CONSENT_KEYS.ANALYTICS] ===
          COOKIE_CONSENT_VALUES.GRANTED
      : true
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (
      !cookieConsentParsed ||
      cookieConsentParsed?.[COOKIE_CONSENT_KEYS.ESSENTIAL] !==
        COOKIE_CONSENT_VALUES.GRANTED
    ) {
      setShowCookieConsentModal(true);
    }
  }, [cookieConsentParsed, setShowCookieConsentModal]);

  return (
    <CustomModal
      //   overlayClassNames="!bg-black/50"
      modalClassName="w-[99%] sm:w-[90%] md:w-[60%] lg:w-[50%] max-w-fit"
      onClose={() => {
        setShowCookieConsentModal(false);
      }}
      open={showCookieConsentModal}
      closeOnOutSideClick={false}
    >
      <div className="flex flex-col items-center w-full py-12 text-center">
        <h1 className="font-light sm:text-lg">COOKIE POLICY</h1>
        <p className="mx-2 mt-5 text-sm font-light text-left opacity-80">
          This site uses technical cookies, including third party cookies to
          ensure essential functionality and security and to analyze site
          traffic (“analytics cookies”). By using this site, you agree to the
          use of these cookies.{" "}
          <button
            onClick={() => {
              setIsExpanded((v) => !v);
            }}
            className="inline-flex items-start justify-center w-fit"
          >
            <span className="text-xs font-light underline hover:no-underline">
              {isExpanded ? "(Less)" : "(More)"}
            </span>
          </button>
        </p>
        <div className={`w-full mt-2 mb-4`}>
          <ExternallyControlledAccordion isExpanded={isExpanded}>
            <p className="whitespace-pre-wrap">{cookiePolicyPartOne}</p>
            <div>
              <p
                id="cookie-settings"
                className="py-0 my-0 whitespace-pre-wrap"
              >{`3. Types of Cookies We Use`}</p>
              <br />- Essential Cookies{" "}
              <Toggle
                switchClassName="!inline-flex mx-2"
                enabled
                disabled
                setEnabled={() => {}}
              />
              <br />
              These cookies are necessary for the website to function and cannot
              be switched off in our systems. They are usually only set in
              response to actions made by you which amount to a request for
              services, such as logging in or filling in forms. You can set your
              browser to block or alert you about these cookies, but the site
              will not then work.
              <br />
              <br />- Anonymous analytical cookies{" "}
              <Toggle
                switchClassName="!inline-flex mx-2"
                enabled={analyticsCookie}
                setEnabled={() => setAnalyticsCookie((v) => !v)}
              />
              <br />
              Analytical cookies having less identification ability (for
              example, by concealing important portions of the IP address) that
              allow aggregated and anonymous statistics to be collected.
            </div>
            <p className="whitespace-pre-wrap">{cookiePolicyPartTwo}</p>
          </ExternallyControlledAccordion>
        </div>
        <div className="flex flex-col space-y-4">
          <BrandButton
            type="button"
            theme="secondary"
            onClick={() => {
              webStorageService.setLocalStorageValue(
                COOKIE_CONSENT,
                JSON.stringify({
                  [COOKIE_CONSENT_KEYS.ESSENTIAL]:
                    COOKIE_CONSENT_VALUES.GRANTED,
                  [COOKIE_CONSENT_KEYS.ANALYTICS]: analyticsCookie
                    ? COOKIE_CONSENT_VALUES.GRANTED
                    : COOKIE_CONSENT_VALUES.DENIED,
                })
              );
              Utils.GAConsentUpdate(
                analyticsCookie
                  ? COOKIE_CONSENT_VALUES.GRANTED
                  : COOKIE_CONSENT_VALUES.DENIED
              );
              setShowCookieConsentModal(false);
            }}
            className="!text-xs !px-10 !py-4"
          >
            ACCEPT
          </BrandButton>
          <BrandButton
            type="button"
            theme="secondary"
            onClick={() => {
              setIsExpanded(true);
              delay(() => {
                document?.querySelector("#cookie-settings")?.scrollIntoView({
                  behavior: "smooth",
                });
              }, 250);
            }}
            className="!text-xs !text-white/50 !py-0 !px-0 !border-0"
          >
            Cookie Settings
          </BrandButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default React.memo(CookieConsentModal);
