import { Transaction } from "../../models/entities/Transaction";
import { AdminTransactionsFiltersKey } from "../../utils/enums";
import {
  TRANSACTIONS_STATS_DOWNLOAD,
  TRANSACTIONS_STATS_DOWNLOAD_COMPLETED,
  TRANSACTIONS_STATS_DOWNLOAD_ERROR,
  TRANSACTION_LIST,
  TRANSACTION_LIST_COMPLETED,
  TRANSACTION_LIST_EMPTY,
  TRANSACTION_LIST_ERROR,
  TRANSACTION_REVERT,
  TRANSACTION_REVERT_COMPLETED,
  TRANSACTION_REVERT_ERROR,
  USER_TRANSACTION_LIST_EMPTY,
  USER_TRANSACTION_LIST_GET,
  USER_TRANSACTION_LIST_GET_COMPLETED,
  USER_TRANSACTION_LIST_GET_ERROR,
} from "./actions.constants";

export const transactionListAction = (payload: {
  req?: {
    page?: number;
    search?: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
    sort_by?: string;
  };
}) => ({ type: TRANSACTION_LIST, payload });

export const transactionListCompletedAction = (data: {
  transactions: Transaction[];
  meta: any;
  wallet_address?: string;
}) => ({ type: TRANSACTION_LIST_COMPLETED, data });

export const transactionListErrorAction = (message: string) => ({
  type: TRANSACTION_LIST_ERROR,
  payload: message,
});

export const transactionRevertAction = (payload: {
  req: {
    id: number;
    comments: string;
  };
}) => ({ type: TRANSACTION_REVERT, payload });

export const transactionRevertCompletedAction = (payload: {
  res: {
    new_transaction: Transaction;
    updated_transaction: Transaction;
  };
}) => ({ type: TRANSACTION_REVERT_COMPLETED, payload });

export const transactionRevertErrorAction = (message: string) => ({
  type: TRANSACTION_REVERT_ERROR,
  payload: message,
});

export const transactionListEmptyAction = () => ({
  type: TRANSACTION_LIST_EMPTY,
});

export const userTransactionListEmptyAction = () => ({
  type: USER_TRANSACTION_LIST_EMPTY,
});

export const userTransactionListGetAction = (payload: {
  req?: {
    page?: number;
    search?: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
    sort_by?: string;
  };
}) => ({ type: USER_TRANSACTION_LIST_GET, payload });

export const userTransactionListGetCompletedAction = (data: {
  transactions: Transaction[];
  meta: any;
  wallet_address?: string;
}) => ({ type: USER_TRANSACTION_LIST_GET_COMPLETED, data });

export const userTransactionListGetErrorAction = (message: string) => ({
  type: USER_TRANSACTION_LIST_GET_ERROR,
  payload: message,
});

export const transactionStatsDownloadAction = (payload: {
  req?: {
    search?: string;
    filters?: {
      [AdminTransactionsFiltersKey.TYPES]: Array<string>;
      [AdminTransactionsFiltersKey.STATUSES]: Array<string>;
      [AdminTransactionsFiltersKey.CATEGORIES]: Array<string>;
    };
  };
}) => ({ type: TRANSACTIONS_STATS_DOWNLOAD, payload });

export const transactionStatsDownloadCompletedAction = () => ({
  type: TRANSACTIONS_STATS_DOWNLOAD_COMPLETED,
});

export const transactionStatsDownloadErrorAction = (message: string) => ({
  type: TRANSACTIONS_STATS_DOWNLOAD_ERROR,
  payload: message,
});
