import classNames from "classnames";
import React from "react";
import InputHelper from "../../input/Inputhelper/InputHelper";
import withFormikContext from "../../withFormikContext";

interface Props {
  name: string;
  error?: string;
  onChange: (event: any) => void;
  value?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onclick?: () => void;
  className?: string;
  labelClassnames?: string;
  parentClassname?: string;
}

export const CustomCheckbox: React.FC<Props> = ({
  name,
  onclick,
  onChange,
  error,
  value,
  disabled,
  children,
  className,
  labelClassnames,
  parentClassname,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <label
        className={`flex space-x-[2px] md:space-x-2 items-center w-full ${labelClassnames} `}
      >
        <input
          onClick={onclick}
          name={name}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
          checked={value}
          className={classNames(
            `border-2 focus:ring-offset-0 border-dropdownActiveLight w-4 h-4 rounded-md cursor-pointer ${className} disabled:cursor-not-allowed bg-transparent text-black focus:ring-white `,
            {}
          )}
        />
        <div
          className={classNames(
            "cursor-pointer grow truncate",
            {
              "cursor-not-allowed ": disabled,
            },
            parentClassname
          )}
        >
          {children}
        </div>
      </label>
      <InputHelper type={"error"} text={error} />
    </div>
  );
};

export default CustomCheckbox;

export const FormikCheck = withFormikContext(CustomCheckbox);
