import { AppProps } from "next/app";
import Script from "next/script";
import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConnectWalletProvider from "../src/components/brand-components/connect-wallet/ConnectWalletProvider";
import { ENV_G_MEASUREMENT_ID } from "../src/constants/secret";
import AppComponentWrapper from "../src/hoc/AppComponentWrapper";
import wrapper from "../src/redux/store";
import "../styles/globals.css";

const App: React.FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Script strategy="lazyOnload" id="GA0">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          if(localStorage.getItem('cookieConsent') === null){
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
              'analytics_storage': 'denied'
            });
          } else {
            const value = JSON.parse(localStorage.getItem('cookieConsent')).analytics_storage;
            gtag('consent', 'default', {
              'analytics_storage': value,
              'ad_storage': value,
              'ad_user_data': value,
              'ad_personalization': value,
            });
          }
        `}
      </Script>
      <Script
        strategy="lazyOnload"
        id="GA1"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${ENV_G_MEASUREMENT_ID}`}
      />
      <Script strategy="lazyOnload" id="GA2">
        {/* Define dataLayer and the gtag function. */}
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${ENV_G_MEASUREMENT_ID}');
        `}
      </Script>

      <Provider store={store}>
        <ToastContainer
          // hideProgressBar
          limit={3}
          closeButton={false}
          pauseOnFocusLoss={false}
          theme="dark"
          toastClassName={`relative flex p-4 rounded-xl text-sm !font-secondary justify-between overflow-hidden cursor-pointer bg-primaryModal/90 backdrop-blur-md text-primaryText border border-primaryText/10`}
        />
        <ConnectWalletProvider>
          <AppComponentWrapper>
            <Component {...props.pageProps} />
          </AppComponentWrapper>
        </ConnectWalletProvider>
      </Provider>
    </>
  );
};

export default App;
