import classNames from "classnames";
import { Eye, EyeClosed } from "phosphor-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { safetyCheckLinkAction } from "../../../../redux/actions/analytics.action";
import { AppState } from "../../../../redux/reducers";
import { AnalyticsState } from "../../../../redux/reducers/analytics.reducer";
import { LinkSafetyStatus } from "../../../../utils/enums";
import Badge from "../../Badge/Badge";
import InputLabel from "../InputLabel/InputLabel";
import { InputProps } from "../InputProps";
import InputHelper from "../Inputhelper/InputHelper";

const Input: FunctionComponent<InputProps> = ({
  name,
  value,
  type,
  onChange,
  onBlur,
  onClick,
  placeholder,
  label,
  noColourChangeLabel,
  helperText,
  leadingIcon,
  trailingIcon,
  containerClassName,
  className,
  labelClassName,
  style,
  disabled,
  error,
  showErrorText = true,
  min,
  max,
  maxLength,
  minLength,
  floatingLabel,
  floatingLabelClassnames,
  checkLinkSafety = false,
  leadingIconClassNames,
  ...rest
}: InputProps) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false);

  const handleChange = useCallback(() => setVisible(!visible), [visible]);

  const { safety_check }: AnalyticsState = useSelector((state: AppState) => state.analytics)

  const isLink = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(value)

  const setType = useCallback(() => {
    if (type === "password") {
      if (visible) {
        return "text";
      }
      return "password";
    }
    return type || "text";
  }, [visible, type]);

  const showSafetyCheck = checkLinkSafety && isLink
  const safetyStatus = safety_check?.[value]?.status as LinkSafetyStatus

  useEffect(() => {
    if (showSafetyCheck && !safetyStatus) {
      dispatch(
        safetyCheckLinkAction({
          req: {
            link: value
          }
        })
      )
    }
  }, [dispatch, showSafetyCheck, safetyStatus, value])

  const [height, setHeight] = useState<number>();

  const elementId = rest.id || name;
  return (
    <>
      {label && !floatingLabel && (
        <InputLabel
          htmlFor={elementId}
          label={label}
          className={`${!error && value && !noColourChangeLabel ? "" : ""} ${disabled ? "text-primaryText/75" : ""
            } ${error ? "text-brandError" : ""} ${labelClassName}`}
        />
      )}

      <div
        className={` ${label ? "mt-2" : ""
          } relative w-full ${containerClassName}`}
        style={{ height: height || "auto" }}
      >
        {leadingIcon && (
          <div
            className={`absolute inset-y-0 left-0 flex items-center w-8 pl-3 pointer-events-none ${disabled ? "opacity-50" : ""
              } ${leadingIconClassNames}`}
          >
            {leadingIcon}
          </div>
        )}
        <input
          id={elementId}
          // ref={(node: any) => {
          //   if (node !== null) {
          //     const nodeHeight = node?.getBoundingClientRect()?.height;
          //     if (nodeHeight && nodeHeight !== height) {
          //       setHeight(nodeHeight);
          //     }
          //   }
          // }}
          name={name}
          value={value}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          type={setType()}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          disabled={disabled}
          onWheelCapture={(e) => e.currentTarget.blur()}
          placeholder={!floatingLabel ? placeholder : placeholder || " "}
          {...rest}
          style={style}
          className={classNames(
            `block bg-transparent rounded-xl w-full transition-all ring-1 hover:ring-2 ring-primaryText/50 hover:ring-primaryText focus:ring-primaryText placeholder-brandGray/75 border-0 duration-300 focus:ring-1 focus:outline-none py-3 md:py-4 px-5 peer`,
            {
              "pl-12": !!leadingIcon,
              "pr-10": !!trailingIcon || type === "password",
              "opacity-50 pointer-events-none": !!disabled,
              "!placeholder-brandError !ring-brandError focus:!ring-brandError hover:!ring-brandError":
                !!error,
            },
            className
          )}
          aria-invalid={!!error}
          aria-errormessage={rest.id && error ? `${rest.id}-error` : undefined}
        />
        {label && floatingLabel && (
          <label
            htmlFor={elementId}
            onClick={() => {
              if (document && elementId) {
                document?.getElementById(elementId)?.focus();
              }
            }}
            className={classNames(
              `absolute cursor-text duration-300 transform -translate-y-1/2 scale-90 top-0 z-10 bg-primaryBackground px-2 left-2 peer-focus:left-2 peer-focus:top-0 peer-focus:scale-90 peer-focus:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 ${leadingIcon ? "peer-placeholder-shown:left-10" : ""
              } ${disabled ? "text-primaryText/75" : ""} ${error ? "text-brandError" : ""} ${floatingLabelClassnames}`
            )}
          >
            {label}
          </label>
        )}
        {
          showSafetyCheck &&
          <Badge
            text={`${safetyStatus === LinkSafetyStatus.SAFE ? "Looks Safe!" : "Looks Risky!"}`}
            className={classNames(
              "!rounded-full text-black text-xs mx-auto !py-1.5 tracking-wide w-fit mt-2",
              {
                "bg-brandGreen": safetyStatus === LinkSafetyStatus.SAFE
              },
              {
                "bg-brandError": [LinkSafetyStatus.MALWARE, LinkSafetyStatus.SOCIAL_ENGINEERING, LinkSafetyStatus.UNWANTED_SOFTWARE].includes(safetyStatus)
              },
            )}
          />}
        {type === "password" ? (
          <div
            aria-hidden
            className={`absolute inset-y-0 flex items-center w-8 right-1 ${disabled ? "pointer-events-none opacity-50" : ""
              }`}
            onClick={() => handleChange()}
          >
            {visible ? (
              <Eye className="cursor-pointer text-primaryText/75 icon-size-xs" />
            ) : (
              <EyeClosed className="cursor-pointer text-primaryText/75 icon-size-xs" />
            )}
          </div>
        ) : (
          trailingIcon && (
            <div
              className={`absolute inset-y-0 flex items-center w-8 right-1 ${disabled ? "pointer-events-none opacity-50" : ""
                }`}
            >
              {trailingIcon}
            </div>
          )
        )}
      </div>

      {!disabled && (
        <InputHelper type="helper" text={error ? undefined : helperText} />
      )}
      {showErrorText && <InputHelper type="error" text={error} />}
    </>
  );
};

export default React.memo(Input);
