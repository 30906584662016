import classNames from "classnames";
import React from "react";
import { AbbreviatedSize } from "../../../utils/size/AbbreviatedSize";
import { TopRoundedSpinner } from "./TopRoundedSpinner";
export interface Props {
  size?: AbbreviatedSize;
  bg?: string;
  initial?: boolean;
}

export const FullScreenSpinner: React.FunctionComponent<Props> = ({
  size,
  bg,
  initial,
}) => {
  return (
    <div
      className={classNames("fixed bottom-0 inset-x-0 px-4 pb-4 inset-0  ")}
      // zIndex More than the WALLET-NOT-CONNECTED Overlay and Select Wallet Modal
      style={{ zIndex: 10002 }}
    >
      <div className="fixed inset-0 flex items-center justify-center w-full h-full ">
        <div
          className={classNames(
            `absolute inset-0 backdrop-blur-xl bg-primaryBackground/80 ${bg} ${
              initial ? "!bg-black" : ""
            }`
          )}
        />
        <TopRoundedSpinner initial={initial} size={size} />
      </div>
    </div>
  );
};

export default React.memo(FullScreenSpinner);
