import {
  EXCLUSIVE_CONTENTS_LIST_GET,
  EXCLUSIVE_CONTENTS_LIST_GET_COMPLETED,
  EXCLUSIVE_CONTENTS_LIST_GET_ERROR,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_COMPLETED,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_ERROR,
  EXCLUSIVE_CONTENT_CREATE,
  EXCLUSIVE_CONTENT_CREATE_COMPLETED,
  EXCLUSIVE_CONTENT_CREATE_ERROR,
  EXCLUSIVE_CONTENT_DELETE,
  EXCLUSIVE_CONTENT_DELETE_COMPLETED,
  EXCLUSIVE_CONTENT_DELETE_ERROR,
  EXCLUSIVE_CONTENT_GET,
  EXCLUSIVE_CONTENT_GET_COMPLETED,
  EXCLUSIVE_CONTENT_GET_ERROR,
  EXCLUSIVE_CONTENT_UPDATE,
  EXCLUSIVE_CONTENT_UPDATE_COMPLETED,
  EXCLUSIVE_CONTENT_UPDATE_ERROR,
} from "./actions.constants";
import { ExclusiveContent } from "../../models/entities/exclusive-content/ExclusiveContent";
import {
  ExclusiveContentCreateRequestPayload,
  ExclusiveContentUpdateRequestPayload,
} from "../../models/entities/exclusive-content/ExclusiveContentRequestPayload";
import { SortBy } from "../../utils/enums";

// Exclusive Content Get Action:

export const exclusiveContentGetAction = (payload: { id: number }) => ({
  type: EXCLUSIVE_CONTENT_GET,
  payload,
});

export const exclusiveContentGetCompletedAction = (payload: {
  res: { exclusive_content: ExclusiveContent };
}) => ({
  type: EXCLUSIVE_CONTENT_GET_COMPLETED,
  payload,
});

export const exclusiveContentGetErrorAction = (payload: {
  message: string;
}) => ({
  type: EXCLUSIVE_CONTENT_GET_ERROR,
  payload,
});

// Exclusive Content Create Action:

export const exclusiveContentCreateAction = (payload: {
  req: ExclusiveContentCreateRequestPayload;
  thumbnail_object_url?: string | null;
  video_object_url?: string | null;
  image_object_url?: string | null;
}) => ({
  type: EXCLUSIVE_CONTENT_CREATE,
  payload,
});

export const exclusiveContentCreateCompletedAction = (payload: {
  res: { exclusive_content: ExclusiveContent };
}) => ({
  type: EXCLUSIVE_CONTENT_CREATE_COMPLETED,
  payload,
});

export const exclusiveContentCreateErrorAction = (payload: {
  message: string;
}) => ({
  type: EXCLUSIVE_CONTENT_CREATE_ERROR,
  payload,
});

//Exclusive Content Cover-image Edit Action

export const exclusiveContentCoverImageEditAction = (payload: {
  id: number;
  thumbnail_object_url: string;
}) => ({
  type: EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE,
  payload,
});

export const exclusiveContentCoverImageEditCompletedAction = (payload: {
  res: { exclusive_content: ExclusiveContent };
}) => ({
  type: EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_COMPLETED,
  payload,
});

export const exclusiveContentCoverImageEditErrorAction = (payload: {
  message: string;
}) => ({
  type: EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_ERROR,
  payload,
});

//Exclusive Content Edit Action

export const exclusiveContentEditAction = (payload: {
  id: number;
  req: ExclusiveContentUpdateRequestPayload;
  video_object_url?: string | null;
  image_object_url?: string | null;
}) => ({
  type: EXCLUSIVE_CONTENT_UPDATE,
  payload,
});

export const exclusiveContentEditCompletedAction = (payload: {
  res: { exclusive_content: ExclusiveContent };
}) => ({
  type: EXCLUSIVE_CONTENT_UPDATE_COMPLETED,
  payload,
});

export const exclusiveContentEditErrorAction = (payload: {
  message: string;
}) => ({
  type: EXCLUSIVE_CONTENT_UPDATE_ERROR,
  payload,
});

//Exclusive Content List Action

export const exclusiveContentListGetAction = (payload: {
  req: {
    search?: string;
    sortBy?: SortBy;
    filters?: string[];
  };
}): any => ({ type: EXCLUSIVE_CONTENTS_LIST_GET, payload });

export const exclusiveContentListGetCompletedAction = (payload: {
  res: { exclusive_contents: ExclusiveContent[] };
}) => ({ type: EXCLUSIVE_CONTENTS_LIST_GET_COMPLETED, payload });

export const exclusiveContentListGetErrorAction = (payload: {
  message: string;
}) => ({
  type: EXCLUSIVE_CONTENTS_LIST_GET_ERROR,
  payload,
});

// Exclusive Content Delete Action

export const exclusiveContentDeleteAction = (payload: { id: number }): any => ({
  type: EXCLUSIVE_CONTENT_DELETE,
  payload,
});
export const exclusiveContentDeleteActionCompleted = (payload: {
  id: number;
}): any => ({
  type: EXCLUSIVE_CONTENT_DELETE_COMPLETED,
  payload,
});

export const exclusiveContentDeleteActionError = (payload: {
  message: number;
}): any => ({
  type: EXCLUSIVE_CONTENT_DELETE_ERROR,
  payload,
});
