import classNames from "classnames";
import Image from "next/image";
import React, { useState } from "react";
import TopRoundedSpinner from "../../Spinner/TopRoundedSpinner";

interface Props {
  url: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  parentClassName?: string;
  layout?: string;
  alt?: string;
  loading?: boolean;
}

const ImageWrapper: React.FC<Props> = ({
  url,
  height,
  width,
  onClick,
  className,
  parentClassName,
  layout,
  loading,
  alt,
}) => {
  const [isImageLoaded, setIsimageLoaded] = useState(false);

  return (
    <div
      className={classNames(
        "flex relative font-bold justify-center items-center overflow-hidden",
        parentClassName,
        {
          "": !isImageLoaded || loading,
        }
      )}
    >
      {!isImageLoaded || loading ? (
        <div className="absolute z-50 flex items-center justify-center w-full h-full">
          <TopRoundedSpinner size="xs" />
        </div>
      ) : null}
      <Image
        onClick={onClick}
        className={`aspect-square overflow-hidden ${className} ${
          (!isImageLoaded || loading) && "blur-sm "
        } `}
        height={height}
        width={width}
        src={url ?? ""}
        layout={layout as any}
        alt={alt ?? "profile pic"}
        onError={() => setIsimageLoaded(true)}
        onLoadingComplete={() => setIsimageLoaded(true)}
      />
    </div>
  );
};

export default ImageWrapper;
