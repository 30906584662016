import { Engagement } from "../../models/entities/Engagement";
import { UserEngagement } from "../../models/entities/UserEngagement";
import { SortBy } from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class UserEngagementService {
  static getInstance(): UserEngagementService {
    return new UserEngagementService();
  }

  async list(): Promise<{
    engagement: {
      twitter: Engagement[];
      discord: Engagement[];
      instagram: Engagement[];
      other: Engagement[];
    };
    user_engagements: Engagement[];
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/user-engagements", {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      engagement: response.data.engagements,
      user_engagements: response.data.user_engagements,
    };
  }
  async create(payload: {
    provider: string;
    type: string;
    username: string;
    per_wallet: number;
    total: number;
    provider_id: string;
  }): Promise<{ engagement: Engagement }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("/engagements", payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { engagement: response.data.engagement };
  }
  async edit(payload: {
    id: number;
    per_wallet: number;
    total: number;
  }): Promise<{ engagement: Engagement }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/engagements/${payload.id}`,
      payload,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { engagement: response.data.engagement };
  }
  async claim(payload: {
    provider: string;
    provider_id?: string;
    isWithoutApi?: boolean;
  }): Promise<{ amount: string; engagements: Engagement[] }> {
    const authToken = await webStorageService.getAuthToken();
    const endpoint = payload.isWithoutApi
      ? "user-engagements/claim-free"
      : "/user-engagements";
    const response = await baseApiService.post(endpoint, null, {
      params: {
        provider: payload.provider,
        providerId: payload.provider_id,
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      amount: response?.data?.amount,
      engagements: response.data.engagements,
    };
  }

  // this is fake twitter Claim servicer which should be modified with the new values
  async fakeTwitterClaim(payload: {
    provider: string;
  }): Promise<{ points: string }> {
    const authtoken = await webStorageService.getAuthToken();
    const response = await baseApiService.post(
      "/according to the backend route ",
      null,
      {
        params: {
          provider: payload.provider,
        },
        extras: {
          useAuth: authtoken ? true : false,
        },
      }
    );
    return { points: response?.data?.amount };
  }

  async myActivityList(payload: {
    provider: string;
    filters: string[];
    sort_by: SortBy;
  }): Promise<{
    user_engagements: UserEngagement[];
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/user-engagements/my-activity", {
      params: {
        provider: payload.provider,
        filters: payload.filters ?? [],
        sort_by: payload.sort_by ?? "",
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      user_engagements: response.data.user_engagements,
    };
  }
}

export const userEngagementService = UserEngagementService.getInstance();
