import { all, call, put, takeLatest } from "redux-saga/effects";
import { Invite } from "../../models/entities/Invite";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import {
  INVITE_ID,
  REFERRAL_CODE,
  webStorageService,
} from "../../services/WebStorageService";
import { invitesService } from "../../services/api-services/InvitesService";
import { GET_INVITE_DETAILS } from "../actions/actions.constants";
import {
  getInviteCompletedAction,
  getInviteErrorAction,
} from "../actions/invites.action";

function* getInviteDetailsSaga(data: SAGA_PAYLOAD_TYPE) {
  try {
    const response: Invite = yield call(
      invitesService.getInviteByInviteId,
      data.payload
    );
    yield put(getInviteCompletedAction({ response }));
  } catch (e: any) {
    webStorageService.removeValue(REFERRAL_CODE);
    webStorageService.removeValue(INVITE_ID);
    yield put(
      getInviteErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

function* inviteSaga() {
  yield all([takeLatest(GET_INVITE_DETAILS, getInviteDetailsSaga)]);
}

export default inviteSaga;
