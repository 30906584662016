import { Menu, Transition } from "@headlessui/react";
import Link from "next/link";
import React, {
  Fragment,
  ReactElement,
  ReactNode,
  forwardRef,
  memo,
} from "react";
import { BrandContext } from "../../../../contexts/BrandContext";
import { BrandBackgroundColor } from "../../../../utils/enums";

interface Props {
  id?: any;
  children: ReactNode | ((open: boolean) => ReactNode);
  items: ReactElement[];
  menuItemsClassName?: string;
  menuItemsStyle?: any;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
}

// READ MORE: https://headlessui.com/react/menu#integrating-with-next-js
// eslint-disable-next-line react/display-name
export const NextJSHeadlessLink = forwardRef((props: any, ref) => {
  let { href, children, rel, target, ...rest } = props;
  return (
    <Link href={href} rel={rel} target={target} legacyBehavior>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  );
});

const DropDown: React.FC<Props> = ({
  id,
  items,
  children,
  menuItemsClassName,
  menuItemsStyle,
  className,
  disabled,
  readOnly,
  ref,
}) => {
  const brandValue = React.useContext(BrandContext);
  return (
    <Menu
      id={id}
      as={"div"}
      className={`inline-block w-full text-left ${className}`}
    >
      <Menu.Button
        disabled={disabled}
        className="relative w-full disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {({ open }) =>
          typeof children === "function" ? children(open) : children
        }
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className={`absolute backdrop-blur-xl shadow-primaryCardShadow w-full z-[60] transition-opacity duration-300 overflow-auto mt-2 rounded-[1.25rem] text-xs lg:text-sm font-medium ${menuItemsClassName} bg-primaryBackground/[88%] border border-primaryText/5`}
          style={{
            ...menuItemsStyle,
          }}
        >
          {items.map((item, index) => {
            return (
              <Menu.Item refName="" key={index}>
                {({ active }) =>
                  React.cloneElement(item, {
                    className: `${item.props?.className || ""} ${
                      active && !readOnly
                        ? brandValue?.background_color ===
                          BrandBackgroundColor.WHITE
                          ? "bg-dropdownActiveLight/25"
                          : "bg-dropdownActiveDark/25"
                        : ""
                    } transition-colors duration-300`,
                  })
                }
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default memo(DropDown);
