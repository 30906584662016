import { all, call, put, takeLatest } from "redux-saga/effects";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webToastService } from "../../services/WebToastService";
import { transactionService } from "../../services/api-services/TransactionService";
import {
  TRANSACTIONS_STATS_DOWNLOAD,
  TRANSACTION_LIST,
  TRANSACTION_REVERT,
  USER_TRANSACTION_LIST_GET,
} from "../actions/actions.constants";
import {
  transactionListCompletedAction,
  transactionListErrorAction,
  transactionRevertCompletedAction,
  transactionStatsDownloadCompletedAction,
  transactionStatsDownloadErrorAction,
  userTransactionListGetCompletedAction,
  userTransactionListGetErrorAction,
} from "../actions/transaction.actions";

function* list(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(transactionService.list, data.payload?.req);
    yield put(
      transactionListCompletedAction({
        transactions: response.transactions,
        meta: response.meta,
        wallet_address: data.payload.wallet_address,
      })
    );
  } catch (e: any) {
    yield put(transactionListErrorAction(e?.message));
  }
}
function* userTransactionList(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(
      transactionService.userTransactionList,
      data.payload?.req
    );
    yield put(
      userTransactionListGetCompletedAction({
        transactions: response.transactions,
        meta: response.meta,
        wallet_address: data.payload.wallet_address,
      })
    );
  } catch (e: any) {
    yield put(userTransactionListGetErrorAction(e?.message));
  }
}

function* revert(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(transactionService.revert, data.payload.req);
    yield put(
      transactionRevertCompletedAction({
        res: {
          new_transaction: response?.new_transaction,
          updated_transaction: response.updated_transaction,
        },
      })
    );
    webToastService.showSuccess("Transaction reverted successfully");
  } catch (e: any) {
    webToastService.showError(e?.message);
    yield put(transactionListErrorAction(e?.message));
  }
}

function* downloadTransactionsStatsSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(
      transactionService.downloadTransactionStats,
      data.payload?.req
    );
    yield put(transactionStatsDownloadCompletedAction());
    webToastService.showSuccess("File Downloaded Successfully");
    window.open(response.url, "_blank", "noopener noreferrer");
  } catch (e: any) {
    yield put(transactionStatsDownloadErrorAction(e?.message));
  }
}

function* transactionSaga() {
  yield all([
    takeLatest(TRANSACTION_LIST, list),
    takeLatest(TRANSACTION_REVERT, revert),
    takeLatest(USER_TRANSACTION_LIST_GET, userTransactionList),
    takeLatest(TRANSACTIONS_STATS_DOWNLOAD, downloadTransactionsStatsSaga),
  ]);
}

export default transactionSaga;
