import { ExclusiveContent } from "../../models/entities/exclusive-content/ExclusiveContent";
import {
  EXCLUSIVE_CONTENTS_LIST_GET,
  EXCLUSIVE_CONTENTS_LIST_GET_COMPLETED,
  EXCLUSIVE_CONTENTS_LIST_GET_ERROR,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_COMPLETED,
  EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_ERROR,
  EXCLUSIVE_CONTENT_CREATE,
  EXCLUSIVE_CONTENT_CREATE_COMPLETED,
  EXCLUSIVE_CONTENT_CREATE_ERROR,
  EXCLUSIVE_CONTENT_DELETE,
  EXCLUSIVE_CONTENT_DELETE_COMPLETED,
  EXCLUSIVE_CONTENT_DELETE_ERROR,
  EXCLUSIVE_CONTENT_GET,
  EXCLUSIVE_CONTENT_GET_COMPLETED,
  EXCLUSIVE_CONTENT_GET_ERROR,
  EXCLUSIVE_CONTENT_UPDATE,
  EXCLUSIVE_CONTENT_UPDATE_COMPLETED,
  EXCLUSIVE_CONTENT_UPDATE_ERROR,
  RESET_APP_STATE,
} from "../actions/actions.constants";

export interface ExclusiveContentState {
  entities: Record<number, ExclusiveContent>;
  entityIds: number[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

const initialState: ExclusiveContentState = {
  loading: false,
  loaded: false,
  error: "",
  entities: {},
  entityIds: [],
};
export const exclusiveContentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case EXCLUSIVE_CONTENT_GET:
    case EXCLUSIVE_CONTENT_UPDATE:
    case EXCLUSIVE_CONTENT_CREATE:
    case EXCLUSIVE_CONTENT_DELETE:
    case EXCLUSIVE_CONTENTS_LIST_GET:
    case EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case EXCLUSIVE_CONTENT_GET_ERROR:
    case EXCLUSIVE_CONTENT_UPDATE_ERROR:
    case EXCLUSIVE_CONTENT_CREATE_ERROR:
    case EXCLUSIVE_CONTENT_DELETE_ERROR:
    case EXCLUSIVE_CONTENTS_LIST_GET_ERROR:
    case EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }

    case EXCLUSIVE_CONTENTS_LIST_GET_COMPLETED: {
      const contents: ExclusiveContent[] =
        action.payload.res.exclusive_contents;
      const contentEntities = {
        entities: contents.reduce<{ [id: number]: ExclusiveContent }>(
          (accumulator: any, content: ExclusiveContent) => {
            return { ...accumulator, [content.id]: { ...content } };
          },
          {}
        ),
        entityIds: contents.reduce<number[]>(
          (accumulator: any, content: ExclusiveContent) => {
            return [...accumulator, content.id];
          },
          []
        ),
      };
      return {
        ...state,
        entities: contentEntities.entities,
        entityIds: contentEntities.entityIds,
        loading: false,
        loaded: true,
      };
    }
    case EXCLUSIVE_CONTENT_UPDATE_COMPLETED:
    case EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_COMPLETED: {
      const content: ExclusiveContent = action.payload.res?.exclusive_content;
      const prvEntityIds = state.entityIds.filter((id) => id !== content.id);
      return {
        ...state,
        entityIds: [content?.id, ...prvEntityIds],
        entities: { ...state.entities, [content.id]: { ...content } },
        loading: false,
        loaded: true,
      };
    }

    case EXCLUSIVE_CONTENT_GET_COMPLETED:
    case EXCLUSIVE_CONTENT_CREATE_COMPLETED: {
      const content: ExclusiveContent = action.payload.res?.exclusive_content;
      return {
        ...state,
        entityIds: [content?.id, ...state.entityIds],
        entities: { ...state.entities, [content.id]: { ...content } },
        loading: false,
        loaded: true,
      };
    }

    case EXCLUSIVE_CONTENT_DELETE_COMPLETED: {
      const exclusiveContentId = action?.payload?.id;
      const exclusiveEntityIds = state.entityIds.filter(
        (id: any) => id !== exclusiveContentId
      );
      const exclusiveEntities = exclusiveEntityIds.reduce(
        (accumulator: any, id: number) => {
          return {
            ...accumulator,
            [id]: { ...state.entities[id] },
          };
        },
        []
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entityIds: [...exclusiveEntityIds],
        entities: { ...exclusiveEntities },
        error: "",
      };
    }
    default:
      return state;
  }
};
