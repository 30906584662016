export enum SortBy {
  updated_at = "updated_at",
  updated_at_asc = "updated_at__asc",
  created_at = "created_at",
  created_at_asc = "created_at__asc",
  value_asc = "value__asc",
  value_desc = "value__desc",
  title_asc = "title__asc",
  title_desc = "title__desc",
  oldest_updated = "updated_at__asc",

  // Members Points
  points_asc = "points__asc",
  points_desc = "points__desc",

  // Transactions Amount
  amount_asc = "amount__asc",
  amount_desc = "amount__desc",
}

export enum CampaignsSortBy {
  updated_at = "updated_at",
  value_asc = "used__asc",
  value_desc = "used__desc",
}

export const enum AdminMembersFiltersKey {
  TIERS = "tiers",
  PROVIDERS = "providers",
}

export const enum AdminTransactionsFiltersKey {
  TYPES = "types",
  STATUSES = "statuses",
  CATEGORIES = "categories",
  FROM_DATE = "from_date",
  TO_DATE = "to_date",
}

export enum Providers {
  QUEST = "quest",
  TWITTER = "twitter",
  DISCORD = "discord",
  YOUTUBE = "youtube",
  GMAIL = "gmail",
  INSTAGRAM = "instagram",
  SPOTIFY = "spotify",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  OPEN_SEA = "open_sea",
}

export enum EngagementType {
  FOLLOW = "follow",
  TWEET = "tweet",
  JOIN = "join",
  SUBSCRIBE = "subscribe",
  // GO = "go",
  // BACKSTORY = "backstory",
  LIKE = "like",
  VIDEO = "video",
  LOGIN = "login",
}
export enum TwitterEngagementType {
  FOLLOW = "follow",
  RETWEET = "retweet",
  REPLY = "reply",
  LIKE = "like",
  LOGIN = "login",
}
export enum InstagramEngagementType {
  FOLLOW = "follow",
  SHARE = "share",
  COMMENT = "comment",
  LIKE = "like",
  POST = "post",
  LOGIN = "login",
}

export enum YoutubeEngagementType {
  SUBSCRIBE = "subscribe",
  LIKE = "like",
  COMMENT = "comment",
  LOGIN = "login",

  // WATCH = "watch",
}
export enum TikTokEngagementType {
  FOLLOW = "follow",
  SHARE = "share",
  COMMENT = "comment",
  LIKE = "like",
  POST = "post",
  LOGIN = "login",
}
export enum DiscordEngagementType {
  JOIN = "join",
  LOGIN = "login",
}

export enum BrandImageType {
  LOGO = "logo",
  BANNER = "banner",
  SHARE_IMAGE = "share.image",
  FAVICON = "favicon",
}
export enum BrandConfigKeys {
  TWITTER = "twitter",
  TWITTER_CONFIG = "twitter.config",
  TWITTER_CREDITS = "twitter.credits",
  DISCORD = "discord",
  DISCORD_CONFIG = "discord.config",
  DISCORD_CREDITS = "discord.credits",
  YOUTUBE = "youtube",
  YOUTUBE_CONFIG = "youtube.config",
  YOUTUBE_CREDITS = "youtube.credits",
  INSTAGRAM = "instagram",
  INSTAGRAM_CONFIG = "instagram.config",
  INSTAGRAM_CREDITS = "instagram.credits",
  LINKEDIN = "linkedin",
  LINKEDIN_CONFIG = "linkedin.config",
  LINKEDIN_CREDITS = "linkedin.credits",
  SPOTIFY = "spotify",
  SPOTIFY_CONFIG = "spotify.config",
  SPOTIFY_CREDITS = "spotify.credits",
  WELCOME = "welcome",
  WELCOME_CREDITS = "welcome.credits",
  TIERS = "tiers",
  TIERS_CONFIG = "tiers.config",
  TIERS_VALUES = "tiers.values",
  STYLES = "styles",
  IMAGES = "images",
  GATED_ENTRY_CONFIG = "gated_entry.config",
  GATED_ENTRY_TOKEN = "gated_entry.token",
  WALLET_LOGIN_CONFIG = "wallet_login.config",
  EMAIL_LOGIN_CONFIG = "email_login.config",
  EMAIL = "emails",
  QUEST_CONFIG = "quest.config",
  TIKTOK = "tiktok",
  TIKTOK_CONFIG = "tiktok.config",
  TIKTOK_CREDITS = "tiktok.credits",
  EMAIL_APPEARANCE = "email.appearance",
}

export enum SocialProfileActions {
  EDIT = "edit",
  DELETE = "delete",
  ADD = "add",
}

export enum AnalyticsTabs {
  USER = "user",
  STATS = "stats",
  ADMIN = "admin",
}

export enum BrandButtonColorStyle {
  SOLID = "solid",
  GRADIENT = "gradient",
}
export enum EngagementActions {
  EDIT = "edit",
  DUPLICATE = "duplicate",
  END = "end",
  PAUSE = "pause",
}

export enum ItemStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  ARCHIVED = "archived",
}

export enum MyItemsTabs {
  PURCHASES = "purchases",
  TRANSACTIONS = "transaction",
}

export enum BrandBackgroundColor {
  BLACK = "#000000",
  WHITE = "#FFFFFF",
}

export enum Category {
  MERCHANDISE = "merchandise",
  UNCLAIMED = "unclaimed",
  RAFFLE = "raffle",
  SERVICE = "service",
  NFT = "nft",
  ALLOWLIST = "allowlist",
  NFH_IQ = "nfh_iq",
  COMICS = "comics",
  THE_FRONTIER = "the_frontier",
  POAP = "poap",
  ART = "art",
  EXPERIENCE = "experience",
}
// Not needed
// export enum Menu {
//   EARN = `earn`,
//   REDEEM = `redeem`,
//   LEADERBOARD = `leaderboard`,
//   SETTINGS = `settings`
// }

export enum FilterBy {
  live = "live",
  archived = `archived`,
  DRAFT = "draft",
  sold_out = "sold_out",
  coming_soon = "coming_soon",
}

export enum APIErrorCode {
  INSUFFICIENT_TOKEN_BALANCE = "503",
  VERIFICATION_CODE_EXPIRED = "500",
  EMAIL_IS_NOT_WHITELISTED = "422",
  USER_QUIZ_QUEST_INCORRECT_ANSWER = "34",
  E_INCORRECT_ANSWER = "35",
  SAFETY_CHECK_LINK_FAILED = "40",
}

export enum LeaderboardNavTabs {
  TODAY = "today",
  WEEK = "week",
  MONTH = "month",
  ALL_TIME = "all time",
}

export enum Contacts {
  email = `email`,
  discord = "discord",
}

export enum CardTypes {
  heroes = "heroes",
  villians = "villians",
  gods = "gods",
  bananas = "bananas",
}

export enum TokenTraits {
  WINGS = "wings",
  FACTION = "faction",
  BACKGROUND = "background",
  SUIT = "suit",
  MOUTH = "mouth",
  IDENTITY = "identity",
  BASE = "base",
  EYES = "eyes",
  HEADGEAR = "headgear",
}

export enum LoreCheckTabValue {
  heroes = "heroes",
  gods = "gods",
  bananas = "bananas",
}

export enum NFTType {
  heroes = "heroes",
  gods = "gods",
  bananas = "bananas",
  whales = "whales",
  nff_executive_producer_pass = "nff_executive_producer_pass",
  all = "all",
}

export enum ClaimStatus {
  CLAIMED = "claimed",
  UNCLAIMED = "unclaimed",
}

export enum TransactionType {
  CREDIT = "credit",
  DEBIT = "debit",
}

export enum UploadStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  DONE = "done",
}

export enum TransactionStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum TransferType {
  WEB2_TO_WEB3 = "web2_to_web3",
  WEB3_TO_WEB2 = "web3_to_web2",
}

export enum TransactionCategory {
  ITEM_PURCHASE = "item_purchase",
  REVERT = "revert",
  SOCIAL_LOGIN = "social_login",
  ENGAGEMENT = "engagement",
  GIFT = "gift",
  REVOKE = "revoke",
  QUEST = "quest",
  WELCOME = "welcome",
}

export enum TableViewType {
  LIST = "list",
  GRID = "grid",
}
export enum ModalType {
  SUGGESTIONS = "suggestions",
  SUPPORT = "support",
}

export enum QuestCategory {
  POLL = "poll",
  QUIZ = "quiz",
  SURVEY = "survey",
  CONTEST = "contest",
  LEARN_2_EARN = "learn2earn",
  SUGGESTION_BOX = "suggestion_box",
  REFER_2_EARN = "refer2earn",
  CUSTOM = "custom",
  PROOF = "proof",
}

export enum QuestRepetition {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ONGOING = "ongoing",
}

export enum QuestLear2EarnSlug {
  VIDEO_URL = "lear2earn/video/url",
}

export enum QuestQuizSlug {
  QUESTIONS = "quiz/questions",
}

export enum QuestPollSlug {
  POLL_QUESTIONS = "poll/questions",
}

export enum QuestProofSlug {
  PROOF = "proof/meta",
}

export enum QuestSurveysSlug {
  QUESTIONS = "survey/questions",
}

export enum Learn2EarnSubType {
  WATCH = "watch",
  READ = "read",
}

export enum QuestRepeatInterval {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ONGOING = "ongoing",
}

export enum QuizQuestCategory {
  MULTIPLE_CHOICE = "multiple",
  SINGLE_CHOICE = "single",
}
export enum QuizSubType {
  VIDEO = "video",
  TEXT = "text",
}

export enum QuestFilterBy {
  live = "live",
  draft = "draft",
  archived = `archived`,
  exhausted = "exhausted",
  past = "past",
  coming_soon = "coming_soon",
}

export enum ProofSubType {
  MEDIA = "media", // Image or video
  TEXT = "text",
}

export enum QuestMenuActions {
  START = "start",
  INFO = "info",
  HIDE = "hide",
  SKIP = "skip",
}

export enum UserQuestStatus {
  PENDING = "pending",
  CLAIMED = "claimed",
  REJECTED = "rejected",
}

export enum Filters {
  FILTER = "filter_by",
  SORT_BY = "sort_by",
  CATEGORY = "category",
}

export enum InviteType {
  EMAIL = "email",
  REFERRAL_CODE = "referral_code",
}

export enum InviteStatus {
  NOOP = "noop",
  SENT = "sent",
  INITIATED = "initiated",
  ACCEPTED = "accepted",
}

export enum ExclusiveContentType {
  IMAGE = "image",
  VIDEO = "video",
}

export enum ExclusiveContentActions {
  EDIT = "edit",
  DUPLICATE = "duplicate",
  ARCHIVE = "archive",
  PUBLISH = "publish",
  REMOVE = "remove",
  SHARE = "share",
}

export enum ExclusiveContentFilterBy {
  LIVE = "live",
  DRAFT = "draft",
  ARCHIVED = "archived",
  PAST = "past",
  COMING_SOON = "coming_soon",
}

export enum GAEventName {
  LOGIN = "login",
  LOGOUT = "logout",
  SIGNUP = "signup",

  PROFILE_UPDATE_USER = "profile_update_user",
  ACCOUNT_PERMANENTLY_DELETE_USER = "account_permanently_delete_user",
  SOCIAL_CONNECT = "social_connect",
  SOCIAL_DISCONNECT = "social_disconnect",
  ALTERNATIVE_EMAIL = "alternative_email",
  ALTERNATIVE_WALLET_ADDRESS = "alternative_wallet_address",

  REWARD_REDEEM = "reward_redeem",
  REWARD_VIEW = "reward_view",

  ENGAGEMENT_TWITTER_PERFORM = "engagement_twitter_perform",
  ENGAGEMENT_YOUTUBE_PERFORM = "engagement_youtube_perform",
  ENGAGEMENT_DISCORD_PERFORM = "engagement_discord_perform",
  ENGAGEMENT_TIKTOK_PERFORM = "engagement_tiktok_perform",
  ENGAGEMENT_INSTAGRAM_PERFORM = "engagement_instagram_perform",

  QUEST_VIEW = "quest_view",
  QUEST_CLAIM = "quest_claim",

  EXCLUSIVE_CONTENT_VIEW = "exclusive_content_view",
  SHARE = "share",

  LINK_CLICK = "link_click",

  BUTTON_CLICK = "button_click",
}

export enum LinkSafetyStatus {
  SAFE = "safe",
  MALWARE = "malware",
  SOCIAL_ENGINEERING = "social_engineering",
  UNWANTED_SOFTWARE = "unwanted_software",
  SOCIAL_ENGINEERING_EXTENDED_COVERAGE = "social_engineering_extended_coverage",
}
