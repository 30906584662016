import { all, call, put, takeLatest } from "redux-saga/effects";
import { Engagement } from "../../models/entities/Engagement";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { userEngagementService } from "../../services/api-services/UserEngagementService";
import {
  MY_ACTIVITY_LIST_GET,
  USER_ENGAGEMENTS_CLAIM,
  USER_ENGAGEMENT_LIST_GET,
} from "../actions/actions.constants";
import {
  userFakeTwitterClaimsActionCompleted,
  userFakeTwitterClaimsActionError,
} from "../actions/brand.actions";
import {
  myActivityListGetCompletedAction,
  myActivityListGetErrorAction,
  userEngagementClaimErroAction,
  userEngagementListGetCompletedAction,
  userEngagementListGetError,
  userEngagementsClaimCompletedAction,
} from "../actions/user.engagement.action";

function* userEngagementListSaga(data: SAGA_PAYLOAD_TYPE) {
  const payload = data.payload;
  try {
    const response: {
      engagement: {
        twitter: Engagement[];
        discord: Engagement[];
        instagram: Engagement[];
        other: Engagement[];
      };
      user_engagements: Engagement[];
    } = yield call(userEngagementService.list);
    yield put(
      userEngagementListGetCompletedAction({
        res: {
          engagement: response.engagement,
          user_engagements: response.user_engagements,
        },
      })
    );
  } catch (e: any) {
    yield put(userEngagementListGetError({ message: e?.message }));
  }
}

function* userEngagementsClaimSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userEngagementService.claim, payload.req);
    yield put(
      userEngagementsClaimCompletedAction({
        res: {
          amount: response.amount,
          provider: payload?.req.provider,
          engagements: response.engagements,
        },
      })
    );
  } catch (e: any) {
    yield put(
      userEngagementClaimErroAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
        provider: payload.req.provider,
      })
    );
  }
}
function* myActivityListGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(
      userEngagementService.myActivityList,
      payload.req
    );
    yield put(
      myActivityListGetCompletedAction({
        provider: payload.req.provider,
        res: {
          userEngagements: response.user_engagements,
        },
      })
    );
  } catch (e: any) {
    yield put(
      myActivityListGetErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

// fake twitter saga

function* fakeTwitterClaimSaga(data: any): any {
  const payload = data.payload;
  try {
    const response = yield call(
      userEngagementService.fakeTwitterClaim,
      payload
    );
    yield put(
      userFakeTwitterClaimsActionCompleted({
        res: {
          res: response,
        },
      })
    );
  } catch (e: any) {
    yield put(
      userFakeTwitterClaimsActionError({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

function* userEngagementSaga() {
  yield all([
    takeLatest(USER_ENGAGEMENT_LIST_GET, userEngagementListSaga),
    takeLatest(USER_ENGAGEMENTS_CLAIM, userEngagementsClaimSaga),
    takeLatest(MY_ACTIVITY_LIST_GET, myActivityListGetSaga),
  ]);
}

export default userEngagementSaga;
