export enum UserDashboardItems {
  HOME = "home",
  REWARDS = "rewards",
  ACHIEVEMENTS = "achievements",
  SETTINGS = "settings",
  EXCLUSIVE_CONTENT = "exclusive_content",

  // More Dropdown
  MY_ITEMS = "my_items",
  SUPPORT = "support",
  LEGAL_DOCS = "legal_docs",
  SUGGESTION_BOX = "suggestion_box",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  PRIVACY_POLICY = "privacy_policy",
  COOKIE_POLICY = "cookie_policy",
  LOGOUT = "logout",

  //For Public Pages
  LOG_IN = "log_in",
  GET_IN_TOUCH = "get_in_touch",
}

export enum AdminDashboardItems {
  CAMPAIGNS = "admin/campaigns",
  REWARDS = "admin/rewards",
  ANALYTICS = "admin/analytics",
  TRANSACTIONS = "admin/transactions",
  MEMBERS = "admin/members",
  SETTINGS = "admin/settings",
  EXCLUSIVE_CONTENT = "admin/exclusive-content",
}
