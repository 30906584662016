import { Engagement } from "../../models/entities/Engagement";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class EngagementService {
  static getInstance(): EngagementService {
    return new EngagementService();
  }

  async list(payload: {
    filter: string[];
    sort_by: string;
    search?: string;
  }): Promise<{
    engagement: {
      twitter: Engagement[];
      discord: Engagement[];
      instagram: Engagement[];
      youtube: Engagement[];
      tiktok: Engagement[];
      gmail: Engagement[];
      other: Engagement[];
    };
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/engagements", {
      extras: {
        useAuth: authToken ? true : false,
      },
      params: {
        filters: payload.filter ?? [],
        sort_by: payload.sort_by,
        search: payload.search ?? "",
      },
    });
    return {
      engagement: response.data.engagements,
    };
  }
  async create(payload: {
    provider: string;
    type: string;
    username: string;
    per_wallet: any;
    total: number;
    provider_id: string;
    invite_link: string;
    video_url: string;
    isWithoutApi?: boolean;
  }): Promise<{ engagement: Engagement }> {
    const authToken = await webStorageService.getAuthToken();
    const endpoints = payload?.isWithoutApi
      ? "/engagements/createWithoutApi"
      : "/engagements";
    delete payload.isWithoutApi;
    const response = await baseApiService.post(endpoints, payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { engagement: response.data.engagements };
  }
  async edit(payload: {
    id: number;
    per_wallet: number;
    total: number;
  }): Promise<{ engagement: Engagement }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/engagements/${payload.id}`,
      payload,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { engagement: response.data.engagement };
  }
  async claim(payload: { provider: string }): Promise<{ amount: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("/user-engagements", null, {
      params: {
        provider: payload.provider,
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { amount: response?.data?.amount };
  }
  async toggleArchive(id: number): Promise<{ engagements: Engagement[] }> {
    const response = await baseApiService.put(
      `/engagements/${id}/toggle-archive`
    );
    return { engagements: response.data.engagements };
  }
}

export const engagementService = EngagementService.getInstance();
