import React from "react";

interface HomeMenuProps {
  className?: string;
}

const HomeMenu: React.FC<HomeMenuProps> = (props) => {
  return (
    <span className={`block ${props.className} `}>
      <svg
        fill="currentColor"
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.3333 1.66667V5H1.66667V1.66667H18.3333ZM18.3333 0H1.66667C1.22464 0 0.800716 0.175595 0.488155 0.488155C0.175595 0.800716 0 1.22464 0 1.66667V5C0 5.44203 0.175595 5.86595 0.488155 6.17851C0.800716 6.49107 1.22464 6.66667 1.66667 6.66667H18.3333C18.7754 6.66667 19.1993 6.49107 19.5118 6.17851C19.8244 5.86595 20 5.44203 20 5V1.66667C20 1.22464 19.8244 0.800716 19.5118 0.488155C19.1993 0.175595 18.7754 0 18.3333 0ZM5 10V18.3333H1.66667V10H5ZM5 8.33333H1.66667C1.22464 8.33333 0.800716 8.50893 0.488155 8.82149C0.175595 9.13405 0 9.55797 0 10V18.3333C0 18.7754 0.175595 19.1993 0.488155 19.5118C0.800716 19.8244 1.22464 20 1.66667 20H5C5.44203 20 5.86595 19.8244 6.17851 19.5118C6.49107 19.1993 6.66667 18.7754 6.66667 18.3333V10C6.66667 9.55797 6.49107 9.13405 6.17851 8.82149C5.86595 8.50893 5.44203 8.33333 5 8.33333ZM18.3333 10V18.3333H10V10H18.3333ZM18.3333 8.33333H10C9.55797 8.33333 9.13405 8.50893 8.82149 8.82149C8.50893 9.13405 8.33333 9.55797 8.33333 10V18.3333C8.33333 18.7754 8.50893 19.1993 8.82149 19.5118C9.13405 19.8244 9.55797 20 10 20H18.3333C18.7754 20 19.1993 19.8244 19.5118 19.5118C19.8244 19.1993 20 18.7754 20 18.3333V10C20 9.55797 19.8244 9.13405 19.5118 8.82149C19.1993 8.50893 18.7754 8.33333 18.3333 8.33333Z" />
      </svg>
    </span>
  );
};

export default React.memo(HomeMenu);
