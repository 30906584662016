import { useFormikContext } from "formik";
import React from "react";

// TODO: Below HOC take proptype as an argument

const withFormikContext = (WrappedComponent: React.FunctionComponent<any>) => {
  const Wrapper: React.FC<{ name: string } & Partial<any>> = ({
    name,
    ...rest
  }) => {
    const context: any = useFormikContext();
    // #TODO Fix On Blur
    const wrappedProps = {
      name,
      value: context.values[name],
      onChange: context.handleChange,
      onBlur: context.handleBlur,
      error: context.touched[name] && context.errors[name],
      ...rest,
    };
    return <WrappedComponent {...wrappedProps} />;
  };

  return Wrapper;
};

export default withFormikContext;
