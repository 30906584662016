import { Invite } from "../../models/entities/Invite";
import { baseApiService } from "./BaseApiService";

class InvitesService {
  static getInstance() {
    return new InvitesService();
  }

  public async getInviteByInviteId(payload: {
    invite_id: string;
  }): Promise<{ invite: Invite }> {
    const res = await baseApiService.get("/invites", {
      params: payload,
    });
    return res.data;
  }

  public async sendInvites(payload: {
    emails: string[];
  }): Promise<{ success?: boolean; error?: string[] }> {
    const res = await baseApiService.post("/invites", {
      emails: payload.emails,
    });
    return res.data;
  }
}
export const invitesService = InvitesService.getInstance();
