import { Admin } from "../../models/entities/Admin";
import { Brand } from "../../models/entities/Brand";
import { User } from "../../models/entities/User";
import { baseApiService } from "./BaseApiService";

class AuthService {
  static getInstance(): AuthService {
    return new AuthService();
  }

  async login(data: {
    address?: string;
    email?: string;
    invite_id?: string;
    referral_code?: string;
  }): Promise<{ nonce?: string; code?: string }> {
    return baseApiService.get("/auth/login", {
      params: data,
      extras: { useAuth: false },
    });
  }
  async verifyEmail(data: { code: string }): Promise<{ success: boolean }> {
    return baseApiService.get("/auth/verify-email", {
      params: data,
      extras: { useAuth: false },
    });
  }

  async checkVerification(data: {
    code: string;
    referral_code?: string;
    invite_id?: string;
  }): Promise<{ user?: User; admin?: Admin; brand: Brand; token: string }> {
    return baseApiService.get("/auth/check-verification", {
      params: data,
      extras: { useAuth: false },
    });
  }

  async verifySignature(data: {
    address: string;
    signature: string;
  }): Promise<{ data: User | Admin; token: string }> {
    return baseApiService.post("/auth/verify-signature", data, {
      extras: { useAuth: false },
    });
  }

  async rejectWalletSignature(data: {
    address: string;
  }): Promise<{ success: boolean }> {
    return baseApiService.post("/auth/login-reject-signature", data, {
      extras: { useAuth: false },
    });
  }

  async resendEmail(data: { code: string }): Promise<{ success: boolean }> {
    return baseApiService.get("/auth/resend-email", {
      params: data,
      extras: { useAuth: false },
    });
  }
}

export const authService = AuthService.getInstance();
