import { all, call, put, takeLatest } from "redux-saga/effects";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import {
  EMAIL,
  EMAIL_CODE,
  INVITE_ID,
  REFERRAL_CODE,
  webStorageService,
} from "../../services/WebStorageService";
import {
  AUTH_CHECK_VERIFICATION,
  AUTH_LOGIN_EMAIL_RESEND,
  AUTH_LOGIN_HALF,
  AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_SIGNATURE,
} from "../actions/actions.constants";
import {
  authCheckVerificationAction,
  authCheckVerificationCompletedAction,
  authCheckVerificationErrorAction,
  authLoginCompletedAction,
  authLoginEmailResendCompletedAction,
  authLoginEmailResendErrorAction,
  authLoginHalfCompletedAction,
  authLoginHalfEmailSentAction,
  authLoginHalfErrorAction,
  authVerifyEmailCompletedAction,
  authVerifyEmailErrorAction,
  authVerifySignatureErrorAction,
  authWalletAddressRejectSignActionCompleted,
  authWalletAddressRejectSignActionError,
} from "../actions/auth.actions";

import Router from "next/router";
import { getDefaultRoute } from "../../../next-routes/NextRouteConfig";
import {
  LoginPayloadGA,
  SignUpPayloadGA,
} from "../../models/types/GAPayload.types";
import { webToastService } from "../../services/WebToastService";
import { authService } from "../../services/api-services/AuthService";
import { Roles } from "../../utils/Roles.enums";
import Utils from "../../utils/Utils";
import { GAEventName } from "../../utils/enums";

function* loginSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(authService.login, data.payload);
    // If Email Login, BE sends code (identification code)
    if (response.code) {
      webStorageService.setValue(EMAIL_CODE, response.code, {
        maxAge: 60 * 30 * 1000,
      });
      webStorageService.setValue(EMAIL, data.payload.email, {
        maxAge: 60 * 30 * 1000,
      });
      yield put(authLoginHalfEmailSentAction());
    } else {
      yield put(authLoginHalfCompletedAction({ nonce: response.nonce }));
    }
  } catch (e: any) {
    yield put(
      authLoginHalfErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* verifyEmail(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(authService.verifyEmail, data.payload);
    if (response.success) {
      yield put(authVerifyEmailCompletedAction({ success: response.success }));
    }
  } catch (e: any) {
    webStorageService.removeValue(EMAIL_CODE);
    webStorageService.removeValue(EMAIL);
    yield put(
      authVerifyEmailErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* checkVerification(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(authService.checkVerification, data.payload);
    if (response.token) {
      // Once successfully verified
      webStorageService.removeValue(EMAIL_CODE);
      webStorageService.removeValue(EMAIL);
      webStorageService.removeValue(REFERRAL_CODE);
      webStorageService.removeValue(INVITE_ID);
      yield put(
        authLoginCompletedAction({
          data: {
            user: response.hasOwnProperty("user") ? response.user : null,
            admin: response.hasOwnProperty("admin") ? response.admin : null,
          },
          brand: response.brand,
          token: response.token,
        })
      );
      webToastService.showSuccess("Successfully Logged In!🙌");
      webStorageService.setAuthToken(response?.token);
      if (response.hasOwnProperty("user")) {
        if (response.user?.is_new_user) {
          Utils.GAEvent<SignUpPayloadGA>(GAEventName.SIGNUP, {
            event_category: "User",
            event_label: "New User: Email",
            method: "email",
          });
        } else {
          Utils.GAEvent<LoginPayloadGA>(GAEventName.LOGIN, {
            event_category: "User",
            event_label: "Returning User: Email",
            method: "email",
          });
        }
      } else {
        Utils.GAEvent<LoginPayloadGA>(GAEventName.LOGIN, {
          event_category: "Admin",
          event_label: "Admin: Email",
          method: "email",
        });
      }
      Router.push({
        pathname: getDefaultRoute(
          response.hasOwnProperty("user") ? Roles.USER : Roles.ADMIN
        ),
      });
    } else {
      yield put(authCheckVerificationCompletedAction());
    }
  } catch (e: any) {
    const isCodeInvalid = e.response?.data?.code === "30";
    if (!data.payload?.otp || !isCodeInvalid) {
      webStorageService.removeValue(EMAIL_CODE);
      webStorageService.removeValue(EMAIL);
    }
    yield put(
      authCheckVerificationErrorAction(
        isCodeInvalid ? e?.response?.data?.message : e?.message
      )
    );
  }
}

function* verifySignatureSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(authService.verifySignature, data.payload);
    yield put(
      authLoginCompletedAction({
        data: {
          user: response.hasOwnProperty("user") ? response.user : null,
          admin: response.hasOwnProperty("admin") ? response.admin : null,
        },
        brand: response.brand,
        token: response.token,
      })
    );

    webStorageService.removeValue(REFERRAL_CODE);
    webStorageService.removeValue(INVITE_ID);
    webToastService.showSuccess("Successfully Logged In!🙌");
    webStorageService.setAuthToken(response?.token);
    if (response.hasOwnProperty("user")) {
      if (response.user?.is_new_user) {
        Utils.GAEvent<SignUpPayloadGA>(GAEventName.SIGNUP, {
          event_category: "User",
          event_label: "New User: Wallet",
          method: "wallet",
        });
      } else {
        Utils.GAEvent<LoginPayloadGA>(GAEventName.LOGIN, {
          event_category: "User",
          event_label: "Returning User: Wallet",
          method: "wallet",
        });
      }
    } else {
      Utils.GAEvent<LoginPayloadGA>(GAEventName.LOGIN, {
        event_category: "Admin",
        event_label: "Admin: Wallet",
        method: "wallet",
      });
    }
    Router.push({
      pathname: getDefaultRoute(
        response.hasOwnProperty("user") ? Roles.USER : Roles.ADMIN
      ),
    });
  } catch (e: any) {
    yield put(
      authVerifySignatureErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* rejectLoginWalletAddressSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(
      authService.rejectWalletSignature,
      data.payload
    );
    if (response.success) {
      yield put(
        authWalletAddressRejectSignActionCompleted({
          res: {
            success: response.success,
          },
        })
      );
    }
  } catch (e: any) {
    yield put(authWalletAddressRejectSignActionError(e.message.error));
    console.log(e.message.error);
  }
}

function* resendEmailSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(authService.resendEmail, data.payload);
    if (response.success) {
      const emailCode = webStorageService.getValue(EMAIL_CODE);
      const email = webStorageService.getValue(EMAIL);
      // 30 Minutes Expiry
      webStorageService.setValue(EMAIL_CODE, emailCode, {
        maxAge: 60 * 30 * 1000,
      });
      webStorageService.setValue(EMAIL, email, {
        maxAge: 60 * 30 * 1000,
      });
    }
    yield put(
      authLoginEmailResendCompletedAction({
        res: {
          isEmailResent: response.success,
        },
      })
    );
  } catch (e: any) {
    yield put(
      authLoginEmailResendErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(AUTH_LOGIN_HALF, loginSaga),
    takeLatest(AUTH_VERIFY_EMAIL, verifyEmail),
    takeLatest(AUTH_CHECK_VERIFICATION, checkVerification),
    takeLatest(AUTH_VERIFY_SIGNATURE, verifySignatureSaga),
    takeLatest(AUTH_LOGIN_EMAIL_RESEND, resendEmailSaga),
    takeLatest(
      AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN,
      rejectLoginWalletAddressSaga
    ),
  ]);
}

export default authSaga;
