import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import NextRouteConfig, {
  matchPagePathRegex,
} from "../../next-routes/NextRouteConfig";
import CookieConsentModal from "../components/shared-components/Modal/CookieConsentModal";
import BrandPageHeader from "../components/shared-components/PageHeader/BrandPageHeader";
import FullScreenSpinner from "../components/shared-components/Spinner/FullScreenSpinner";
import UserDashboard from "../components/shared-components/user-dashboard/UserDashboard";
import { BrandContext } from "../contexts/BrandContext";
import { useSetColorVariables } from "../hooks/useSetColorVariables";
import { AppState } from "../redux/reducers";
import { BrandState } from "../redux/reducers/brand.reducer";
import { UserState } from "../redux/reducers/user.reducer";
import {
  brandConfigSelector,
  brandSelector,
  userSelector,
} from "../redux/selectors/app.selectors";
import { BrandBackgroundColor, BrandConfigKeys } from "../utils/enums";

interface AppComponentWrapperProps {
  children: any;
}

const AppComponentWrapper: React.FC<AppComponentWrapperProps> = (
  props: AppComponentWrapperProps
) => {
  const areCSSVarsSet = useSetColorVariables();

  const userState: UserState = useSelector((appState: AppState) =>
    userSelector(appState)
  );

  const router = useRouter();

  const {
    brand,
    brand_socials_credentials,
    brand_get_loading,
    configEntities: brandConfig,
  }: BrandState = useSelector(brandSelector);

  const configs = useMemo(
    () => ({
      url: brandConfigSelector(brandConfig, BrandConfigKeys.TWITTER)?.value
        ?.url,
      credits: brandConfigSelector(brandConfig, BrandConfigKeys.TWITTER_CREDITS)
        ?.value,
      brandStyles: brandConfigSelector(brandConfig, BrandConfigKeys.STYLES)
        ?.value,
      brand_logo_url: brandConfigSelector(brandConfig, BrandConfigKeys.IMAGES)
        ?.value?.logo_image_url,
      welcome_heading: brandConfigSelector(brandConfig, BrandConfigKeys.WELCOME)
        ?.value?.heading,
      welcome_subheading: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.WELCOME
      )?.value?.subheading,
      brand_banner_url: brandConfigSelector(brandConfig, BrandConfigKeys.IMAGES)
        ?.value?.banner_image_url,
      is_tier_enable: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.TIERS_CONFIG
      )?.value?.is_enabled,
      button_font_color: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.STYLES
      )?.value?.button_font_color,
      sign_up_credit_points: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.WELCOME_CREDITS
      )?.value?.register,
      favicon_image_url: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.IMAGES
      )?.value?.favicon_image_url,
      shareable_image_url: brandConfigSelector(
        brandConfig,
        BrandConfigKeys.IMAGES
      )?.value?.shareable_image_url,
    }),
    [brandConfig]
  );

  // Don't show NFT Header for admin screens except admin settings
  const noBannerHeader =
    router.pathname.match(
      matchPagePathRegex(NextRouteConfig["terms-and-conditions"])
    ) ||
    router.pathname.match(
      matchPagePathRegex(NextRouteConfig["privacy-policy"])
    ) ||
    (!!userState.adminData &&
      !router.pathname.match(NextRouteConfig.ADMIN.SETTINGS.META.regex));

  const [is404Page, setIs404Page] = useState<boolean>(false);
  const [openCookiePolicy, setOpenCookiePolicy] = useState<boolean>(false);
  const isAuthPage =
    !!router.pathname.match(matchPagePathRegex(NextRouteConfig.login)) ||
    !!router.pathname.match(matchPagePathRegex(NextRouteConfig.logout)) ||
    !!router.pathname.match(
      matchPagePathRegex(NextRouteConfig["gated-entry"])
    ) ||
    !!router.pathname.match(NextRouteConfig.VERIFY_EMAIL.META.regex) ||
    !!router.pathname.match(NextRouteConfig.ALT_LOGIN_VERIFY_EMAIL.META.regex);

  return (
    <>
      <BrandContext.Provider
        value={{
          brandButtonColorStyle: configs.brandStyles?.button_color_style,
          brand_button_color: configs.brandStyles?.button_font_color,
          background_color: configs.brandStyles?.background_color ?? "#000000",
          primary_color: configs.brandStyles?.primary_color,
          secondary_color: configs.brandStyles?.secondary_color,
          currency: brand?.currency,
          primary_font: configs.brandStyles?.primary_font,
          secondary_font: configs.brandStyles?.secondary_font,
          domain: brand?.domain ?? "",
          discord_client_id:
            brand_socials_credentials?.discord?.client_id ?? "",
          twitter_client_id:
            brand_socials_credentials?.twitter?.client_id ?? "",
          instagram_client_id:
            brand_socials_credentials?.instagram?.client_id ?? "",
          tiktok_client_id: brand_socials_credentials?.tiktok?.client_id ?? "",
          youtube_client_id:
            brand_socials_credentials?.youtube?.client_id ?? "",
          linkedin_client_id:
            brand_socials_credentials?.linkedin?.client_id ?? "",
          spotify_client_id:
            brand_socials_credentials?.spotify?.client_id ?? "",
          brand_logo_url: configs.brand_logo_url ?? "/icons/GenCityLab.svg",
          brand_banner_url: configs.brand_banner_url,
          is_tier_enable: configs.is_tier_enable,
          button_font_color: configs.button_font_color,
          sign_up_credit_points: configs.sign_up_credit_points,
          isBrandLoading: brand_get_loading,
          favicon_image_url: configs.favicon_image_url,
          shareable_image_url: configs.shareable_image_url,

          // Operational States
          is404Page,
          setIs404Page,
          openCookiePolicy,
          setOpenCookiePolicy,
        }}
      >
        <div
          className={`h-full overflow-auto relative w-full ${
            configs.brandStyles?.background_color === BrandBackgroundColor.BLACK
              ? "whiteScrollbar"
              : "blackScrollbar"
          }`}
        >
          <CookieConsentModal
            showCookieConsentModal={openCookiePolicy}
            setShowCookieConsentModal={setOpenCookiePolicy}
          />
          {brand && !areCSSVarsSet && <FullScreenSpinner initial />}
          {
            <>
              {!(is404Page || isAuthPage) ? (
                <div className="relative flex flex-col-reverse h-full md:flex-row">
                  <div className="md:h-full md:relative">
                    <UserDashboard />
                  </div>
                  <div className="w-full h-full px-2 pt-2 overflow-auto md:px-7 md:pt-5">
                    {noBannerHeader ? null : <BrandPageHeader />}
                    {/* Subtract atleast the height of nft-header and padding top + margin top (100vh - 100px+20px+20px) */}
                    {/* Also subtract 6rem of bottom navbar in mobile view: (100vh - 100px+8px+16px) */}
                    <div
                      className={`px-2 pb-24 md:pb-5 ${
                        noBannerHeader
                          ? "h-full"
                          : "mt-4 md:mt-5 h-[calc(100%-8rem)] md:h-[calc(100%-8rem)] overflow-auto "
                      }`}
                    >
                      {props.children}
                    </div>
                  </div>
                </div>
              ) : (
                props.children
              )}
            </>
          }
        </div>
      </BrandContext.Provider>
    </>
  );
};

export default AppComponentWrapper;
