import React from "react";

interface Props {
  text: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Badge: React.FC<Props> = ({ text, className, style }) => {
  return (
    <span
      className={`inline-block truncate py-1 px-3 leading-none text-center whitespace-nowrap font-bold align-middle rounded-md ${className}`}
      style={style}
    >
      {text}
    </span>
  );
};

export default Badge;
