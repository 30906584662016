export const cookiePolicyPartOne = `Last Updated: January 2024

1. Introduction

Welcome to Gen City Labs, Inc (“GCL”, “we”, “us”, or “our”). This Cookie Policy explains how we use cookies and similar technologies to provide, customize, and improve our services.

2. What Are Cookies?

Cookies are small text files stored on your device when you visit a website. They serve various purposes such as enhancing user experience, remembering preferences, and providing analytics.

`;

export const cookiePolicyPartTwo = `
4. How We Use Cookies

We use cookies for the following purposes:
- To provide and maintain our services
- To understand how you use the service and make improvements.

5. Third-Party Cookies

We may use third-party services that use cookies to help us provide essential services like logging in.

6. Changes to This Cookie Policy

We reserve the right to update our Cookie Policy. Any changes will be effective upon posting the updated policy on our website.

7. Contact Us

If you have questions or concerns about our Cookie Policy, please contact us at [legal@gencitylabs.com].`;
