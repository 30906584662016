import { omit } from "lodash";
import Quest from "../../models/entities/Quest";
import { QuestAdminConfig } from "../../models/entities/QuestAdminConfigs";
import { Learn2EarnContentRequestPayloadType } from "../../models/entities/quest/lear2earn/Learn2Earn";
import { PollMeta } from "../../models/entities/quest/poll/Poll";
import { ProofMeta } from "../../models/entities/quest/proof/Proof";
import { QuizMetaRequestPayload } from "../../models/entities/quest/quiz/Quiz";
import { SurveyMeta } from "../../models/entities/quest/survey/Survey";
import {
  QUESTS_LIST_GET,
  QUESTS_LIST_GET_COMPLETED,
  QUESTS_LIST_GET_ERROR,
  QUEST_COVER_IMAGE_UPDATE,
  QUEST_COVER_IMAGE_UPDATE_COMPLETED,
  QUEST_COVER_IMAGE_UPDATE_ERROR,
  QUEST_CREATE,
  QUEST_CREATE_COMPLETED,
  QUEST_CREATE_ERROR,
  QUEST_GET,
  QUEST_GET_COMPLETED,
  QUEST_GET_ERROR,
  QUEST_LEAR2EARN_VIDEO_URL_SECTION,
  QUEST_MORE_DETAILS_FORM_SUBMISSION,
  QUEST_NEW_FORM_SUBMISSION,
  QUEST_POLL_QUESTION_SECTION,
  QUEST_PROOF_SECTION,
  QUEST_QUIZ_QUESTIONS_SECTION,
  QUEST_RESET_CURRENT_QUEST_ITEM,
  QUEST_SURVEY_QUESTIONS_SECTION,
  QUEST_UPDATE,
  QUEST_UPDATE_COMPLETED,
  QUEST_UPDATE_ERROR,
  SET_CURRENT_QUEST_ITEM,
} from "./actions.constants";

export const questNewFormSubmissionAction = (payload: {
  category: string;
  cover_image?: File | string;
  req: {
    type: string;
    title: string;
    description: string;
    per_wallet: number;
    total?: number | null;
    is_max_quantity_infinite: boolean;
    max_per_wallet?: number | null;
    is_max_per_wallet_infinite: boolean;
    rules: string;
  };
}) => ({
  type: QUEST_NEW_FORM_SUBMISSION,
  payload,
});

export const questLear2EarnVideoUrlSectionAction = (payload: {
  index: number;
  category: string;
  subType: string;
  isQuestDuplicated?: boolean;
  req: {
    quest_content: Learn2EarnContentRequestPayloadType[];
  };
}) => ({
  type: QUEST_LEAR2EARN_VIDEO_URL_SECTION,
  payload,
});

export const questQuizQuestionsSectionAction = (payload: {
  index: number;
  isQuestDuplicated: boolean;
  category: string;
  req: QuizMetaRequestPayload;
}) => ({
  type: QUEST_QUIZ_QUESTIONS_SECTION,
  payload,
});

export const questPollQuestionSectionAction = (payload: {
  index: number;
  category: string;
  isQuestDuplicated: boolean;
  req: {
    poll: PollMeta;
  };
}) => ({
  type: QUEST_POLL_QUESTION_SECTION,
  payload,
});

export const questSurveyQuestionsSectionAction = (payload: {
  index: number;
  category: string;
  isQuestDuplicated: boolean;
  req: {
    survey: SurveyMeta;
  };
}) => ({
  type: QUEST_SURVEY_QUESTIONS_SECTION,
  payload,
});

export const questProofSectionSubmissionAction = (payload: {
  index: number;
  category: string;
  isQuestDuplicated: boolean;
  req: ProofMeta;
}) => ({
  type: QUEST_PROOF_SECTION,
  payload,
});

export const questMoreDetailsFormSubmissionAction = (payload: {
  category: string;
  req: {
    validationRequired: boolean;
    screenshotRequired: boolean;
    liveAt: string | null;
    endAt: string | null;
    repeat: string;
    tags: string;
  };
}) => ({
  type: QUEST_MORE_DETAILS_FORM_SUBMISSION,
  payload,
});

export const questRestCurrentItem = () => ({
  type: QUEST_RESET_CURRENT_QUEST_ITEM,
});

export const createNewQuestAction = (payload: {
  cover_image?: File | string;
  req: {
    brand_id: number;
    type: string;
    title: string;
    description: string;
    namespace?: string;
    total: number | null;
    per_wallet: number;
    max_per_wallet: number | null;
    rules: string;
    meta: any;
    config: QuestAdminConfig | null;
    status: string;
  };
}) => ({
  type: QUEST_CREATE,
  payload,
});
export const createNewQuestCompletedAction = (payload: {
  res: {
    quest: any;
  };
}) => ({
  type: QUEST_CREATE_COMPLETED,
  payload,
});
export const createNewQuestErrorAction = (payload: { message: string }) => ({
  type: QUEST_CREATE_ERROR,
  payload,
});

export const questsListGetAction = (payload?: {
  req: {
    page: number;
    query: {
      filter?: string[];
      sort_by?: string;
      search?: string | null;
      category?: string[];
    };
  };
}) => ({
  type: QUESTS_LIST_GET,
  payload,
});
export const questsListGetCompletedAction = (payload?: {
  res: {
    quests: Quest[];
    meta: any;
  };
}) => ({
  type: QUESTS_LIST_GET_COMPLETED,
  payload,
});
export const questsListGetErrorAction = (payload: { message: string }) => ({
  type: QUESTS_LIST_GET_ERROR,
  payload,
});

export const questSetCurrentQuestItemAction = (quest: Quest) => {
  return {
    type: SET_CURRENT_QUEST_ITEM,
    payload: {
      [quest.type]: {
        initial_form_data: omit(quest, "meta", "config"),
        sections: { 0: { ...quest.meta } },
        more_details: { ...quest.config },
      },
    },
  };
};

export const questUpdateAction = (payload: {
  req: {
    id: number;
    type?: string;
    title?: string;
    description?: string;
    namespace?: string;
    total?: number | null;
    per_wallet?: number;
    max_per_wallet?: number | null;
    rules?: string;
    meta?: any;
    config?: QuestAdminConfig | null;
    status?: string;
  };
}) => ({
  type: QUEST_UPDATE,
  payload,
});
export const questUpdateCompletedAction = (payload: {
  res: {
    quest: Quest;
  };
}) => ({
  type: QUEST_UPDATE_COMPLETED,
  payload,
});
export const questUpdateErrorAction = (payload: { message: string }) => ({
  type: QUEST_UPDATE_ERROR,
  payload,
});

export const questCoverImageUpdateAction = (payload: {
  req: {
    id: number;
    cover_image: File | string;
  };
}) => ({
  type: QUEST_COVER_IMAGE_UPDATE,
  payload,
});
export const questCoverImageUpdateCompletedAction = (payload: {
  res: {
    quest: Quest;
  };
}) => ({
  type: QUEST_COVER_IMAGE_UPDATE_COMPLETED,
  payload,
});
export const questCoverImageUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: QUEST_COVER_IMAGE_UPDATE_ERROR,
  payload,
});

export const questGetAction = (payload: {
  req: {
    id: number;
  };
}) => ({ type: QUEST_GET, payload });

export const questGetCompletedAction = (payload: {
  res: {
    quest: Quest;
  };
}) => ({ type: QUEST_GET_COMPLETED, payload });

export const questGetErrorAction = (payload: { message: string }) => ({
  type: QUEST_GET_ERROR,
  payload,
});
