import React, { FunctionComponent } from "react";

export interface Props {
  type?: "error" | "helper";
  text?: string;
}

const InputHelper: FunctionComponent<Props> = ({ text, type }) => (
  <div className="relative w-full">
    {text ? (
      <p
        className={`absolute translate-y-1 pr-2 w-full text-right text-xs ${
          type === "error" ? "text-brandError" : "text-primaryText/50"
        }`}
        id="input-error"
      >
        {text}
      </p>
    ) : null}
  </div>
);

InputHelper.defaultProps = {
  type: "helper",
};

export default React.memo(InputHelper);
