import { baseApiService } from "./BaseApiService";

class AnalyticsServices {
  static getInstance(): AnalyticsServices {
    return new AnalyticsServices();
  }

  async get(): Promise<{ stats: any }> {
    const response: any = await baseApiService.get("/admin/analytics");
    return { stats: response.data };
  }

  async safetyCheckLink(req: { link: string }) {
    const response: any = await baseApiService.get(
      `/user-quests/safety-check?link=${req.link}`
    );
    return { status: response?.threatTypes };
  }
}

export const analyticsServices = AnalyticsServices.getInstance();
