import classNames from "classnames";
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import LogoutIcon from "../../../../../public/icons/logout-icon";
import { UserState } from "../../../../redux/reducers/user.reducer";
import { userSelector } from "../../../../redux/selectors/app.selectors";
import Utils from "../../../../utils/Utils";
import BrandTooltip from "../../BrandTooltip";
import CustomBrandTooltip from "../../CustomBrandTooltip";
import Dropdown, {
  NextJSHeadlessLink,
} from "../../custom-components/menu/Dropdown";
import { DashboardItemsType } from "../UserDashboard";

interface UserDashboardMobileViewProps {
  navBarItems: DashboardItemsType[];
  selectedMenu: string;
  collapsed: boolean;
  brandUserDashBoardConfigs: {
    tiers: any;
    tires_value: any;
    suggestion_email: any;
  };
  setIsDisconnectedModalOpen: Function;
  setIsSupportModalOpen: Function;
  isUser: boolean;
  setModalType: Function;
}

const UserDashboardMobileView: React.FC<UserDashboardMobileViewProps> = ({
  navBarItems,
  selectedMenu,
  collapsed,
  brandUserDashBoardConfigs,
  setIsSupportModalOpen,
  setIsDisconnectedModalOpen,
  isUser,
  setModalType,
}) => {
  const { adminData, userData }: UserState = useSelector(userSelector);
  const filteredItems = [...navBarItems].filter((i) => !i.isHidden);
  return (
    <div className="fixed inset-x-0 bottom-0 z-[51] md:hidden h-[6rem] rounded-t-[1.25rem] bg-dashboard shadow-primaryCardShadow">
      {/* Navbar Items */}
      <div
        id="mobile_menu"
        className="flex items-center h-full px-4 justify-evenly"
      >
        {filteredItems
          .sort((a, b) => (a?.id || 0) - (b?.id || 0))
          .map((item: DashboardItemsType, index) => {
            const isMiddle = index === Math.floor(filteredItems.length / 2);
            const linkOrButtonChild = (
              <BrandTooltip
                className="z-[60]"
                tooltip={item.label}
                place="top"
                id={`${item.value}_mobile_menu_tooltip`}
              >
                <span
                  className={`relative p-[10px] sm:p-3 transition-colors duration-300 rounded-full ${
                    !isMiddle && item.regex?.test(selectedMenu || "")
                      ? "drop-shadow-dashboardSelected gradient-button text-textButton"
                      : ""
                  } `}
                >
                  {item.icon}
                </span>
              </BrandTooltip>
            );

            // if Link
            if (item.defaultRoutePath) {
              return (
                <div
                  key={`${item.value}_mobile`}
                  className={
                    isMiddle
                      ? `-top-[45%] transition-all p-1 inline-block relative rounded-full drop-shadow-dashboardSelected ${
                          item.regex?.test(selectedMenu || "")
                            ? "gradient-button"
                            : "bg-primaryText text-primaryBackground"
                        }`
                      : ""
                  }
                >
                  {!item.openInNewTab ? (
                    <Link
                      href={item.defaultRoutePath}
                      legacyBehavior
                      onClick={
                        item.isDisabled
                          ? (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          : undefined
                      }
                    >
                      <a
                        className={`flex space-x-5 items-center w-full aspect-square !justify-start p-1 ${
                          item.isDisabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onClick={
                          item.isDisabled
                            ? (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            : undefined
                        }
                      >
                        {linkOrButtonChild}
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={item.defaultRoutePath}
                      className={`flex space-x-5 items-center w-full aspect-square !justify-start p-1 ${
                        item.isDisabled ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={
                        item.isDisabled
                          ? (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          : undefined
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {linkOrButtonChild}
                    </a>
                  )}
                </div>
              );
            } else if (!item.openDropdown && item.onClick) {
              <button
                onClick={item.onClick}
                className={`flex space-x-5 items-center w-full aspect-square !justify-start p-1 ${
                  item.isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={item.isDisabled}
              >
                {linkOrButtonChild}
              </button>;
            } else if (
              item.openDropdown &&
              item.dropdownItems &&
              item.dropdownItems?.length > 0
            ) {
              return (
                <div key={`${item.value}_mobile`}>
                  <Dropdown
                    menuItemsClassName={`bottom-[6rem] right-0 rounded-[1.25rem] w-44 text-xs lg:text-sm font-medium grid grid-cols-1 shadow-primaryCardShadow`}
                    disabled={item.isDisabled}
                    items={[
                      ...item.dropdownItems
                        .filter((i) => !i.isHidden)
                        .map((dropItem) => {
                          const labelChild = (
                            <>
                              {dropItem.icon && (
                                <span
                                  className={`p-2 rounded-full ${
                                    dropItem.regex?.test(selectedMenu || "")
                                      ? "drop-shadow-dashboardSelected gradient-button text-textButton"
                                      : ""
                                  } `}
                                >
                                  {dropItem.icon}
                                </span>
                              )}
                              <span className={`py-2 font-semibold`}>
                                {dropItem.label}
                              </span>
                            </>
                          );
                          return dropItem.defaultRoutePath ? (
                            // If Link
                            !dropItem.openInNewTab ? (
                              <NextJSHeadlessLink
                                onClick={
                                  dropItem.isDisabled
                                    ? (e: any) => {
                                        // If link is disabled
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    : undefined
                                }
                                href={dropItem.defaultRoutePath}
                                key={dropItem.value}
                                className={classNames(
                                  "flex space-x-2 items-center w-full !justify-start px-4 py-2",
                                  {
                                    "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                      dropItem.isDisabled,
                                  }
                                )}
                              >
                                {labelChild}
                              </NextJSHeadlessLink>
                            ) : (
                              <a
                                href={dropItem.defaultRoutePath}
                                key={dropItem.value}
                                target="_blank"
                                rel="noreferrer noopener"
                                onClick={
                                  dropItem.isDisabled
                                    ? (e: any) => {
                                        // If link is disabled
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    : undefined
                                }
                                className={classNames(
                                  "flex space-x-2 items-center w-full !justify-start px-4 py-2",
                                  {
                                    "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                      dropItem.isDisabled,
                                  }
                                )}
                              >
                                {labelChild}
                              </a>
                            )
                          ) : (
                            <button
                              disabled={dropItem.isDisabled}
                              onClick={dropItem.onClick}
                              className={classNames(
                                "flex space-x-2 items-center w-full !justify-start px-4 py-2",
                                {
                                  "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                    dropItem.isDisabled,
                                }
                              )}
                            >
                              {labelChild}
                            </button>
                          );
                        }),
                      ...(userData || adminData
                        ? [
                            <div
                              key={"logout_more_menu_mobile"}
                              className="flex flex-col py-3 space-y-1 !text-sm cursor-pointer transition-colors duration-300"
                              onClick={() => {
                                setIsDisconnectedModalOpen(true);
                              }}
                            >
                              <div className="flex items-center justify-center space-x-2 ">
                                <span>
                                  <LogoutIcon className="w-5 h-5 opacity-60" />
                                </span>
                                <span className="whitespace-nowrap opacity-60">
                                  Log Out
                                </span>
                              </div>
                              {adminData && (
                                <p
                                  key="account_menu"
                                  className="px-5 text-xs font-light text-center opacity-50"
                                >
                                  [
                                  {adminData?.wallet_address
                                    ? Utils.getEllipsisedAddress(
                                        adminData?.wallet_address
                                      )
                                    : Utils.getEllipsisedEmail(
                                        adminData?.email!
                                      )}
                                  ]
                                </p>
                              )}
                            </div>,
                          ]
                        : []),
                    ]}
                  >
                    {/* Dropdown Button */}
                    <div
                      key={`${item.value}_mobile`}
                      className={`rounded-none rounded-r-full transition-colors duration-300 `}
                    >
                      <span className="flex space-x-5 items-center !justify-start p-1">
                        <span
                          id={`${item.value}_mobile_menu_tooltip`}
                          className="p-3 rounded-full"
                        >
                          {item.icon}
                        </span>
                      </span>
                    </div>
                  </Dropdown>
                  <CustomBrandTooltip
                    tooltip={item.label}
                    id={`${item.value}_mobile_menu_tooltip`}
                    place="top"
                  />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default React.memo(UserDashboardMobileView);
