import { Transition } from "@headlessui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { SignIn } from "phosphor-react";
import React from "react";
import NextRouteConfig from "../../../../../next-routes/NextRouteConfig";
import LogoutIcon from "../../../../../public/icons/logout-icon";
import { BrandContext } from "../../../../contexts/BrandContext";
import { Brand } from "../../../../models/entities/Brand";
import { ButtonClickPayloadGA } from "../../../../models/types/GAPayload.types";
import Utils from "../../../../utils/Utils";
import { GAEventName } from "../../../../utils/enums";
import BrandTooltip from "../../BrandTooltip";

interface UserDashboardLogoutSectionProps {
  collapsed: boolean;
  isAdmin: boolean;
  isUser: boolean;
  setIsDisconnectedModalOpen: Function;
  brand: Brand;
}

const UserDashboardLogoutSection: React.FC<UserDashboardLogoutSectionProps> = ({
  collapsed,
  isAdmin,
  isUser,
  brand,
  setIsDisconnectedModalOpen,
}) => {
  const router = useRouter();
  const brandValue = React.useContext(BrandContext);

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4 space-y-4">
      {isUser || isAdmin ? (
        <div className="h-16 space-y-2 text-center">
          <div
            className="!text-sm flex items-center justify-center space-x-2 cursor-pointer transition-colors duration-300"
            onClick={() => {
              setIsDisconnectedModalOpen(true);
              Utils.GAEvent<ButtonClickPayloadGA>(GAEventName.BUTTON_CLICK, {
                event_category: "Button",
                event_label: "Log Out",
              });
            }}
          >
            <BrandTooltip
              tooltip={"Logout"}
              id={collapsed ? `logout_menu_tooltip` : undefined}
            >
              <span>
                <LogoutIcon className="w-5 h-5 opacity-60" />
              </span>
            </BrandTooltip>
            {!collapsed && (
              <span className="whitespace-nowrap opacity-60">Log Out</span>
            )}
          </div>
          <Transition
            show={!collapsed}
            as="div"
            className={"flex flex-col items-center space-y-2"}
            enterFrom="scale-0"
            enter="transition-all duration-500"
            enterTo="scale-100"
            leaveFrom="scale-100"
            leaveTo="scale-0"
          >
            <p className="flex flex-col space-x-1 text-xs opacity-30">
              {brand?.privacy_policy_url && (
                <a
                  className="whitespace-nowrap"
                  href={`${brand.privacy_policy_url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              )}
              {brand?.terms_and_condition_url && (
                <a
                  className="whitespace-nowrap"
                  href={brand.terms_and_condition_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              )}
            </p>
          </Transition>
        </div>
      ) : (
        <Transition
          show={!collapsed}
          as="div"
          className={"flex flex-col items-center pb-3"}
          enterFrom="scale-0"
          enter="transition-all duration-500"
          enterTo="scale-100"
          leaveFrom="scale-100"
          leaveTo="scale-0"
        >
          <>
            <div className="mt-12 mb-5 text-center">
              <div className="text-lg font-semibold md:text-2xl">
                Get{" "}
                {`${brandValue.sign_up_credit_points} ${
                  brandValue.currency ?? "Points"
                }`}
              </div>
              <div className="text-sm">When you sign up!</div>
            </div>
            <div
              className="!text-sm flex items-center justify-center space-x-2 cursor-pointer transition-colors duration-300 mb-10"
              onClick={() => {
                router.push(NextRouteConfig.login);
              }}
            >
              {!collapsed && (
                <span className="whitespace-nowrap opacity-60">Log In</span>
              )}
              <BrandTooltip
                tooltip={"Logout"}
                id={collapsed ? `logout_menu_tooltip` : undefined}
              >
                <span>
                  <SignIn weight="bold" className="w-6 h-6 opacity-60" />
                </span>
              </BrandTooltip>
            </div>
            <p className="px-10 text-center">
              Are You Interested In Offering Your Community A New To Engage And
              Earn Rewards?
            </p>
            <Link href="/" legacyBehavior>
              <a className="px-4 py-3 font-bold underline transition-colors duration-300 rounded-full">
                Get In Touch
              </a>
            </Link>
          </>
        </Transition>
      )}
    </div>
  );
};

export default React.memo(UserDashboardLogoutSection);
