import classNames from "classnames";
import React from "react";

interface AccordionProps {
  children: React.ReactNode;
  isExpanded: boolean;
}

const ExternallyControlledAccordion: React.FC<AccordionProps> = ({
  children,
  isExpanded,
}) => {
  return (
    <div
      className={classNames(
        "transition-all ease-in-out relative text-primaryText origin-top flex flex-col select-none w-full text-left opacity-75 h-full duration-500",
        isExpanded
          ? "max-h-[15rem] md:max-h-[19rem]"
          : "max-h-0 overflow-hidden"
      )}
    >
      <div className="p-6 overflow-auto text-xs border rounded-md sm:text-sm bg-primaryBackground/40 border-primaryText/10">
        {children}
      </div>
    </div>
  );
};

export default React.memo(ExternallyControlledAccordion);
