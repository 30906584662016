import { BrandMetadata } from "../../models/entities/Brand";
import { Item } from "../../models/entities/Item";
import { UserItem } from "../../models/entities/UserItem";
import { SortBy } from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class ItemService {
  static getInstance(): ItemService {
    return new ItemService();
  }

  async get(payload: { id: number }): Promise<{ item: Item }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("items/" + payload.id, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    let item: Item = {
      ...response.data,
      purchased_quantity: response.purchased_quantity,
    };
    return { item };
  }

  async metadata(payload: {
    id: number;
  }): Promise<{ metadata: BrandMetadata }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("items/metadata/" + payload.id, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { metadata: (response as any).data };
  }

  async listItemStats(payload: {
    id: number;
    page: number;
    query: { search?: string; filter?: any[]; sort_by?: string };
  }): Promise<{ item_stats: UserItem[]; meta?: any }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get(
      "items/" + payload.id + "/stats",
      {
        params: {
          page: payload.page.toString(),
          search: payload.query.search,
          filters: payload.query.filter,
          sort_by: payload.query.sort_by,
        },
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { item_stats: response.data, meta: response.meta };
  }

  async downloadItemStats(payload: {
    id: number;
    page: number;
    query: { search?: string };
  }): Promise<{ url: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get(
      "items/" + payload.id + "/stats/download",
      {
        params: {
          search: payload.query.search,
        },
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      url: response.data,
    };
  }

  async create(
    payload: Omit<Omit<Item, "image_url">, "quantity_used">
  ): Promise<{ item: Item; signedUrl: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("items/", payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    let item: Item = response.data;
    return { item, signedUrl: response.signed_url };
  }

  async editImage(id: number): Promise<{ item: Item; signedUrl: string }> {
    const response = await baseApiService.put(`items/${id}/image`);
    return { item: response.data, signedUrl: response.signed_url };
  }

  async edit(id: number, payload: any): Promise<{ item: Item }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(`items/${id}`, payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    let item: Item = response.data;
    return { item };
  }

  async list(payload: {
    end_point: string;
    page: number;
    query: {
      search?: string[];
      sortBy?: SortBy;
      filters?: string[];
      category?: string[];
    };
  }): Promise<{ items: Item[]; meta?: any }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get(payload.end_point, {
      params: {
        page: payload.page.toString(),
        search: payload.query.search || [],
        sort_by: payload.query.sortBy || "",
        filters: payload.query.filters || [],
        category: payload.query.category || [],
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { items: response.data, meta: response.meta };
  }

  async listOwned(payload: {
    wallet_address?: string;
    req: {
      page: number;
      query: { search?: string; sortBy?: SortBy; categories?: string[] };
    };
  }): Promise<{ userItems: UserItem[]; meta?: any }> {
    const otherParams = {
      page: payload.req.page as unknown as string,
      search: payload.req.query.search || "",
      sort_by: payload.req.query.sortBy || "",
      category: payload.req.query.categories || [],
    };
    const response = await baseApiService.get("/user-items", {
      params: payload.wallet_address
        ? {
            wallet_address: payload.wallet_address,
            ...otherParams,
          }
        : {
            ...otherParams,
          },
    });
    return { userItems: response.user_items, meta: response.meta };
  }

  async redeem(
    id: number,
    payload: {
      email?: string;
      quantity?: number;
      discord?: string;
    }
  ): Promise<{ userItem: UserItem }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post(
      `/user-items`,
      { ...payload, item_id: id },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    let userItem: UserItem = response.user_item;
    return { userItem };
  }

  async downloadItemReport(): Promise<{ url: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/items/on-contract/report", {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      url: response.data.url,
    };
  }
}

export const itemService = ItemService.getInstance();
