import React from "react";
import { PlacesType } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CustomBrandTooltip from "./CustomBrandTooltip";

interface TooltipProps {
  id: string | undefined;
  children: React.ReactElement;
  place?: PlacesType;
  tooltip: React.ReactNode;
  className?: string;
  tooltipClassnames?: string;
}
const BrandTooltip: React.FC<TooltipProps> = (props) => {
  const { children, place, tooltip, id, className } = props;
  return (
    <div className={`flex ${className}`}>
      {children &&
        React.cloneElement(children, {
          "data-tip": true,
          "data-for": id,
          role: "tooltip",
          id: id,
        })}
      {id && (
        <CustomBrandTooltip
          tooltipClassnames={props.tooltipClassnames}
          id={id}
          tooltip={tooltip}
          place={place}
        />
      )}
    </div>
  );
};

export default React.memo(BrandTooltip);
