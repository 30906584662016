import classNames from "classnames";
import { Upload } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { BrandContext } from "../../../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../../../utils/enums";
import TopRoundedSpinner from "../../Spinner/TopRoundedSpinner";
import InputLabel from "../../input/InputLabel/InputLabel";
import InputHelper from "../../input/Inputhelper/InputHelper";
import ImageWrapper from "../image-wrapper/ImageWrapper";

export enum FilePickerInputType {
  profileImage = "image",
}

interface Props {
  id: string;
  label?: React.ReactNode;
  isUploading?: boolean;
  url: any;
  height: number;
  width: number;
  onChannelChange: (e: React.FormEvent<HTMLInputElement>) => void;
  buttonElement: React.ReactNode;
  imageClassName?: string;
  acceptFile?: string;
  onHoverElement?: React.ReactNode;
  imageAlt?: string;
  labelClassName?: string;
  className?: string;
  imageLoading?: boolean;
  disabled?: boolean;
  error?: string;
  videoClassNames?: string;
  helperText?: string;
  videoId?: string;
  isAbsoluteVideoField?: boolean; // for fields that are only meant for videos
  imageParentClassnames?:string
}

const FilePicker: React.FC<Props> = ({
  id,
  label,
  isUploading,
  url,
  height,
  width,
  onChannelChange,
  buttonElement,
  imageClassName,
  acceptFile,
  onHoverElement,
  imageAlt,
  labelClassName,
  className,
  imageLoading,
  error,
  helperText,
  videoClassNames,
  disabled,
  videoId,
  isAbsoluteVideoField,
  imageParentClassnames
}) => {
  const brandValue = React.useContext(BrandContext);
  const [fileType, setFileType] = useState<any>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState<any>(false);

  useEffect(() => {
    if (isAbsoluteVideoField) {
      setFileType("video");
    }
  }, [isAbsoluteVideoField]);

  return (
    <div className="flex flex-col items-start w-full">
      {label ? (
        <InputLabel
          htmlFor={"image"}
          label={label}
          className={`${disabled ? "text-primaryText/75" : ""} ${
            error ? "text-brandError" : ""
          } ${labelClassName}`}
        />
      ) : null}
      <div
        className={`flex mt-2 flex-col overflow-hidden w-full ${className} `}
      >
        <div className="relative w-full h-full">
          <div
            tabIndex={0}
            // @ts-ignore
            shape="round"
            className={classNames(
              "absolute inset-0 z-10 flex flex-col items-center justify-center p-1 overflow-hidden border-none cursor-pointer",
              {
                "opacity-50 cursor-not-allowed": disabled,
              }
            )}
          >
            <input
              id={id}
              accept={acceptFile ?? "image/*"}
              type="file"
              autoComplete="off"
              tabIndex={-1}
              onChange={(e: any) => {
                onChannelChange(e);
                if (e?.target.files?.length === 1) {
                  setFileType(e?.target.files[0]?.type);
                }
              }}
              onClick={(event: any) => {
                if (event.target?.value) {
                  event.target.value = null;
                }
              }}
              disabled={disabled}
              style={{ display: "none" }}
            />
            <label htmlFor={id}>
              <div
                aria-hidden="true"
                className={classNames(
                  "absolute inset-0 z-50 w-full transition-opacity duration-300 overflow-hidden opacity-0 cursor-pointer rounded-2xl backdrop-blur-sm text-textButton hover:opacity-100",
                  {
                    "opacity-50 cursor-not-allowed hover:opacity-0": disabled,
                  },
                  brandValue.brandButtonColorStyle ===
                    BrandButtonColorStyle.GRADIENT
                    ? "gradient-button-translucent"
                    : "bg-primaryButton/50"
                )}
              >
                <div
                  aria-hidden="true"
                  className="absolute z-50 flex items-center justify-center w-full h-full duration-75 ease-in-out delay-75 rounded-2xl backdrop-blur-sm"
                >
                  {onHoverElement}
                  <Upload className="w-10 h-10" />
                </div>
                {isUploading && (
                  <div
                    aria-hidden="true"
                    className="absolute z-50 flex items-center justify-center w-full h-full"
                  >
                    <TopRoundedSpinner />
                  </div>
                )}
              </div>
            </label>
          </div>
          <div
            // style={{ backgroundImage: `url(${url})` }}
            className={classNames(
              "relative flex items-center w-full h-full  overflow-hidden bg-center bg-no-repeat bg-cover rounded-2xl",
              {
                "opacity-50 cursor-not-allowed": disabled,
              }
            )}
          >
            {(fileType ?? "")?.includes("video") ? (
              url ? (
                <div className="w-full h-full !shrink-0">
                  {!isVideoLoaded ? (
                    <div className="absolute flex items-center justify-center w-full h-full">
                      <TopRoundedSpinner size="xs" />
                    </div>
                  ) : null}
                  <video
                    src={url}
                    id={videoId}
                    height={height}
                    width={width}
                    className={`w-full h-full object-cover flex justify-self-stretch ${videoClassNames} `}
                    muted
                    loop
                    autoPlay
                    onLoadedData={() => {
                      setIsVideoLoaded(true);
                    }}
                  />
                </div>
              ) : (
                buttonElement
              )
            ) : url ? (
              <ImageWrapper
                loading={imageLoading}
                alt={imageAlt}
                className={classNames(
                  "rounded-2xl object-bottom object-cover",
                  imageClassName
                )}
                parentClassName={imageParentClassnames}
                width={width}
                height={height}
                url={url}
              />
            ) : (
              buttonElement
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <InputHelper type={"error"} text={error} />
      </div>
    </div>
  );
};

export default React.memo(FilePicker);
