import {
  ADMIN_ANALYTICS_STATS_GET,
  ADMIN_ANALYTICS_STATS_GET_COMPLETED,
  ADMIN_ANALYTICS_STATS_GET_ERROR,
  SAFETY_CHECK_LINK,
  SAFETY_CHECK_LINK_COMPETED,
  SAFETY_CHECK_LINK_ERROR,
} from "./actions.constants";

export const adminGetAnalyticsStatsAction = ({}) => ({
  type: ADMIN_ANALYTICS_STATS_GET,
});

export const adminGetAnalyticsStatsCompletedAction = (payload: {
  res: { stats: any };
}) => ({
  type: ADMIN_ANALYTICS_STATS_GET_COMPLETED,
  payload,
});

export const adminGetAnalyticsStatsErrorAction = (payload: {
  message: string;
}) => ({
  type: ADMIN_ANALYTICS_STATS_GET_ERROR,
  payload,
});

export const safetyCheckLinkAction = (payload: {
  req: {
    link: string;
  };
}) => ({
  type: SAFETY_CHECK_LINK,
  payload,
});
export const safetyCheckLinkCompleteAction = (payload: {
  res: {
    link: string;
    status: string;
  };
}) => ({
  type: SAFETY_CHECK_LINK_COMPETED,
  payload,
});
export const safetyCheckLinkErrorAction = (payload: { message: string }) => ({
  type: SAFETY_CHECK_LINK_ERROR,
  payload,
});
