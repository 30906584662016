import { Provider } from "@ethersproject/abstract-provider";
import { Admin } from "../../models/entities/Admin";
import { Brand } from "../../models/entities/Brand";
import { User } from "../../models/entities/User";
import { AdminMembersFiltersKey, TransferType } from "../../utils/enums";
import {
  ADD_ALTERNATE_LOGIN_CODE_RESET,
  ADD_ALTERNATE_LOGIN_COMPLETED,
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL,
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_COMPLETED,
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_ERROR,
  ADD_EMAIL,
  ADD_EMAIL_CHECK_VERIFICATION,
  ADD_EMAIL_CHECK_VERIFICATION_ERROR,
  ADD_EMAIL_COMPLETED,
  ADD_EMAIL_ERROR,
  ADD_WALLET_ADDRESS,
  ADD_WALLET_ADDRESS_COMPLETED,
  ADD_WALLET_ADDRESS_ERROR,
  ADD_WALLET_ADDRESS_REJECT_SIGN,
  ADD_WALLET_ADDRESS_REJECT_SIGN_COMPLETED,
  ADD_WALLET_ADDRESS_REJECT_SIGN_ERROR,
  ADD_WALLET_ADDRESS_VERIFY_SIGN,
  ADD_WALLET_ADDRESS_VERIFY_SIGN_ERROR,
  ADMIN_IS_QUEST_APPROVAL_PENDING,
  GET_USER_REFERRAL_CODE,
  GET_USER_REFERRAL_CODE_COMPLETED,
  GET_USER_REFERRAL_CODE_ERROR,
  MEMBERS_STATS_DOWNLOAD,
  MEMBERS_STATS_DOWNLOAD_COMPLETED,
  MEMBERS_STATS_DOWNLOAD_ERROR,
  SOCIAL_LOGIN,
  USERS_LIST_GET,
  USERS_LIST_GET_COMPLETED,
  USERS_LIST_GET_ERROR,
  USER_ALTERNATE_EMAIL_RESEND,
  USER_ALTERNATE_EMAIL_RESEND_COMPLETED,
  USER_ALTERNATE_EMAIL_RESEND_ERROR,
  USER_ALTERNATE_EMAIL_RESEND_RESET,
  USER_ALTERNATE_WALLET_LOGIN,
  USER_AWARD_POINTS,
  USER_AWARD_POINTS_COMPLETED,
  USER_AWARD_POINTS_ERROR,
  USER_DEAUTHORIZE_COMPLETED,
  USER_DEAUTHORIZE_ERROR,
  USER_DISCONNECT,
  USER_ERROR_RESET,
  USER_FETCH_ME,
  USER_FETCH_ME_COMPLETED,
  USER_FETCH_ME_ERROR,
  USER_GET,
  USER_GET_COMPLETED,
  USER_GET_ERROR,
  USER_LEADERBOARD_LIST_GET,
  USER_LEADERBOARD_LIST_GET_COMPLETED,
  USER_LEADERBOARD_LIST_GET_ERROR,
  USER_LIST_RESET,
  USER_PROFILE_IMAGE_UPDATE,
  USER_PROFILE_IMAGE_UPDATE_COMPLETED,
  USER_PROFILE_IMAGE_UPDATE_ERROR,
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_COMPLETED,
  USER_PROFILE_UPDATE_ERROR,
  USER_DEAUTHORIZE as USER_REAUTHORIZE,
  USER_REVOKE_POINTS,
  USER_REVOKE_POINTS_COMPLETED,
  USER_REVOKE_POINTS_ERROR,
  USER_SOCIAL_CONNECT,
  USER_SOCIAL_CONNECT__CANCELED,
  USER_SUGGESTION_OR_SUPPORT_SUBMISSION_RESET,
  USER_SUGGESTION_SUBMISSION,
  USER_SUGGESTION_SUBMISSION_COMPLETED,
  USER_SUGGESTION_SUBMISSION_ERROR,
  USER_SUGGESTION_SUBMISSION_ERROR_RESET,
  USER_TRANSFER_LORE,
  USER_TRANSFER_LORE_COMPLETED,
  USER_TRANSFER_LORE_ERROR,
  USER_TWITTER_PROFILE_CONFIRMATION_MODAL,
  USER_TWITTER_PROFILE_CONFIRMATION_MODAL_CANCEL,
  USER_UPDATE,
  USER_UPDATE_COMPLETED,
  USER_UPDATE_ERROR,
} from "./actions.constants";

export const userFetchMeAction = () => ({ type: USER_FETCH_ME });

export const userFetchMeCompletedAction = (data: {
  data: {
    user: User | null;
    admin: Admin | null;
  };
  brand: Brand;
}) => ({ type: USER_FETCH_ME_COMPLETED, data });

export const userFetchMeErrorAction = (message: string) => ({
  type: USER_FETCH_ME_ERROR,
  payload: message,
});

export const userTransferLoreAction = (payload: {
  amount: number;
  wallet_address: string;
  transfer_type: TransferType;
}) => ({ type: USER_TRANSFER_LORE, payload });

export const userTransferLoreCompletedAction = () => ({
  type: USER_TRANSFER_LORE_COMPLETED,
});

export const userTransferLoreErrorAction = (message: string) => ({
  type: USER_TRANSFER_LORE_ERROR,
  payload: message,
});

export const userDisconnectAction = () => ({ type: USER_DISCONNECT });

export const socialLoginAction = (payload: {
  code: any;
  provider: string;
  redirect_uri: string;
}) => ({ type: SOCIAL_LOGIN, payload });

export const userProfileUpdateAction = (payload: {
  req: { username: string };
}) => ({
  type: USER_PROFILE_UPDATE,
  payload,
});

export const userProfileUpdateCompletedAction = (payload: {
  res: { user: User };
}) => ({
  type: USER_PROFILE_UPDATE_COMPLETED,
  payload,
});

export const userProfileUpdateErrorAction = (payload: { message: string }) => ({
  type: USER_PROFILE_UPDATE_ERROR,
  payload,
});

export const userProfileImageUpdateAction = (payload: {
  req: {
    profile_image_object_url: string;
  };
}) => ({
  type: USER_PROFILE_IMAGE_UPDATE,
  payload,
});

export const userProfileImageUpdateCompletedAction = (payload: {
  res: {
    user: User;
  };
}) => ({
  type: USER_PROFILE_IMAGE_UPDATE_COMPLETED,
  payload,
});

export const userProfileImageUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: USER_PROFILE_IMAGE_UPDATE_ERROR,
  payload,
});

export const userDeauthorizeAction = (payload: {
  req: {
    id: number;
    provider: string;
  };
}) => ({
  type: USER_REAUTHORIZE,
  payload,
});

export const userDeauthorizeCompletedAction = (payload: {
  res: { user: User };
}) => ({
  type: USER_DEAUTHORIZE_COMPLETED,
  payload,
});
export const userDeauthorizeErrorAction = (payload: { message: string }) => ({
  type: USER_DEAUTHORIZE_ERROR,
  payload,
});

export const usersListGetAction = (payload: {
  req?: {
    page?: number;
    search?: string;
    filters?: {
      [AdminMembersFiltersKey.TIERS]: Array<string>;
      [AdminMembersFiltersKey.PROVIDERS]: Array<string>;
    };
    sort_by?: string;
  };
}) => ({
  type: USERS_LIST_GET,
  payload,
});

export const usersListGetCompletedAction = (payload: {
  res: { users: User[]; meta: any };
}) => ({
  type: USERS_LIST_GET_COMPLETED,
  payload,
});

export const usersListGetErrorAction = (payload: { message: string }) => ({
  type: USERS_LIST_GET_ERROR,
  payload,
});

export const usersListResetAction = () => ({
  type: USER_LIST_RESET,
});

export const userAwardPointsAction = (payload: {
  req: { id: number; points: number; comments?: string };
}) => ({
  type: USER_AWARD_POINTS,
  payload,
});

export const userAwardPointsCompletedAction = (payload: {
  res: { user: User };
  gifted: number;
}) => ({
  type: USER_AWARD_POINTS_COMPLETED,
  payload,
});
export const userAwardPointsErrorAction = (payload: { message: string }) => ({
  type: USER_AWARD_POINTS_ERROR,
  payload,
});

export const userRevokePointsAction = (payload: {
  req: { id: number; points: number; comments?: string };
}) => ({
  type: USER_REVOKE_POINTS,
  payload,
});

export const userRevokePointsCompletedAction = (payload: {
  res: { user: User };
  revoked: number;
}) => ({
  type: USER_REVOKE_POINTS_COMPLETED,
  payload,
});
export const userRevokePointsErrorAction = (payload: { message: string }) => ({
  type: USER_REVOKE_POINTS_ERROR,
  payload,
});

export const userGetAction = (payload: { req: { id: number } }) => ({
  type: USER_GET,
  payload,
});

export const userGetCompletedAction = (payload: { res: { user: User } }) => ({
  type: USER_GET_COMPLETED,
  payload,
});

export const userGetErrorAction = (payload: { message: string }) => ({
  type: USER_GET_ERROR,
  payload,
});

export const usersLeaderBoardListGetAction = (payload: {}) => ({
  type: USER_LEADERBOARD_LIST_GET,
  payload,
});

export const usersLeaderBoardListGetCompletedAction = (payload: {
  res: {
    rank: number;
    users: User[];
  };
}) => ({
  type: USER_LEADERBOARD_LIST_GET_COMPLETED,
  payload,
});

export const usersLeaderBoardListGetErrorAction = (payload: {
  message: string;
}) => ({
  type: USER_LEADERBOARD_LIST_GET_ERROR,
  payload,
});

export const userUpdateAction = () => ({
  type: USER_UPDATE,
});

export const userUpdateCompletedAction = (payload: {
  res: { user: User };
}) => ({
  type: USER_UPDATE_COMPLETED,
  payload,
});
export const userUpdateErrorAction = (payload: { message: string }) => ({
  type: USER_UPDATE_ERROR,
  payload,
});
export const userTwitterProfileConfirmationModalAction = () => ({
  type: USER_TWITTER_PROFILE_CONFIRMATION_MODAL,
});
export const userTwitterProfileConfirmationModalCancelAction = () => ({
  type: USER_TWITTER_PROFILE_CONFIRMATION_MODAL_CANCEL,
});

export const userSocialConnectAction = (payload: {
  req: {
    provider: Provider | any;
    client_id: string | any;
  };
}) => ({
  type: USER_SOCIAL_CONNECT,
  payload,
});
export const userSocialConnectCanceledAction = () => ({
  type: USER_SOCIAL_CONNECT__CANCELED,
});

export const addEmailAction = (payload: {
  req: {
    email: string;
  };
}) => ({
  type: ADD_EMAIL,
  payload,
});

export const addEmailCompletedAction = (payload: {
  res: {
    code: string;
    alternate_email_data: { email: string; expires_at: Date };
  };
}) => ({
  type: ADD_EMAIL_COMPLETED,
  payload,
});

export const addEmailErrorAction = (payload: { message: string }) => ({
  type: ADD_EMAIL_ERROR,
  payload,
});

export const addEmailCheckVerificationAction = (payload: {
  req: {
    code: string;
  };
}) => ({
  type: ADD_EMAIL_CHECK_VERIFICATION,
  payload,
});

export const addEmailCheckVerificationErrorAction = (payload: {
  message: string;
}) => ({
  type: ADD_EMAIL_CHECK_VERIFICATION_ERROR,
  payload,
});

export const addWalletAddressAction = (payload: {
  req: {
    wallet_address: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS,
  payload,
});

export const addWalletAddressCompletedAction = (payload: {
  res: {
    nonce: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_COMPLETED,
  payload,
});

export const addWalletAddressErrorAction = (payload: {
  req: {
    message: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_ERROR,
  payload,
});

export const addWalletAddressRejectSignAction = () => ({
  type: ADD_WALLET_ADDRESS_REJECT_SIGN,
});

export const addWalletAddressRejectSignActionCompleted = (payload: {
  res: {
    success: boolean;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_REJECT_SIGN_COMPLETED,
  payload,
});

export const addWalletAddressRejectSignActionError = (payload: {
  res: {
    message: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_REJECT_SIGN_ERROR,
  payload,
});

export const addWalletAddressVerifySignAction = (payload: {
  req: {
    address: string;
    signature: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_VERIFY_SIGN,
  payload,
});

export const addWalletAddressVerifySignErrorAction = (payload: {
  req: {
    message: string;
  };
}) => ({
  type: ADD_WALLET_ADDRESS_VERIFY_SIGN_ERROR,
  payload,
});

export const addAlternateLoginErrorRestAction = () => ({
  type: ADD_ALTERNATE_LOGIN_CODE_RESET,
});

export const userErrorResetAction = () => ({
  type: USER_ERROR_RESET,
});

export const addAlternateLoginEmailVerifyAction = (payload: {
  code: string;
}) => ({
  type: ADD_ALTERNATE_LOGIN_VERIFY_EMAIL,
  payload,
});
export const addAlternateLoginEmailVerifyCompletedAction = (payload: {
  success: boolean;
}) => ({
  type: ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_COMPLETED,
  payload,
});
export const addAlternateLoginEmailVerifyActionError = (payload: {
  message: string;
}) => ({
  type: ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_ERROR,
  payload,
});

export const addAlternateLoginCompletedAction = (payload: {
  res: {
    user: User | null;
  };
}) => ({
  type: ADD_ALTERNATE_LOGIN_COMPLETED,
  payload,
});

export const userAlternateWalletLoginAction = () => ({
  type: USER_ALTERNATE_WALLET_LOGIN,
});

export const userSuggestionSubmissionAction = (payload: {
  image_file_object_url: string;
  type: string;
  req: {
    subject?: string;
    suggestion: string;
    email: string;
  };
}) => ({
  type: USER_SUGGESTION_SUBMISSION,
  payload,
});
export const userSuggestionSubmissionCompletedAction = (payload: {
  res: {
    success: boolean;
  };
}) => ({
  type: USER_SUGGESTION_SUBMISSION_COMPLETED,
  payload,
});
export const userSuggestionSubmissionErrorAction = (payload: {
  message: string;
  safety_check_link_error?: {
    in_suggestion?: boolean;
  };
}) => ({
  type: USER_SUGGESTION_SUBMISSION_ERROR,
  payload,
});
export const userSuggestionSubmissionErrorResetAction = (payload: {
  reset_suggestion_safety_link_error?: boolean;
}) => ({
  type: USER_SUGGESTION_SUBMISSION_ERROR_RESET,
  payload,
});
export const userSuggestionOrSupportSubmissionResetAction = () => ({
  type: USER_SUGGESTION_OR_SUPPORT_SUBMISSION_RESET,
});

export const getUserReferralCodeAction = () => ({
  type: GET_USER_REFERRAL_CODE,
});
export const getUserReferralCodeCompletedAction = (payload: {
  res: {
    referral_code: string;
  };
}) => ({
  type: GET_USER_REFERRAL_CODE_COMPLETED,
  payload,
});
export const getUserReferralCodeErrorAction = (payload: {
  message: string;
}) => ({
  type: GET_USER_REFERRAL_CODE_ERROR,
  payload,
});

// user alternate login resent actions
export const userAlternateLoginEmailResendAction = (payload: {
  code: string;
  email: string;
}) => ({
  type: USER_ALTERNATE_EMAIL_RESEND,
  payload,
});

export const userAlternateLoginEmailResendCompletedAction = (payload: {
  res: {
    isEmailResent: boolean;
  };
}) => ({ type: USER_ALTERNATE_EMAIL_RESEND_COMPLETED, payload });

export const userAlternateLoginEmailResendErrorAction = (payload: {
  message: string;
}) => ({ type: USER_ALTERNATE_EMAIL_RESEND_ERROR, payload });

export const userAlternateLoginEmailResendResetAction = () => ({
  type: USER_ALTERNATE_EMAIL_RESEND_RESET,
});

export const adminIsQuestApprovalPending = (payload: {
  res: {
    is_quest_approval_pending: boolean;
  };
}) => ({
  type: ADMIN_IS_QUEST_APPROVAL_PENDING,
  payload,
});
export const membersStatsDownloadAction = (payload: {
  req?: {
    search?: string;
    filters?: {
      [AdminMembersFiltersKey.TIERS]: Array<string>;
      [AdminMembersFiltersKey.PROVIDERS]: Array<string>;
    };
  };
}) => ({
  type: MEMBERS_STATS_DOWNLOAD,
  payload,
});

export const membersStatsDownloadCompletedAction = () => ({
  type: MEMBERS_STATS_DOWNLOAD_COMPLETED,
});

export const membersStatsDownloadErrorAction = (payload: {
  message: string;
}) => ({
  type: MEMBERS_STATS_DOWNLOAD_ERROR,
  payload,
});
