import { BrandMetadata } from "../../models/entities/Brand";
import { ExclusiveContent } from "../../models/entities/exclusive-content/ExclusiveContent";
import {
  ExclusiveContentCreateRequestPayload,
  ExclusiveContentUpdateRequestPayload,
} from "../../models/entities/exclusive-content/ExclusiveContentRequestPayload";
import { Item } from "../../models/entities/Item";
import { SortBy } from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class ExclusiveContentServices {
  static getInstance(): ExclusiveContentServices {
    return new ExclusiveContentServices();
  }

  async get(payload: {
    id: number;
  }): Promise<{ exclusive_content: ExclusiveContent }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get(
      "exclusive-content/" + payload.id,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    let exclusive_content: ExclusiveContent = {
      ...response.data,
      purchased_quantity: response.purchased_quantity,
    };
    return { exclusive_content };
  }

  async metadata(payload: {
    id: number;
  }): Promise<{ metadata: BrandMetadata }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get(
      "exclusive-content/metadata/" + payload.id,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { metadata: (response as any).data };
  }

  async create(payload: ExclusiveContentCreateRequestPayload): Promise<{
    exclusive_content: ExclusiveContent;
    signed_urls: {
      video_signed_url?: string;
      image_signed_url?: string;
      thumbnail_signed_url?: string;
    };
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("exclusive-content/", payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      exclusive_content: response.data,
      signed_urls: response.signed_urls,
    };
  }

  async editImage(
    id: number
  ): Promise<{ exclusive_content: ExclusiveContent; signed_url: string }> {
    const response = await baseApiService.put(`exclusive-content/${id}/image`);
    return {
      exclusive_content: response.data,
      signed_url: response.signed_url,
    };
  }

  async edit(
    id: number,
    payload: ExclusiveContentUpdateRequestPayload
  ): Promise<{
    exclusive_content: ExclusiveContent;
    signed_urls: {
      video_signed_url?: string;
      image_signed_url?: string;
    };
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `exclusive-content/${id}`,
      payload,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      exclusive_content: response.data,
      signed_urls: response.signed_urls,
    };
  }

  async list(payload: {
    search?: string[];
    sortBy?: SortBy;
    filters?: string[];
  }): Promise<{ exclusive_contents: ExclusiveContent[] }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("exclusive-content", {
      params: {
        search: payload.search || [],
        sort_by: payload.sortBy || "",
        filters: payload.filters || [],
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { exclusive_contents: response.data?.exclusive_contents };
  }

  async delete(id: number): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.delete(`exclusive-content/${id}`, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { success: response?.data?.success };
  }
}

export const exclusiveContentServices = ExclusiveContentServices.getInstance();
