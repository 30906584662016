import { HYDRATE } from "next-redux-wrapper";
import { Admin } from "../../models/entities/Admin";
import { Brand } from "../../models/entities/Brand";
import { BrandConfig } from "../../models/entities/BrandConfig";
import BrandSocialCredential from "../../models/entities/BrandSocialsCredentials";
import {
  ADD_ADMIN_WALLET_ADDRESS,
  ADD_ADMIN_WALLET_ADDRESS_COMPLETED,
  ADD_ADMIN_WALLET_ADDRESS_ERROR,
  ADMIN_LIST_GET,
  ADMIN_LIST_GET_COMPLETED,
  ADMIN_LIST_GET_ERROR,
  ADMIN_LIST_RESET,
  ADMIN_WALLET_ADDRESS_DELETE,
  ADMIN_WALLET_ADDRESS_DELETE_COMPLETED,
  ADMIN_WALLET_ADDRESS_DELETE_ERROR,
  AUTH_LOGIN_COMPLETED,
  AUTH_SIGNATURE_REJECTED,
  BRAND_BANNER_UPDATE,
  BRAND_BANNER_UPDATE_COMPLETED,
  BRAND_BANNER_UPDATE_ERROR,
  BRAND_CONFIG_UPDATE,
  BRAND_CONFIG_UPDATE_COMPLETED,
  BRAND_CONFIG_UPDATE_ERROR,
  BRAND_FAVICON_UPDATE,
  BRAND_FAVICON_UPDATE_COMPLETED,
  BRAND_FAVICON_UPDATE_ERROR,
  BRAND_GET,
  BRAND_GET_COMPLETED,
  BRAND_GET_ERROR,
  BRAND_LOGO_UPDATE,
  BRAND_LOGO_UPDATE_COMPLETED,
  BRAND_LOGO_UPDATE_ERROR,
  BRAND_SHARE_IMAGE_UPDATE,
  BRAND_SHARE_IMAGE_UPDATE_COMPLETED,
  BRAND_SHARE_IMAGE_UPDATE_ERROR,
  BRAND_SOCIALS_CREDENTIALS_GET,
  BRAND_SOCIALS_CREDENTIALS_GET_COMPLETED,
  BRAND_SOCIALS_CREDENTIALS_GET_ERROR,
  BRAND_SOCIALS_CREDENTIALS_UPDATE,
  BRAND_SOCIALS_CREDENTIALS_UPDATE_COMPLETED,
  BRAND_SOCIALS_CREDENTIALS_UPDATE_ERROR,
  BRAND_UPDATE,
  BRAND_UPDATE_COMPLETED,
  BRAND_UPDATE_ERROR,
  EDIT_ADMIN_COMPLETED,
  RESET_APP_STATE,
  USER_FETCH_ME_COMPLETED,
} from "../actions/actions.constants";

export interface BrandState {
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  brand: Brand | null;
  isBannerUploading?: boolean;
  isLogoUploading?: boolean;
  configEntities: Record<number, BrandConfig>;
  brand_socials_credentials: Record<string, BrandSocialCredential>;
  admin_wallet_address: {
    entities: Record<number, Admin>;
    entityIds: number[];
    loading: boolean;
    loaded: boolean;
    error: string;
  };
  brand_get_loading: boolean;
}

const initialState: BrandState = {
  isBannerUploading: false,
  isLogoUploading: false,
  brand: null,
  loading: false,
  loaded: true,
  brand_socials_credentials: {},
  admin_wallet_address: {
    entities: {},
    entityIds: [],
    loaded: true,
    loading: false,
    error: "",
  },
  brand_get_loading: false,
  configEntities: {},
};

export const BrandReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case HYDRATE: {
      if (action.payload.brand) {
        // Hydrate these values only
        const {
          brand,
          loading,
          loaded,
          brand_socials_credentials,
          configEntities,
          error,
        } = action.payload.brand;
        return {
          ...state,
          ...{
            brand,
            loading,
            loaded,
            brand_socials_credentials,
            configEntities,
            error,
          },
        };
      }
      break;
    }
    case USER_FETCH_ME_COMPLETED:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: "",
        brand: { ...state.brand, ...action.data.brand },
      };
    case BRAND_GET:
      return {
        ...state,
        brand_get_loading: true,
        loaded: false,
        error: "",
      };
    case BRAND_GET_COMPLETED: {
      const sanitizeSocialsCredentials =
        (action.payload.res.brand?.brand_social_credentials).reduce(
          (cummulator: any, socialCredentials: any) => {
            return {
              ...cummulator,
              [socialCredentials.provider]: {
                id: socialCredentials?.id,
                ...socialCredentials?.meta,
              },
            };
          },
          {}
        );
      const sanitizeConfigEntities = (
        action.payload.res.brand?.brand_configs as BrandConfig[]
      ).reduce((commutator: any, config: BrandConfig) => {
        return { ...commutator, [config.id]: { ...config } };
      }, {});

      return {
        ...state,
        brand: { ...state.brand, ...action.payload.res.brand },
        brand_socials_credentials: { ...sanitizeSocialsCredentials },
        configEntities: { ...sanitizeConfigEntities },
        brand_get_loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_CONFIG_UPDATE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    }
    case BRAND_CONFIG_UPDATE_COMPLETED: {
      const brandConfig: BrandConfig = action.payload.res.config;
      return {
        ...state,
        configEntities: {
          ...state.configEntities,
          [brandConfig.id]: { ...brandConfig },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_CONFIG_UPDATE_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
        isUploading: false,
      };
    }
    case BRAND_GET_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };

    case AUTH_SIGNATURE_REJECTED:
      return {
        ...state,
        brand_get_loading: false,
        loading: false,
        loaded: true,
        error: "",
      };
    case AUTH_LOGIN_COMPLETED:
      const brand: Brand = action.data.brand;
      return {
        ...state,
        brand: { ...state.brand, ...brand },
        loading: false,
        error: "",
      };
    case BRAND_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case BRAND_UPDATE_COMPLETED:
      const updatedBrand: Brand = action.payload.res.brand;
      return {
        ...state,
        loading: false,
        loaded: true,
        error: "",
        brand: { ...state.brand, ...updatedBrand },
      };

    case BRAND_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    case ADMIN_LIST_GET: {
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          loading: true,
          loaded: false,
          error: "",
        },
      };
    }
    case ADMIN_LIST_GET_COMPLETED: {
      const admins: Admin[] = action.payload?.res.admins;
      const sanitizeAdminsEntities = admins.reduce((cummulator: any, admin) => {
        return {
          ...cummulator,
          [admin.id]: { ...admin },
        };
      }, {});
      const adminEntityIds: any[] = admins.reduce((cummulator: any, admin) => {
        return [...cummulator, admin.id];
      }, []);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: "",
        admin_wallet_address: {
          entities: {
            ...sanitizeAdminsEntities,
          },
          entityIds: [...adminEntityIds],
          loading: false,
          loaded: true,
          error: "",
        },
      };
    }
    case ADMIN_LIST_GET_ERROR: {
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          error: action.payload.message,
          loading: false,
          loaded: true,
        },
      };
    }
    case ADMIN_LIST_RESET: {
      return {
        ...state,
        admin_wallet_address: {
          entities: {},
          entityIds: [],
          error: "",
        },
      };
    }
    case ADMIN_WALLET_ADDRESS_DELETE: {
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          loading: true,
          loaded: false,
          error: "",
        },
      };
    }
    case ADMIN_WALLET_ADDRESS_DELETE_COMPLETED: {
      const adminId = action.payload.id;
      const adminEntityIds = state.admin_wallet_address.entityIds.filter(
        (id: any) => id !== adminId
      );
      const adminEntities = adminEntityIds.reduce(
        (accumulator: any, id: number) => {
          return {
            ...accumulator,
            [id]: { ...state.admin_wallet_address.entities[id] },
          };
        },
        []
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        error: "",
        admin_wallet_address: {
          entityIds: [...adminEntityIds],
          entities: { ...adminEntities },
          loading: false,
          loaded: true,
          error: "",
        },
      };
    }
    case ADMIN_WALLET_ADDRESS_DELETE_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    }
    case BRAND_LOGO_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
        isLogoUploading: true,
      };
    case BRAND_LOGO_UPDATE_COMPLETED:
      const config: BrandConfig = action.payload.res.brand_config;
      return {
        ...state,
        configEntities: {
          ...state.configEntities,
          [config.id]: { ...config },
        },
        loading: false,
        loaded: true,
        error: "",
        isLogoUploading: false,
      };
    case BRAND_LOGO_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,

        isBannerUploading: false,
      };

    case BRAND_SHARE_IMAGE_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case BRAND_SHARE_IMAGE_UPDATE_COMPLETED: {
      const config: BrandConfig = action.payload.res.brand_config;
      return {
        ...state,
        configEntities: {
          ...state.configEntities,
          [config.id]: { ...config },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_SHARE_IMAGE_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    case BRAND_FAVICON_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case BRAND_FAVICON_UPDATE_COMPLETED: {
      const config: BrandConfig = action.payload.res.brand_config;
      return {
        ...state,
        configEntities: {
          ...state.configEntities,
          [config.id]: { ...config },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_FAVICON_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    case BRAND_BANNER_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
        isLogoUploading: false,
        isBannerUploading: true,
      };
    case BRAND_BANNER_UPDATE_COMPLETED: {
      const config: BrandConfig = action.payload.res.brand_config;
      return {
        ...state,
        configEntities: {
          ...state.configEntities,
          [config.id]: { ...config },
        },
        loading: false,
        loaded: true,
        error: "",
        isLogoUploading: false,
        isBannerUploading: false,
      };
    }
    case BRAND_BANNER_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
        isLogoUploading: false,
        isBannerUploading: false,
      };
    case BRAND_SOCIALS_CREDENTIALS_GET: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: "",
      };
    }
    case BRAND_SOCIALS_CREDENTIALS_GET_COMPLETED: {
      const socialCredentials: BrandSocialCredential[] =
        action.payload.res.brand_social_credentials;
      const sanitizeSocialsCredentials = socialCredentials.reduce(
        (cummulator: any, socialCredentials: any) => {
          return {
            ...cummulator,
            [socialCredentials.provider]: {
              id: socialCredentials?.id,
              ...socialCredentials?.meta,
            },
          };
        },
        {}
      );
      return {
        ...state,
        brand_socials_credentials: { ...sanitizeSocialsCredentials },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_SOCIALS_CREDENTIALS_GET_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    }
    case BRAND_SOCIALS_CREDENTIALS_UPDATE: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: "",
      };
    }
    case BRAND_SOCIALS_CREDENTIALS_UPDATE_COMPLETED: {
      const socialCredential = action.payload.res.brand_social_credential;
      return {
        ...state,
        brand_socials_credentials: {
          ...state.brand_socials_credentials,
          [socialCredential.provider]: {
            id: socialCredential?.id,
            ...socialCredential?.meta,
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case BRAND_SOCIALS_CREDENTIALS_UPDATE_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true,
      };
    }

    case ADD_ADMIN_WALLET_ADDRESS:
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          loading: true,
          loaded: false,
          error: "",
        },
      };
    case ADD_ADMIN_WALLET_ADDRESS_COMPLETED: {
      const admin: Admin = action.payload.res.admin;
      return {
        ...state,
        admin_wallet_address: {
          entities: {
            ...state.admin_wallet_address.entities,
            [admin.id]: { ...admin },
          },
          entityIds: [admin.id, ...state.admin_wallet_address.entityIds],
          loading: false,
          loaded: true,
          error: "",
        },
      };
    }
    case EDIT_ADMIN_COMPLETED: {
      const admin: Admin = action.payload.res.admin;
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          entities: {
            ...state.admin_wallet_address.entities,
            [admin.id]: { ...admin },
          },
          loading: false,
          loaded: true,
          error: "",
        },
      };
    }
    case ADD_ADMIN_WALLET_ADDRESS_ERROR:
      return {
        ...state,
        admin_wallet_address: {
          ...state.admin_wallet_address,
          error: action.payload.message,
          loading: false,
          loaded: true,
        },
      };

    default:
      return state;
  }
};
