import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { itemReducer } from "./item.reducer";
import { userReducer } from "./user.reducer";
import { transactionReducer } from "./transaction.reducer";
import { itemStatsReducer } from "./item-stats.reducer";
import { userEngagementReducer } from "./user.engagement.reducer";
import { BrandReducer } from "./brand.reducer";
import { analyticsReducer } from "./analytics.reducer";
import { questReducer } from "./quest.reducer";
import { inviteStateReducer } from "./invite.reducer";
import { exclusiveContentReducer } from "./exclusive.content.reducer";

// IMPORTANT NOTE: Ensure that when adding a new reducer, include a case for the action type `RESET_APP_STATE` to handle logout functionality.

const appReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  item: itemReducer,
  transaction: transactionReducer,
  itemStats: itemStatsReducer,
  engagement: userEngagementReducer,
  brand: BrandReducer,
  analytics: analyticsReducer,
  quest: questReducer,
  invite: inviteStateReducer,
  exclusiveContent: exclusiveContentReducer,
});

export const rootReducer = (state: any, action: any) =>
  appReducer(state, action);

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
